import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  makeStyles,
  OutlinedInput,
  Paper,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  Autorenew as AutorenewIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { apiUsuario } from '../../services/api';
import randPassword from '../../utils/randomString';
import { addServerErrors } from '../../utils/transformErrors';
import AutoCompleteAsyncCPF from './AutoCompleteAsyncCPF';

const useStyles = makeStyles(theme =>
  createStyles({
    flex: {
      flex: 1,
    },
  }),
);

type Usuario = {
  cpf: string;
  email: string;
  name: string;
};

interface GeneratePasswordFormData {
  usuario: Usuario;
  password: string;
}

interface GeneratePasswordProps {
  setAppBarComponent(component: ReactNode): void;
}

const GeneratePasswordSchema = Yup.object().shape({
  usuario: Yup.object()
    .typeError('Campo inválido')
    .shape({
      cpf: Yup.string().required('Campo obrigatório'),
    }),
  password: Yup.string()
    .required('Campo senha obrigatório')
    .matches(
      /^(?=.*[0-9])(?=.*[A-z]).{8,}$/,
      'A senha deverá conter pelo menos 8 caracteres com no mínimo uma letra e um número',
    ),
});

const GeneratePassword: React.FC<GeneratePasswordProps> = ({
  setAppBarComponent,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [generatedPassword, setGeneratedPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setAppBarComponent(
      <Typography variant="h6" color="inherit" className={classes.flex}>
        Gerar Nova Senha
      </Typography>,
    );
  }, [setAppBarComponent, classes.flex]);

  useEffect(() => {
    document.title = 'Renda Franca | Gerar Nova Senha';
  });

  const {
    control,
    handleSubmit,
    register,
    errors,
    setError,
    watch,
    formState: { isSubmitting },
  } = useForm<GeneratePasswordFormData>({
    resolver: yupResolver(GeneratePasswordSchema),
    defaultValues: {
      usuario: {
        cpf: '',
        email: '',
        name: '',
      },
      password: '',
    },
  });

  const watchUsuario = watch('usuario', {} as Usuario);

  const handleSubmitCallBack = useCallback(
    async (values: GeneratePasswordFormData) => {
      try {
        await apiUsuario.post('/password/reset/admin', {
          cpf: values.usuario.cpf,
          password: values.password,
        });

        enqueueSnackbar('Senha alterada com sucesso', {
          variant: 'success',
        });

        history.replace('/');
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;
          if (errorDetails) {
            addServerErrors<GeneratePasswordFormData>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao solicitar alteração de senha', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, setError, history],
  );

  const handleGeneratePassword = useCallback(() => {
    setGeneratedPassword(randPassword(5, 3, 2));
  }, []);

  return (
    <Box margin={1}>
      <Grid container justify="space-evenly" alignItems="stretch" spacing={2}>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={10}
          style={{ flexBasis: 'auto' }}
        >
          <Card elevation={5} style={{ maxWidth: 400, height: '100%' }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Alterar senha
              </Typography>

              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handleSubmitCallBack)}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Paper variant="outlined" style={{ padding: 16 }}>
                      <Typography>
                        <Box marginBottom={1} fontWeight="fontWeightMedium">
                          Usuário
                        </Box>
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <AutoCompleteAsyncCPF
                            control={control}
                            name="usuario"
                            label="CPF"
                            variant="outlined"
                            defaultValue={
                              control.defaultValuesRef.current.usuario
                            }
                            fullWidth
                            required
                            helperText={errors.usuario?.cpf?.message}
                            error={!!errors.usuario?.cpf}
                          />
                        </Grid>

                        {watchUsuario.cpf && (
                          <Grid item xs={12}>
                            <Box paddingLeft={1}>
                              <ListItemText disableTypography>
                                <Typography variant="h6">
                                  {watchUsuario.name}
                                </Typography>
                                <Typography
                                  display="block"
                                  variant="subtitle2"
                                  color="textSecondary"
                                >
                                  {`Email: ${watchUsuario.email}`}
                                </Typography>
                              </ListItemText>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      error={!!errors.password}
                    >
                      <InputLabel htmlFor="password-id">Senha</InputLabel>
                      <OutlinedInput
                        id="password-id"
                        inputRef={register}
                        name="password"
                        label="Senha *"
                        readOnly
                        value={generatedPassword}
                        endAdornment={
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    generatedPassword,
                                  );
                                }}
                                edge="end"
                              >
                                <SvgIcon>
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                </SvgIcon>
                              </IconButton>
                            </InputAdornment>
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleGeneratePassword}
                                edge="end"
                              >
                                <AutorenewIcon />
                              </IconButton>
                            </InputAdornment>
                          </>
                        }
                      />
                      <FormHelperText>
                        {errors.password?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems="flex-end"
                  justify="flex-end"
                  spacing={1}
                  style={{ marginTop: 16 }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      startIcon={<CloseIcon />}
                      onClick={() => history.replace('/')}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<SaveIcon />}
                      disabled={isSubmitting}
                    >
                      Alterar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneratePassword;

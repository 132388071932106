import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import Route from './Routes';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import AppBarInteraction from '../components/AppBar';
import PasswordEmail from '../pages/Password/PasswordEmail';
import PasswordEmailSuccess from '../pages/Password/PasswordEmailSuccess';
import PasswordReset from '../pages/Password/PasswordReset';
import PasswordResetError from '../pages/Password/PasswordResetError';
import PasswordResetSuccess from '../pages/Password/PasswordResetSuccess';

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/password/email" exact component={PasswordEmail} />
        <Route
          path="/password/email/success"
          component={PasswordEmailSuccess}
        />
        <Route path="/password/reset" exact component={PasswordReset} />
        <Route path="/password/reset/error" component={PasswordResetError} />
        <Route
          path="/password/reset/success"
          component={PasswordResetSuccess}
        />

        <Route path="/home" component={AppBarInteraction} isPrivate />
        <Route path="/inscricoes/:id" component={AppBarInteraction} isPrivate />
        <Route path="/inscrever" component={AppBarInteraction} isPrivate />
        <Route path="/analise" component={AppBarInteraction} isPrivate />
        <Route path="/diario-oficial" component={AppBarInteraction} isPrivate />
        <Route path="/pagamento" component={AppBarInteraction} isPrivate />
        <Route
          path="/inscricao-abertura"
          component={AppBarInteraction}
          isPrivate
        />
        <Route
          path="/generatepassword"
          component={AppBarInteraction}
          isPrivate
        />
      </Switch>
    </Router>
  );
};

export default Routes;

import axios from 'axios';

const apiUsuario = axios.create({
  baseURL: process.env.REACT_APP_API_USUARIO_URL,
});

const apiCep = axios.create({
  baseURL: process.env.REACT_APP_API_ENDERECO_URL,
});

const apiRendaFranca = axios.create({
  baseURL: process.env.REACT_APP_API_RENDAFRANCA_URL,
});

export { apiUsuario, apiCep, apiRendaFranca };

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  GetApp as GetAppIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
} from '@material-ui/icons';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/agGridColumn';
import { Controller, useForm } from 'react-hook-form';
import {
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { normalizeDiacritics } from 'normalize-text';
import { useSnackbar } from 'notistack';
import { format, isValid, parseISO } from 'date-fns';
import { green, red } from '@material-ui/core/colors';
import MaskedInput from '../../components/MaskedInput';
import TextFieldSelect from '../../components/TextFieldSelect';
import { apiRendaFranca } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { InscricaoAberturaApi as InscricaoAberturaSelectApi } from '../../components/AppBar';
import { formataCPF } from '../../utils/functions';
import CustomLoadingOverlay from '../../components/CustomLoadingOverlay';
import ConclusaoCellRenderer from '../../components/ConclusaoCellRenderer';
import DialogDadosBancarios from './DialogDadosBancarios';

const useStyles = makeStyles(theme =>
  createStyles({
    flex: {
      flex: 1,
    },
  }),
);

interface InscricaoAbertura {
  idInscricaoAbertura: number;
  descricaoAbertura: string;
}

interface SituacaoInscricao {
  idSituacaoInscricao: number;
  nomeSituacaoInscricao: string;
  descricaoSituacaoInscricao: string;
}

interface BeneficiarioPrincipal {
  id: string;
  tipoBeneficiario: string;
  nome: string;
  cpf: string;
}

interface DadosBancarios {
  id: number;
  tipoContaId: number;
  tipoContaNome: string;
  bancoId: number;
  bancoNome: string;
  agencia: string;
  operacao: string;
  numero: number;
  digito: string;
  idCpfCupomCaminhoEmprego: number;
  cpfCupomId: number;
  cpfCupomCodigo: string;
  cpfCupomDataHoraRegistro: string;
  cpfCupomDataHoraUtilizacao: string;
}

export interface InscricaoCurso {
  id: number | null;
  conclusaoCurso: boolean | null;
  numeroCurso: number;
  updatedAt: string;
}

export interface Pagamento {
  idInscricao: string;
  numeroInscricao: number;
  inscricaoPosicao: number;
  inscricaoAbertura: InscricaoAbertura;
  situacaoInscricao: SituacaoInscricao;
  beneficiarioPrincipal: BeneficiarioPrincipal[];
  dadosBancarios: DadosBancarios;
  inscricaoCurso01: InscricaoCurso;
  inscricaoCurso02: InscricaoCurso;
  inscricaoCurso03: InscricaoCurso;
}

interface PagamentoResponse {
  payload: Pagamento[];
}

interface SelectOption {
  title: string;
  value: number | string;
}

interface FormValues {
  idInscricao: string;
  idInscricaoCurso: number;
  conclusaoCurso: boolean;
  numeroCurso: number;
}

interface PagamentoProps {
  setAppBarComponent(component: ReactNode): void;
}

const initialState = {
  mouseX: null,
  mouseY: null,
};

const Pagamento: React.FC<PagamentoProps> = ({ setAppBarComponent }) => {
  const classes = useStyles();
  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [appBarHeight, setAppBarHeight] = useState(64);
  const cardRef = useRef<HTMLElement>(null);
  const [cardHeight, setCardHeight] = useState(228);

  const inscricaoCursoUpdate = useRef<FormValues[]>([]);

  const [openDialogDadosBancarios, setOpenDialogDadosBancarios] =
    useState(false);

  const [contextMenuState, setContextMenuState] = useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  const { control, register, setValue } = useForm({
    defaultValues: {
      edicaoInscricao: '',
      numeroInscricaoInicial: '',
      numeroInscricaoFinal: '',
      numeroPosicaoInicial: '',
      numeroPosicaoFinal: '',
      cpf: '',
      nome: '',
      cupomUtilizado: false,
    },
  });

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  const [rowCount, setRowCount] = useState(0);

  const edicaoInscricaoFilter = useRef<number | string>('');
  const numeroInscricaoInicialFilter = useRef<number>(0);
  const numeroInscricaoFinalFilter = useRef<number>(9999);
  const numeroPosicaoInicialFilter = useRef<number>(0);
  const numeroPosicaoFinalFilter = useRef<number>(9999);
  const cpfFilter = useRef<string>('');
  const nomeFilter = useRef<string>('');
  const cupomUtilizadoFilter = useRef<boolean>(false);

  const [rowData, setRowData] = useState<Pagamento[]>([]);
  const [inscricaoAberturasSelect, setInscricaoAberturasSelect] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    setAppBarComponent(
      <Typography variant="h6" color="inherit" className={classes.flex}>
        Pagamento Inscrições
      </Typography>,
    );
  }, [setAppBarComponent, classes.flex]);

  useEffect(() => {
    document.title = 'Renda Franca | Pagamento Inscrições';
  });

  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppBarHeight(
        Number(localStorage.getItem('@RendaFranca:app_bar_height') || 64),
      );

      setCardHeight(cardRef.current?.clientHeight || 228);
    });
  }, []);

  const defaultColDef = {
    resizable: true,
    filter: true,
    sortable: true,
  };

  const loadData = useCallback(async () => {
    try {
      const responseInscricoes = await apiRendaFranca.get<PagamentoResponse>(
        `/inscricao/pagamento`,
      );

      const responseInscricaoAbertura =
        await apiRendaFranca.get<InscricaoAberturaSelectApi>(
          '/cadastros/inscricao-abertura',
        );

      const inscricaoAberturasSelectResponse =
        responseInscricaoAbertura.data.payload.map<SelectOption>(item => {
          return {
            title: item.descricao,
            value: item.id,
          };
        });

      inscricaoAberturasSelectResponse.splice(0, 0, {
        title: 'Selecione uma opção',
        value: '',
      });

      setRowData(responseInscricoes.data.payload);
      setInscricaoAberturasSelect(inscricaoAberturasSelectResponse);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
        signOut();
        return;
      }
      enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
    } finally {
      gridApi?.hideOverlay();
    }
  }, [enqueueSnackbar, signOut, gridApi]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);

      params.api.showLoadingOverlay();

      loadData();
    },
    [loadData],
  );

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns();

    params.columnApi.applyColumnState({
      state: [
        {
          colId: 'numeroInscricao',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    return (
      edicaoInscricaoFilter.current !== '' ||
      numeroInscricaoInicialFilter.current !== 0 ||
      numeroInscricaoFinalFilter.current !== 9999 ||
      numeroPosicaoInicialFilter.current !== 0 ||
      numeroPosicaoFinalFilter.current !== 9999 ||
      cpfFilter.current !== '' ||
      nomeFilter.current !== '' ||
      cupomUtilizadoFilter.current !== false
    );
  }, []);

  const externalFilterCpfChanged = useCallback(
    (newValue: string) => {
      cpfFilter.current = newValue.replace(/[^\d]/g, '');
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNomeChanged = useCallback(
    (newValue: string) => {
      nomeFilter.current = normalizeDiacritics(newValue.toUpperCase());
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterCupomUtilizadoChanged = useCallback(
    (newValue: boolean) => {
      cupomUtilizadoFilter.current = newValue;
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterEdicaoInscricaoChanged = useCallback(
    (newValue: string) => {
      edicaoInscricaoFilter.current = newValue;
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroInscricaoInicialChanged = useCallback(
    (newValue: string) => {
      numeroInscricaoInicialFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroInscricaoFinalChanged = useCallback(
    (newValue: string) => {
      numeroInscricaoFinalFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroPosicaoInicialChanged = useCallback(
    (newValue: string) => {
      numeroPosicaoInicialFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroPosicaoFinalChanged = useCallback(
    (newValue: string) => {
      numeroPosicaoFinalFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const doesExternalFilterPass = useCallback((node: RowNode) => {
    const nodeData = node.data as Pagamento;

    let boolEdicaoInscricao = true;
    if (edicaoInscricaoFilter.current !== '') {
      boolEdicaoInscricao =
        nodeData.inscricaoAbertura.idInscricaoAbertura ===
        edicaoInscricaoFilter.current;
    }

    let boolNumeroInscricaoInicial = true;
    if (numeroInscricaoInicialFilter.current !== 0) {
      boolNumeroInscricaoInicial =
        nodeData.numeroInscricao >= numeroInscricaoInicialFilter.current;
    }

    let boolNumeroInscricaoFinal = true;
    if (numeroInscricaoFinalFilter.current !== 9999) {
      boolNumeroInscricaoFinal =
        nodeData.numeroInscricao <= numeroInscricaoFinalFilter.current;
    }

    let boolNumeroPosicaoInicial = true;
    if (numeroPosicaoInicialFilter.current !== 0) {
      boolNumeroPosicaoInicial =
        nodeData.inscricaoPosicao >= numeroPosicaoInicialFilter.current;
    }

    let boolNumeroPosicaoFinal = true;
    if (numeroPosicaoFinalFilter.current !== 9999) {
      boolNumeroPosicaoFinal =
        nodeData.inscricaoPosicao <= numeroPosicaoFinalFilter.current;
    }

    let boolCpf = true;
    if (cpfFilter.current !== '') {
      const cpfFilterMembros = nodeData.beneficiarioPrincipal.filter(
        (item: any) => {
          return item.cpf.includes(cpfFilter.current);
        },
      );

      if (cpfFilterMembros.length > 0) {
        boolCpf = true;
      } else {
        boolCpf = false;
      }
    }

    let boolNome = true;
    if (nomeFilter.current !== '') {
      const nomeFilterMembros = nodeData.beneficiarioPrincipal.filter(
        (item: any) => {
          return normalizeDiacritics(item.nome.toUpperCase()).includes(
            nomeFilter.current,
          );
        },
      );

      if (nomeFilterMembros.length > 0) {
        boolNome = true;
      } else {
        boolNome = false;
      }
    }

    let boolCupomUtilizado = true;
    if (cupomUtilizadoFilter.current !== false) {
      boolCupomUtilizado =
        nodeData.dadosBancarios.cpfCupomDataHoraUtilizacao !== null;
    }

    return (
      boolEdicaoInscricao &&
      boolNumeroInscricaoInicial &&
      boolNumeroInscricaoFinal &&
      boolNumeroPosicaoInicial &&
      boolNumeroPosicaoFinal &&
      boolCpf &&
      boolNome &&
      boolCupomUtilizado
    );
  }, []);

  const handleClickExportCSV = useCallback(() => {
    const date = format(new Date(), 'yyyy-MM-dd HH.mm.ss');

    gridApi?.exportDataAsCsv({
      columnSeparator: ';',
      allColumns: true,
      processCellCallback: (params: ProcessCellForExportParams): string => {
        let cellVal = params.value;

        if (typeof params.value === 'object') {
          switch (params.column.getColId()) {
            case 'inscricaoCurso01':
              cellVal = params.node?.data.inscricaoCurso01.conclusaoCurso;
              break;
            case 'inscricaoCurso02':
              cellVal = params.node?.data.inscricaoCurso02.conclusaoCurso;
              break;
            case 'inscricaoCurso03':
              cellVal = params.node?.data.inscricaoCurso03.conclusaoCurso;
              break;
            case 'beneficiarioPrincipal.cpf':
              cellVal = params.node?.data.beneficiarioPrincipal[0].cpf;
              break;
            default:
              cellVal = '';
          }
        }

        return cellVal;
      },
      fileName: `rendafranca-CSV-${date}.csv`,
    });
  }, [gridApi]);

  const nomeGetter = useCallback((params: ValueGetterParams): string => {
    return normalizeDiacritics(
      params.data.beneficiarioPrincipal[0].nome.trim().toUpperCase(),
    );
  }, []);

  const cpfFormatter = useCallback((params: ValueFormatterParams): string => {
    return formataCPF(params.value[0].cpf);
  }, []);

  const handleClickMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      setContextMenuState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    },
    [],
  );

  const handleMarcarDesmarcarConclusao = useCallback(
    async (item: string) => {
      try {
        const formValues = inscricaoCursoUpdate.current.map<FormValues>(
          itemRef => {
            return {
              idInscricao: itemRef.idInscricao,
              idInscricaoCurso: itemRef.idInscricaoCurso,
              conclusaoCurso: item === 'MARCAR',
              numeroCurso: itemRef.numeroCurso,
            };
          },
        );

        await apiRendaFranca.post('/inscricao/pagamento', formValues);

        gridApi?.deselectAll();
        inscricaoCursoUpdate.current = [];
      } catch (err) {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
          signOut();
          return;
        }
        if (err.response && err.response.status === 404) {
          return;
        }
        enqueueSnackbar('Erro ao processar operação', { variant: 'error' });
      }
    },
    [gridApi, enqueueSnackbar, signOut],
  );

  const handleCloseMenu = useCallback(
    async (item: string) => {
      setContextMenuState(initialState);

      if (item === 'MARCAR' || item === 'DESMARCAR') {
        gridApi?.showLoadingOverlay();

        await handleMarcarDesmarcarConclusao(item);

        loadData();
      }

      if (item === 'EDITAR_DADOS_BANCARIOS') {
        setOpenDialogDadosBancarios(true);
      }
    },
    [gridApi, loadData, handleMarcarDesmarcarConclusao],
  );

  const dateFormatterDataHoraUtilizacao = useCallback(
    (params: ValueFormatterParams): string => {
      if (isValid(parseISO(params.value))) {
        return format(parseISO(params.value), 'dd/MM/yyyy HH:mm:ss');
      }

      return '';
    },
    [],
  );

  const dateGetterDataHoraUtilizacao = useCallback(
    (params: ValueGetterParams): string => {
      if (
        isValid(parseISO(params.data.dadosBancarios.cpfCupomDataHoraUtilizacao))
      ) {
        return format(
          parseISO(params.data.dadosBancarios.cpfCupomDataHoraUtilizacao),
          'yyyy-MM-dd HH:mm:ss',
        );
      }

      return '';
    },
    [],
  );

  const dateFormatterDataHoraModificacao = useCallback((value: any): string => {
    if (isValid(parseISO(value))) {
      return format(parseISO(value), 'dd/MM/yyyy HH:mm:ss');
    }

    return '';
  }, []);

  const dateGetterDataHoraModificacao = useCallback((value: any): string => {
    if (isValid(parseISO(value))) {
      return format(parseISO(value), 'yyyy-MM-dd HH:mm:ss');
    }

    return '';
  }, []);

  const handleChangeConclusao = useCallback(
    (idInscricao: string, inscricaoCurso: InscricaoCurso, checked: boolean) => {
      if (checked) {
        inscricaoCursoUpdate.current.push({
          idInscricao,
          idInscricaoCurso: inscricaoCurso.id || 0,
          numeroCurso: inscricaoCurso.numeroCurso,
          conclusaoCurso: inscricaoCurso.conclusaoCurso || false,
        });
      } else {
        const index = inscricaoCursoUpdate.current.findIndex(
          item => item.idInscricaoCurso === inscricaoCurso.id || 0,
        );

        inscricaoCursoUpdate.current.splice(index, 1);
      }
    },
    [],
  );

  return (
    <>
      <div
        style={{
          height: `calc(100vh - ${appBarHeight}px - ${cardHeight}px`,
          width: '100%',
        }}
      >
        <Card ref={cardRef} style={{ display: 'flex', flex: 1 }}>
          <CardContent style={{ width: '100%', paddingBottom: 0 }}>
            <Grid container spacing={1} alignContent="stretch">
              <Grid item xs sm md lg>
                <Paper
                  variant="outlined"
                  style={{ padding: 8, height: '100%' }}
                >
                  <Typography gutterBottom variant="h5" component="h2">
                    Filtros
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs sm md lg style={{ minWidth: 170 }}>
                      <TextFieldSelect
                        control={control}
                        name="edicaoInscricao"
                        label="Edição Inscricao"
                        value={edicaoInscricaoFilter}
                        onChangeExternal={value =>
                          externalFilterEdicaoInscricaoChanged(value as string)
                        }
                        fullWidth
                        oneOf={inscricaoAberturasSelect}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroInscricaoInicial"
                        label="Inscrição Inicial"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value.lenght === 0) {
                            externalFilterNumeroInscricaoInicialChanged('0');
                          } else {
                            externalFilterNumeroInscricaoInicialChanged(value);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroInscricaoFinal"
                        label="Inscrição Final"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value) {
                            externalFilterNumeroInscricaoFinalChanged(value);
                          } else {
                            externalFilterNumeroInscricaoFinalChanged('9999');
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroPosicaoInicial"
                        label="Posição Inicial"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value.lenght === 0) {
                            externalFilterNumeroPosicaoInicialChanged('0');
                          } else {
                            externalFilterNumeroPosicaoInicialChanged(value);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroPosicaoFinal"
                        label="Posição Final"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value) {
                            externalFilterNumeroPosicaoFinalChanged(value);
                          } else {
                            externalFilterNumeroPosicaoFinalChanged('9999');
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 150 }}>
                      <MaskedInput
                        options={{
                          mask: '000.000.000-00',
                        }}
                        control={control}
                        name="cpf"
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          externalFilterCpfChanged(value);
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 130 }}>
                      <TextField
                        inputRef={register}
                        name="nome"
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        onChange={event => {
                          externalFilterNomeChanged(event.target.value);
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Controller
                        name="cupomUtilizado"
                        control={control}
                        render={({ name, ref, value }) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  inputRef={ref}
                                  name={name}
                                  checked={value}
                                  onChange={(event, checked) => {
                                    setValue('cupomUtilizado', checked);
                                    externalFilterCupomUtilizadoChanged(
                                      checked,
                                    );
                                  }}
                                  color="primary"
                                />
                              }
                              label="Mostrar somente cupons utilizados"
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper
                  variant="outlined"
                  style={{ padding: 8, height: '100%' }}
                >
                  <Typography gutterBottom variant="h5" component="h2">
                    Opções
                  </Typography>
                  <Grid container spacing={1} alignContent="stretch">
                    <Grid item xs sm md lg>
                      <Tooltip title="exportar lista formato csv">
                        <Button
                          style={{ width: '100%', height: '100%' }}
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<GetAppIcon />}
                          onClick={handleClickExportCSV}
                        >
                          CSV
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item>
                <Typography variant="subtitle1" component="h5">
                  <Box paddingTop={2} fontWeight="fontWeightMedium">
                    {rowCount} {rowCount <= 1 ? 'inscrição' : 'inscrições'}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <div
          className="ag-theme-alpine"
          onContextMenu={handleClickMenu}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            cursor: 'context-menu',
          }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            animateRows
            rowData={rowData}
            getRowNodeId={(data: any) => data.idInscricao}
            defaultColDef={defaultColDef}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            loadingOverlayComponent="customLoadingOverlay"
            loadingOverlayComponentParams={{
              loadingMessage: 'Aguarde...',
            }}
            frameworkComponents={{
              customLoadingOverlay: CustomLoadingOverlay,
              conclusaoCellRenderer: ConclusaoCellRenderer,
            }}
            onFilterChanged={(filter: any) => {
              setRowCount(filter.api.getDisplayedRowCount());
            }}
            onRowDataChanged={(event: any) => {
              setRowCount(event.api.getDisplayedRowCount());
            }}
            enableCellTextSelection
            rowSelection="single"
            suppressRowClickSelection
          >
            <AgGridColumn headerName="Dados Inscrição">
              <AgGridColumn
                field="numeroInscricao"
                headerName="Núm. Inscrição"
                filter="agNumberColumnFilter"
                checkboxSelection
              />

              <AgGridColumn
                field="inscricaoAbertura.descricaoAbertura"
                headerName="Edição"
              />

              <AgGridColumn
                field="situacaoInscricao.descricaoSituacaoInscricao"
                headerName="Situação"
              />

              <AgGridColumn field="inscricaoPosicao" headerName="Posição" />

              <AgGridColumn
                colId="beneficiarioPrincipal.nome"
                field="beneficiarioPrincipal"
                headerName="Nome"
                valueGetter={nomeGetter}
              />

              <AgGridColumn
                colId="beneficiarioPrincipal.cpf"
                field="beneficiarioPrincipal"
                headerName="CPF"
                valueFormatter={cpfFormatter}
              />
            </AgGridColumn>

            <AgGridColumn headerName="Dados Curso">
              <AgGridColumn
                field="dadosBancarios.cpfCupomCodigo"
                headerName="Cupom"
              />

              <AgGridColumn
                field="dadosBancarios.cpfCupomDataHoraUtilizacao"
                headerName="Data/Hora Utilização"
                filter="agDateColumnFilter"
                valueFormatter={dateFormatterDataHoraUtilizacao}
                valueGetter={dateGetterDataHoraUtilizacao}
              />

              <AgGridColumn
                field="inscricaoCurso01"
                headerName="Conclusão Curso 01"
                cellRenderer="conclusaoCellRenderer"
                cellRendererParams={{
                  onChange: handleChangeConclusao,
                }}
                cellStyle={{
                  height: '100%',
                  display: 'flex ',
                  justifyContent: 'center',
                  alignItems: 'center ',
                }}
              />

              <AgGridColumn
                field="inscricaoCurso01.updatedAt"
                headerName="Data/Hora Mod. 01"
                filter="agDateColumnFilter"
                valueFormatter={(params: ValueFormatterParams) =>
                  dateFormatterDataHoraModificacao(params.value)
                }
                valueGetter={(params: ValueGetterParams) =>
                  dateGetterDataHoraModificacao(
                    params.data.inscricaoCurso01.updatedAt,
                  )
                }
              />

              <AgGridColumn
                field="inscricaoCurso02"
                headerName="Conclusão Curso 02"
                cellRenderer="conclusaoCellRenderer"
                cellRendererParams={{
                  onChange: handleChangeConclusao,
                }}
                cellStyle={{
                  height: '100%',
                  display: 'flex ',
                  justifyContent: 'center',
                  alignItems: 'center ',
                }}
              />

              <AgGridColumn
                field="inscricaoCurso02.updatedAt"
                headerName="Data/Hora Mod. 02"
                filter="agDateColumnFilter"
                valueFormatter={(params: ValueFormatterParams) =>
                  dateFormatterDataHoraModificacao(params.value)
                }
                valueGetter={(params: ValueGetterParams) =>
                  dateGetterDataHoraModificacao(
                    params.data.inscricaoCurso02.updatedAt,
                  )
                }
              />

              <AgGridColumn
                field="inscricaoCurso03"
                headerName="Conclusão Curso 03"
                cellRenderer="conclusaoCellRenderer"
                cellRendererParams={{
                  onChange: handleChangeConclusao,
                }}
                cellStyle={{
                  height: '100%',
                  display: 'flex ',
                  justifyContent: 'center',
                  alignItems: 'center ',
                }}
              />

              <AgGridColumn
                field="inscricaoCurso03.updatedAt"
                headerName="Data/Hora Mod. 03"
                filter="agDateColumnFilter"
                valueFormatter={(params: ValueFormatterParams) =>
                  dateFormatterDataHoraModificacao(params.value)
                }
                valueGetter={(params: ValueGetterParams) =>
                  dateGetterDataHoraModificacao(
                    params.data.inscricaoCurso03.updatedAt,
                  )
                }
              />
            </AgGridColumn>

            <AgGridColumn headerName="Dados Bancários">
              <AgGridColumn
                field="dadosBancarios.tipoContaNome"
                headerName="Tipo"
              />

              <AgGridColumn
                field="dadosBancarios.bancoId"
                headerName="Núm. Banco"
              />

              <AgGridColumn
                field="dadosBancarios.bancoNome"
                headerName="Banco"
              />

              <AgGridColumn
                field="dadosBancarios.agencia"
                headerName="Agencia"
              />

              <AgGridColumn
                field="dadosBancarios.operacao"
                headerName="Operação"
              />

              <AgGridColumn field="dadosBancarios.numero" headerName="Número" />

              <AgGridColumn field="dadosBancarios.digito" headerName="Dígito" />
            </AgGridColumn>
          </AgGridReact>

          <Menu
            keepMounted
            open={contextMenuState.mouseY !== null}
            onClose={handleCloseMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenuState.mouseY !== null &&
              contextMenuState.mouseX !== null
                ? {
                    top: contextMenuState.mouseY,
                    left: contextMenuState.mouseX,
                  }
                : undefined
            }
          >
            <MenuItem
              disabled={inscricaoCursoUpdate.current.length === 0}
              onClick={() => {
                handleCloseMenu('MARCAR');
              }}
            >
              <ListItemIcon>
                <DoneIcon style={{ color: green[500] }} />
              </ListItemIcon>
              <ListItemText primary="Marcar como Curso Concluído" />
            </MenuItem>
            <MenuItem
              disabled={inscricaoCursoUpdate.current.length === 0}
              onClick={() => {
                handleCloseMenu('DESMARCAR');
              }}
            >
              <ListItemIcon>
                <CloseIcon style={{ color: red[500] }} />
              </ListItemIcon>
              <ListItemText primary="Marcar como Curso Não Concluído" />
            </MenuItem>
            <MenuItem
              disabled={gridApi?.getSelectedRows().length !== 1}
              onClick={() => {
                handleCloseMenu('EDITAR_DADOS_BANCARIOS');
              }}
            >
              <ListItemIcon>
                <AccountBalanceWalletIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Editar Dados Bancários" />
            </MenuItem>
          </Menu>
        </div>
      </div>

      {openDialogDadosBancarios && (
        <DialogDadosBancarios
          rowNodes={gridApi?.getSelectedNodes() || []}
          open={openDialogDadosBancarios}
          onClose={cancel => {
            if (!cancel) {
              gridApi?.showLoadingOverlay();

              loadData();
            }

            setOpenDialogDadosBancarios(false);
          }}
        />
      )}
    </>
  );
};

export default Pagamento;

import React, { ReactNode, useEffect, useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import {
  Box,
  CircularProgress,
  StepContent,
  useMediaQuery,
} from '@material-ui/core';

import { createStore, useStateMachine } from 'little-state-machine';
import { DevTool } from 'little-state-machine-devtools';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';

import { parseJwt } from '../../../utils/jwt';
import { apiRendaFranca, apiUsuario } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { Params } from '../../../components/AppBarMenu';
import PrazoInscrever from './Requisitos/PrazoInscrever';
import PrazoEditar from './Requisitos/PrazoEditar';
import InscricaoClassificada from './Requisitos/InscricaoClassificada';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    flex: {
      flex: 1,
    },
  }),
);

createStore(
  {
    idInscricao: '',
    beneficiarioList: [
      {
        idBeneficiario: '',
        nome: '',
        nomeSocial: '',
        parentescoMembroPrincipal: '',
        cpf: '',
        endereco: {
          cep: '',
          bairro_id: 0,
          bairro_nome: '',
          logradouro_id: 0,
          logradouro_nome: '',
          zona_id: 0,
          zona_nome: '',
        },
        rg: '',
        nis: '',
        dataNascimento: '',
        raca: '',
        telefones: [
          {
            idTelefone: 0,
            telefone: '',
            tipoTelefone: '',
          },
        ],
        deleteTelefones: [],
        possuiDeficiencia: '',
        deficienciaTipo: '',
        email: '',
        profissao: '',
        renda: '',
        servicoAssistencial: '',
        sexo: '',
        escolaridade: '',
        menorMedidaProtecao: '',
        menorMedidaSocioEducativa: '',
        numero: '',
        complemento: '',
      },
    ],
    deleteBeneficiarios: [],
    observacao: '',
    resideMunicipioMaisTresAnos: '',
    desempregadoPeloMenosSeisMeses: '',
    rendaTotal: '',
    qtdeMembrosFamiliares: '',
    anexosInfo: {
      anexos: [],
      qtdeAnexos: 0,
    },
    deleteAnexos: [],
  },
  { name: 'FormDataValues', middleWares: [], storageType: localStorage },
);

interface LoggedUser {
  name: string;
  socialName: string;
  cpf: string;
  email: string;
}

interface LoggedUserApi {
  payload: LoggedUser;
}

interface PrazoInscricao {
  podeInscricao: boolean;
  podeEditarInscricao: boolean;
}

interface PrazoInscricaoApi {
  payload: PrazoInscricao;
}

export interface InscricaoClassificada {
  numeroInscricao: number;
  cpf: string;
}

interface RequisitosInscricao {
  inscricaoClassificada: InscricaoClassificada;
}

interface RequisitosInscricaoApi {
  payload: RequisitosInscricao;
}

interface Parametros {
  salarioMinimo: number;
}

interface ParametrosApi {
  payload: Parametros;
}

interface RendaFrancaProps {
  setAppBarComponent(component: ReactNode): void;
}

const RendaFranca: React.FC<RendaFrancaProps> = ({ setAppBarComponent }) => {
  const classes = useStyles();
  const params = useParams<Params>();
  const [activeStep, setActiveStep] = React.useState(0);
  const { signOut, access_token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loggedUser, setLoggedUser] = useState<LoggedUser>({} as LoggedUser);
  const [prazoInscricao, setPrazoInscricao] = useState<PrazoInscricao>(
    {} as PrazoInscricao,
  );
  const [requisitosInscricao, setRequisitosInscricao] =
    useState<RequisitosInscricao>({} as RequisitosInscricao);
  const [parametros, setParametros] = useState<Parametros>({} as Parametros);

  const [loading, setLoading] = useState(true);

  const { state } = useStateMachine();
  const [podeContinuarEdicaoInscricao, setPodeContinuarEdicaoInscricao] =
    useState(false);
  const [podeContinuarInscricao, setPodeContinuarInscricao] = useState(false);

  useEffect(() => {
    setPodeContinuarEdicaoInscricao(
      !!state.idInscricao &&
        state.idInscricao.length > 0 &&
        prazoInscricao.podeEditarInscricao,
    );
    setPodeContinuarInscricao(
      state.idInscricao.length === 0 && prazoInscricao.podeInscricao,
    );
  }, [
    prazoInscricao.podeInscricao,
    prazoInscricao.podeEditarInscricao,
    state.idInscricao,
  ]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setAppBarComponent(
      <Typography variant="h6" color="inherit" className={classes.flex}>
        Renda Franca
      </Typography>,
    );
  }, [setAppBarComponent, classes.flex]);

  useEffect(() => {
    document.title = 'Renda Franca | Inscrição';
  });

  const handleNext = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const resultParse = parseJwt(access_token);
        const { idUsuario } = resultParse.usuario || { idUsuario: '0' };
        const responseUser = await apiUsuario.get<LoggedUserApi>(
          `/users/${idUsuario}`,
        );

        const responsePrazo = await apiRendaFranca.get<PrazoInscricaoApi>(
          `/inscrever/${params.id}/prazo-inscricao`,
        );

        const responseRequisitos =
          await apiRendaFranca.get<RequisitosInscricaoApi>(
            '/inscrever/requisitos',
          );

        const responseParametros = await apiRendaFranca.get<ParametrosApi>(
          '/parametros',
        );

        setLoggedUser(responseUser.data.payload);
        setPrazoInscricao(responsePrazo.data.payload);
        setRequisitosInscricao(responseRequisitos.data.payload);
        setParametros(responseParametros.data.payload);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
          signOut();
          return;
        }
        if (err.response && err.response.status === 404) {
          return;
        }
        enqueueSnackbar('Erro ao realizar a operação', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    })();
  }, [access_token, enqueueSnackbar, signOut, params.id]);

  return (
    <>
      <DevTool buttonTop={500} />

      {loading && (
        <Box textAlign="center" marginY={4}>
          <CircularProgress />
        </Box>
      )}

      {!loading &&
        (podeContinuarInscricao || podeContinuarEdicaoInscricao) &&
        !requisitosInscricao.inscricaoClassificada && (
          <div className={classes.root}>
            <Stepper
              style={{ padding: matches ? 3 : 24 }}
              orientation="vertical"
              activeStep={activeStep}
            >
              <Step>
                <StepLabel>Sobre o responsável familiar</StepLabel>
                <StepContent
                  style={{ paddingLeft: matches ? 8 : 20 }}
                  transitionDuration={100}
                >
                  <StepOne
                    handleNext={handleNext}
                    handleBack={handleBack}
                    salarioMinimo={parametros.salarioMinimo}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Complete seus dados</StepLabel>
                <StepContent
                  style={{ paddingLeft: matches ? 8 : 20 }}
                  transitionDuration={100}
                >
                  <StepTwo
                    handleNext={handleNext}
                    handleBack={handleBack}
                    loggedUser={loggedUser}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Membros familiares</StepLabel>
                <StepContent
                  style={{ paddingLeft: matches ? 8 : 20 }}
                  transitionDuration={100}
                >
                  <StepThree handleNext={handleNext} handleBack={handleBack} />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Adicione a documentação</StepLabel>
                <StepContent
                  style={{ paddingLeft: matches ? 8 : 20 }}
                  transitionDuration={100}
                >
                  <StepFour handleBack={handleBack} />
                </StepContent>
              </Step>
            </Stepper>
          </div>
        )}

      {!loading &&
        state.idInscricao.length === 0 &&
        !podeContinuarInscricao && <PrazoInscrever />}

      {!loading &&
        state.idInscricao.length > 0 &&
        !podeContinuarEdicaoInscricao && <PrazoEditar />}

      {!loading &&
        (podeContinuarInscricao || podeContinuarEdicaoInscricao) &&
        requisitosInscricao.inscricaoClassificada && (
          <InscricaoClassificada
            inscricao={requisitosInscricao.inscricaoClassificada}
          />
        )}
    </>
  );
};

export default RendaFranca;

import React, { createContext, useCallback, useContext, useState } from 'react';
import { createBrowserHistory } from 'history';

import { apiUsuario, apiRendaFranca } from '../services/api';
import { parseJwt } from '../utils/jwt';

interface AuthState {
  access_token: string;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  access_token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const access_token = localStorage.getItem('@RendaFranca:access_token');

    if (access_token) {
      apiUsuario.defaults.headers.authorization = `Bearer ${access_token}`;
      apiRendaFranca.defaults.headers.authorization = `Bearer ${access_token}`;
      return { access_token };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await apiUsuario.post(
      '/sessions',
      {
        email,
        password,
      },
      {
        headers: {
          localLogin: true,
        },
      },
    );

    const { access_token } = response.data;

    const resultParse = parseJwt(access_token);

    const { resetPassword } = resultParse.usuario || { resetPassword: false };

    if (resetPassword) {
      createBrowserHistory().push(`/password/reset?tokenLogin=${access_token}`);
      window.location.reload();
    } else {
      localStorage.setItem('@RendaFranca:access_token', access_token);

      apiUsuario.defaults.headers.authorization = `Bearer ${access_token}`;
      apiRendaFranca.defaults.headers.authorization = `Bearer ${access_token}`;

      setData({ access_token });
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@RendaFranca:access_token');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{ access_token: data.access_token, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

import React, { useCallback, useEffect } from 'react';
import {
  Button,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { Close as CloseIcon, Send as SendIcon } from '@material-ui/icons';

import * as Yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { cpf } from 'cpf-cnpj-validator';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Background, BackgroundOverlay, PasswordEmailCard } from './style';
import MaskedInput from '../../../components/MaskedInput';
import { addServerErrors } from '../../../utils/transformErrors';
import { apiUsuario } from '../../../services/api';

interface PasswordEmailFormData {
  cpf: string;
  email: string;
}

const PasswordEmailSchema = Yup.object().shape({
  cpf: Yup.string()
    .required('Campo CPF obrigatório')
    .matches(/\d{3}\.\d{3}\.\d{3}-\d{2}/, 'Campo CPF inválido')
    .test('is valid cpf', 'Campo CPF inválido', value => {
      return cpf.isValid(value || '');
    }),
  email: Yup.string()
    .required('Campo e-mail obrigatório')
    .email('Digite um e-mail válido'),
});

const PasswordEmail: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm<PasswordEmailFormData>({
    resolver: yupResolver(PasswordEmailSchema),
    defaultValues: {
      cpf: '',
    },
  });

  const history = useHistory();

  useEffect(() => {
    document.title = 'Renda Franca | Recuperar Senha';
  });

  const handleSubmitCallBack = useCallback(
    async (values: PasswordEmailFormData) => {
      try {
        await apiUsuario.post('/password/email', values);

        history.replace('/password/email/success', {
          email: values.email,
        });
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;

          if (errorDetails) {
            addServerErrors<PasswordEmailFormData>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao solicitar recuperação de senha', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, setError, history],
  );

  return (
    <Background container>
      <BackgroundOverlay container alignContent="center" justify="center">
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <PasswordEmailCard style={{ maxWidth: 400 }} elevation={12}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Recuperar senha
                </Typography>

                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(handleSubmitCallBack)}
                >
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <MaskedInput
                        options={{
                          mask: '000.000.000-00',
                        }}
                        control={control}
                        name="cpf"
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={errors.cpf?.message}
                        error={!!errors.cpf}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="email"
                        label="E-mail"
                        type="email"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={errors.email?.message}
                        error={!!errors.email}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    alignItems="flex-end"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        startIcon={<CloseIcon />}
                        onClick={() => history.replace('/')}
                      >
                        Cancelar
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                        startIcon={<SendIcon />}
                        disabled={isSubmitting}
                      >
                        Recuperar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </PasswordEmailCard>
          </Grid>
        </Grid>
      </BackgroundOverlay>
    </Background>
  );
};

export default PasswordEmail;

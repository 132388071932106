import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Button,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';

import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';

import { cpf } from 'cpf-cnpj-validator';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { Background, BackgroundOverlay, SignUpCard } from './style';
import { apiUsuario } from '../../services/api';
import { addServerErrors } from '../../utils/transformErrors';
import MaskedInput from '../../components/MaskedInput';

interface SignUpFormData {
  name: string;
  socialName: string;
  cpf: string;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
}

const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .required('Campo nome completo obrigatório')
    .trim()
    .matches(
      /^[\p{L}\p{N}*-]*(?: [\p{L}\p{N}*-]+)+$/u,
      'Campo nome completo inválido',
    ),
  socialName: Yup.string(),
  cpf: Yup.string()
    .required('Campo CPF obrigatório')
    .matches(/\d{3}\.\d{3}\.\d{3}-\d{2}/, 'Campo CPF inválido')
    .test('is valid cpf', 'Campo CPF inválido', value => {
      return cpf.isValid(value || '');
    }),
  email: Yup.string()
    .required('Campo e-mail obrigatório')
    .email('Digite um e-mail válido'),
  emailConfirmation: Yup.string()
    .required('Campo confirmar email obrigatório')
    .oneOf([Yup.ref('email'), null], 'Emails não conferem'),
  password: Yup.string()
    .required('Campo senha obrigatório')
    .matches(
      /^(?=.*[0-9])(?=.*[A-z]).{8,}$/,
      'A senha deverá conter pelo menos 8 caracteres com no mínimo uma letra e um número',
    ),
  passwordConfirmation: Yup.string()
    .required('Campo confirmar senha obrigatório')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
});

const SignUp: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm<SignUpFormData>({
    resolver: yupResolver(SignUpSchema),
    defaultValues: {
      cpf: '',
    },
  });

  useEffect(() => {
    document.title = 'Renda Franca | Cadastro';
  });

  const history = useHistory();

  const handleSubmitCallBack = useCallback(
    async (values: SignUpFormData) => {
      try {
        await apiUsuario.post('/users', values);

        history.push('/');

        enqueueSnackbar('Usuário cadastrado com sucesso!', {
          variant: 'success',
        });
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;

          if (errorDetails) {
            addServerErrors<SignUpFormData>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao cadastrar o usuário', { variant: 'error' });
      }
    },
    [history, enqueueSnackbar, setError],
  );

  return (
    <Background container>
      <BackgroundOverlay container alignContent="center" justify="center">
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <SignUpCard style={{ maxWidth: 400 }} elevation={12}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Cadastro
                </Typography>

                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(handleSubmitCallBack)}
                >
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="name"
                        label="Nome Completo"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={errors.name?.message}
                        error={!!errors.name}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="socialName"
                        label="Nome Social"
                        variant="outlined"
                        fullWidth
                        helperText={errors.socialName?.message}
                        error={!!errors.socialName}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MaskedInput
                        options={{
                          mask: '000.000.000-00',
                        }}
                        control={control}
                        name="cpf"
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={errors.cpf?.message}
                        error={!!errors.cpf}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="email"
                        label="E-mail"
                        type="email"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={errors.email?.message}
                        error={!!errors.email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="emailConfirmation"
                        label="Confirmar E-mail"
                        type="email"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={errors.emailConfirmation?.message}
                        error={!!errors.emailConfirmation}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="password"
                        label="Senha"
                        type="password"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={errors.password?.message}
                        error={!!errors.password}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box marginBottom={2}>
                        <TextField
                          inputRef={register}
                          name="passwordConfirmation"
                          label="Confirmar Senha"
                          type="password"
                          variant="outlined"
                          fullWidth
                          required
                          helperText={errors.passwordConfirmation?.message}
                          error={!!errors.passwordConfirmation}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    alignItems="flex-end"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        startIcon={<CloseIcon />}
                        onClick={() => history.replace('/')}
                      >
                        Cancelar
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        color="primary"
                        startIcon={<SaveIcon />}
                        disabled={isSubmitting}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </SignUpCard>
          </Grid>
        </Grid>
      </BackgroundOverlay>
    </Background>
  );
};

export default SignUp;

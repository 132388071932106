import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import { ErrorOption } from 'react-hook-form';

interface ErrorDetail {
  messageUser: string;
  messageDev: string;
  field: string;
  value: string;
}

export function addServerErrors<T>(
  errorDetails: ErrorDetail[],
  setError: (fieldName: keyof T, error: ErrorOption) => void,
): void {
  return errorDetails.forEach(item => {
    setError(item.field as keyof T, {
      type: 'server',
      message: item.messageUser,
    });
  });
}

export function enqueueSnackbarErrorDetails(
  errorDetails: ErrorDetail[],
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey,
): void {
  return errorDetails.forEach(item => {
    enqueueSnackbar(item.messageUser, { variant: 'error' });
  });
}

import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { format } from 'date-fns';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { DocumentCreator } from './generator';

interface DiarioOficialProps {
  open: boolean;
  onClose: (cancel: boolean) => void;
  gridApi: GridApi | null;
  gridColumnApi: ColumnApi | null;
}

const DiarioOficial: React.FC<DiarioOficialProps> = ({
  open,
  onClose,
  gridApi,
  gridColumnApi,
}) => {
  const [value, setValue] = useState('modeloA');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue((event.target as HTMLInputElement).value);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleExportar = useCallback(() => {
    if (value === 'modeloA') {
      gridColumnApi?.applyColumnState({
        state: [
          {
            colId: 'posicaoConf',
            sort: 'asc',
          },
        ],
        defaultState: { sort: null },
      });
    }

    if (value === 'modeloB') {
      gridColumnApi?.applyColumnState({
        state: [
          {
            colId: 'nome',
            sort: 'asc',
          },
        ],
        defaultState: { sort: null },
      });
    }

    const doc = DocumentCreator.create({
      gridApi,
      modelo: value,
    });

    Packer.toBlob(doc).then(blob => {
      const date = format(new Date(), 'yyyy-MM-dd HH.mm.ss');
      saveAs(blob, `rendafranca-DO-${date}.docx`);
    });
  }, [value, gridApi, gridColumnApi]);

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={open}
      >
        <DialogTitle>Escolha um modelo de exportação abaixo:</DialogTitle>

        <DialogContent dividers>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="modeloA"
                control={<Radio color="primary" />}
                label="Modelo A (ordem por posição de classificação)"
              />
              <FormHelperText style={{ marginBottom: 8 }}>
                Modelo utilizado para exportação de inscrições com a situação
                CLASSIFICADO
              </FormHelperText>
              <FormControlLabel
                value="modeloB"
                control={<Radio color="primary" />}
                label="Modelo B (ordem alfabética por nome)"
              />
              <FormHelperText>
                Modelo utilizado para exportação de inscrições que não estão com
                a situação CLASSIFICADO
              </FormHelperText>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button color="primary" type="submit" onClick={handleExportar}>
            Exportar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DiarioOficial;

function getColor(situacaoInscricao: string): string {
  if (situacaoInscricao === 'SOLICITADO') {
    return '#5362E5';
  }
  if (situacaoInscricao === 'CLASSIFICADO') {
    return '#208C19';
  }
  if (situacaoInscricao === 'DESCLASSIFICADO') {
    return '#f44336';
  }
  if (situacaoInscricao === 'EXCLUIDO') {
    return '#f44336';
  }
  if (situacaoInscricao === 'RECURSO_IMPETRADO') {
    return '#F49C44';
  }

  return '#000000';
}

export { getColor };

import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useStateMachine } from 'little-state-machine';
import { isValid, parseISO, differenceInYears } from 'date-fns';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { createIntl, createIntlCache } from 'react-intl';

import { useHistory } from 'react-router-dom';
import {
  updateStepTwo,
  updateStepTwoRemoveTelefone,
  clearState,
} from '../../../../utils/updateActions';
import TextFieldSelect from '../../../../components/TextFieldSelect';
import {
  deficienciaTipo,
  escolaridade,
  raca,
  servicoAssistencial,
  sexo,
  simNao,
  tipoTelefone,
  estadoCivil,
} from '../../../../utils/enums';
import MaskedInput from '../../../../components/MaskedInput';
import AutoCompleteAsync from '../../../../components/AutoCompleteAsync';
import { formataCPF } from '../../../../utils/functions';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'pt-BR',
    messages: {},
  },
  cache,
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
  }),
);

type Telefone = {
  idTelefone: number;
  telefone: string;
  tipoTelefone: string;
};

type Endereco = {
  cep: string;
  bairro_id: number;
  bairro_nome: string;
  logradouro_id: number;
  logradouro_nome: string;
  zona_id: number;
  zona_nome: string;
};

type FormValues = {
  idBeneficiario: string;
  nome: string;
  nomeSocial: string;
  cpf: string;
  endereco: Endereco;
  rg: string;
  nis: string;
  dataNascimento: any;
  raca: string;
  telefones: Telefone[];
  deleteTelefones: Telefone[];
  possuiDeficiencia: string;
  deficienciaTipo: string;
  email: string;
  profissao: string;
  renda: any;
  servicoAssistencial: string | number;
  estadoCivil: string;
  sexo: string;
  escolaridade: string;
  menorMedidaProtecao: string;
  menorMedidaSocioEducativa: string;
  numero: string;
  complemento: string;
};

const StepTwoSchema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  nomeSocial: Yup.string(),
  cpf: Yup.string().required('Campo obrigatório'),
  endereco: Yup.object()
    .typeError('Campo inválido')
    .shape({
      cep: Yup.string().required('Campo obrigatório'),
    }),
  rg: Yup.string().max(20, 'Campo inválido'),
  nis: Yup.string().matches(/^(\s*|\d{11})$/, 'Campo inválido'),
  raca: Yup.string().required('Campo obrigatório'),
  telefones: Yup.array().of(
    Yup.object().shape({
      tipoTelefone: Yup.string().required('Campo obrigatório'),
      telefone: Yup.string().required('Campo obrigatório'),
    }),
  ),
  possuiDeficiencia: Yup.string().required('Campo obrigatório'),
  deficienciaTipo: Yup.string().when('possuiDeficiencia', {
    is: 'SIM',
    then: Yup.string().required('Campo obrigatório'),
  }),
  email: Yup.string().required('Campo obrigatório'),
  profissao: Yup.string(),
  renda: Yup.number()
    .transform((value, originalValue) => {
      if (value as typeof Number) return value;

      const parseValue = parseFloat(
        originalValue
          .replaceAll('R$', '')
          .replaceAll('.', '')
          .replaceAll(',', '.')
          .trim(),
      );

      return parseValue;
    })
    .typeError('Campo inválido')
    .min(0, 'O valor mínimo para o campo é 0')
    .max(9999.99, 'O valor máximo para o campo é 9999,99')
    .required('Campo obrigatório'),
  servicoAssistencial: Yup.string(),
  estadoCivil: Yup.string().required('Campo obrigatório'),
  sexo: Yup.string().required('Campo obrigatório'),
  escolaridade: Yup.string().required('Campo obrigatório'),
  menorMedidaProtecao: Yup.string().when('$idade', {
    is: (idade: number) => idade < 18,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string(),
  }),
  menorMedidaSocioEducativa: Yup.string().when('$idade', {
    is: (idade: number) => idade >= 12 && idade < 18,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string(),
  }),
  numero: Yup.string()
    .required('Campo obrigatório')
    .max(5, 'Máximo 5 caracteres'),
});

interface LoggedUser {
  name: string;
  socialName: string;
  cpf: string;
  email: string;
}

interface StepProps {
  handleNext: any;
  handleBack: any;
  loggedUser: LoggedUser;
}

const StepTwo: React.FC<StepProps> = ({
  handleNext,
  handleBack,
  loggedUser,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [idade, setIdade] = useState(0);
  const [isValidDataNascimento, setValidDataNascimento] = useState(false);
  const { state, actions } = useStateMachine({
    updateStepTwo,
    updateStepTwoRemoveTelefone,
    clearState,
  });

  const {
    setValue,
    control,
    handleSubmit,
    register,
    errors,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    context: { idade },
    resolver: yupResolver(StepTwoSchema),
    defaultValues: {
      idBeneficiario: state.beneficiarioList[0].idBeneficiario,
      nome: loggedUser.name,
      nomeSocial: loggedUser.socialName,
      cpf: loggedUser.cpf,
      endereco: state.beneficiarioList[0].endereco,
      rg: state.beneficiarioList[0].rg,
      nis: state.beneficiarioList[0].nis,
      dataNascimento: state.beneficiarioList[0].dataNascimento,
      raca: state.beneficiarioList[0].raca,
      telefones: state.beneficiarioList[0].telefones,
      deleteTelefones: state.beneficiarioList[0].deleteTelefones,
      possuiDeficiencia: state.beneficiarioList[0].possuiDeficiencia,
      deficienciaTipo: state.beneficiarioList[0].deficienciaTipo,
      email: loggedUser.email,
      profissao: state.beneficiarioList[0].profissao,
      renda: state.beneficiarioList[0].renda,
      servicoAssistencial: state.beneficiarioList[0].servicoAssistencial,
      estadoCivil: state.beneficiarioList[0].estadoCivil,
      sexo: state.beneficiarioList[0].sexo,
      escolaridade: state.beneficiarioList[0].escolaridade,
      menorMedidaProtecao: state.beneficiarioList[0].menorMedidaProtecao,
      menorMedidaSocioEducativa:
        state.beneficiarioList[0].menorMedidaSocioEducativa,
      numero: state.beneficiarioList[0].numero,
      complemento: state.beneficiarioList[0].complemento,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'telefones',
  });

  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleClose = useCallback(
    (aceitou: boolean) => {
      if (aceitou) {
        actions.clearState(undefined);
        history.push('/home');
      }
      setOpenDialog(false);
    },
    [history, actions],
  );

  const watchPossuiDeficiencia = watch('possuiDeficiencia', '');
  const watchDataNascimento = watch(
    'dataNascimento',
    state.beneficiarioList[0].dataNascimento,
  );
  const watchEndereco = watch('endereco', {} as Endereco);

  useEffect(() => {
    if (isValid(parseISO(watchDataNascimento))) {
      setIdade(differenceInYears(new Date(), parseISO(watchDataNascimento)));
      setValidDataNascimento(true);
    } else {
      setIdade(0);
      setValidDataNascimento(false);
    }
  }, [watchDataNascimento]);

  const handleSubmitCallBack = useCallback(
    (values: FormValues) => {
      actions.updateStepTwo({
        ...values,
        renda: intl.formatNumber(values.renda, {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }),
        dataNascimento: state.beneficiarioList[0].dataNascimento,
        deleteTelefones: state.beneficiarioList[0].deleteTelefones,
      });

      handleNext();
    },
    [actions, handleNext, state.beneficiarioList],
  );

  const handleRemoveTelefone = useCallback(
    (phoneItem: Telefone, index: number) => {
      if (phoneItem.idTelefone) {
        actions.updateStepTwoRemoveTelefone(phoneItem);
      }
      remove(index);
    },
    [actions, remove],
  );

  return (
    <>
      <Grid container alignContent="center" justify="center">
        <Grid item xs={12} sm={9} md={7} lg={5}>
          <Box maxWidth={530} marginX="auto">
            <Card elevation={12}>
              <CardContent>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(handleSubmitCallBack)}
                >
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <input ref={register} type="hidden" name="idBeneficiario" />

                    <input ref={register} type="hidden" name="nome" />
                    <input ref={register} type="hidden" name="nomeSocial" />
                    <input ref={register} type="hidden" name="cpf" />
                    <input ref={register} type="hidden" name="email" />

                    <Grid item xs={12}>
                      <Paper variant="outlined" style={{ padding: 16 }}>
                        <Typography>
                          <Box marginBottom={1} fontWeight="fontWeightMedium">
                            Dados do Usuário
                          </Box>
                        </Typography>

                        <Typography variant="body2">
                          <Box>Nome Completo:</Box>
                          <Box marginBottom={0.5} fontWeight="fontWeightMedium">
                            {loggedUser.name}
                          </Box>
                        </Typography>

                        {loggedUser.socialName && (
                          <Typography variant="body2">
                            <Box>Nome Social:</Box>
                            <Box
                              marginBottom={0.5}
                              fontWeight="fontWeightMedium"
                            >
                              {loggedUser.socialName}
                            </Box>
                          </Typography>
                        )}

                        <Typography variant="body2">
                          <Box>CPF:</Box>
                          <Box marginBottom={0.5} fontWeight="fontWeightMedium">
                            {formataCPF(loggedUser.cpf)}
                          </Box>
                        </Typography>

                        <Typography variant="body2">
                          <Box>Email:</Box>
                          <Box marginBottom={0.5} fontWeight="fontWeightMedium">
                            {loggedUser.email}
                          </Box>
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={12}>
                      <Paper variant="outlined" style={{ padding: 16 }}>
                        <Typography>
                          <Box marginBottom={1} fontWeight="fontWeightMedium">
                            Endereço
                          </Box>
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <AutoCompleteAsync
                              control={control}
                              name="endereco"
                              label="CEP"
                              variant="outlined"
                              defaultValue={
                                control.defaultValuesRef.current.endereco
                              }
                              fullWidth
                              required
                              helperText={errors.endereco?.cep?.message}
                              error={!!errors.endereco?.cep}
                            />
                          </Grid>

                          {watchEndereco.cep && (
                            <Grid item xs={12}>
                              <Box paddingLeft={1}>
                                <ListItemText disableTypography>
                                  <Typography variant="h6">
                                    {watchEndereco.logradouro_nome}
                                  </Typography>
                                  <Typography
                                    display="block"
                                    variant="subtitle2"
                                    color="textSecondary"
                                  >
                                    {`Bairro: ${watchEndereco.bairro_nome}`}
                                  </Typography>
                                  <Typography
                                    display="block"
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {`Zona: ${watchEndereco.zona_nome}`}
                                  </Typography>
                                </ListItemText>
                              </Box>
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            <TextField
                              inputRef={register}
                              name="numero"
                              label="Número"
                              variant="outlined"
                              type="tel"
                              fullWidth
                              required
                              helperText={errors.numero?.message}
                              error={!!errors.numero}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              inputRef={register}
                              name="complemento"
                              label="Complemento"
                              variant="outlined"
                              fullWidth
                              helperText={errors.complemento?.message}
                              error={!!errors.complemento}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="rg"
                        label="RG"
                        variant="outlined"
                        inputProps={{ maxLength: 20 }}
                        fullWidth
                        helperText={errors.rg?.message}
                        error={!!errors.rg}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MaskedInput
                        options={{
                          mask: '00000000000',
                        }}
                        control={control}
                        name="nis"
                        label="NIS"
                        type="tel"
                        variant="outlined"
                        fullWidth
                        helperText={errors.nis?.message}
                        error={!!errors.nis}
                      />
                    </Grid>

                    {isValidDataNascimento && idade < 18 && (
                      <Grid item xs={12}>
                        <TextFieldSelect
                          control={control}
                          name="menorMedidaProtecao"
                          label="Medida de Proteção"
                          fullWidth
                          defaultValue={
                            control.defaultValuesRef.current.menorMedidaProtecao
                          }
                          required
                          helperText={errors.menorMedidaProtecao?.message}
                          error={!!errors.menorMedidaProtecao}
                          oneOf={simNao}
                        />
                      </Grid>
                    )}

                    {isValidDataNascimento && idade >= 12 && idade < 18 && (
                      <Grid item xs={12}>
                        <TextFieldSelect
                          control={control}
                          name="menorMedidaSocioEducativa"
                          label="Cumprindo Medida Socioeducativa?"
                          fullWidth
                          defaultValue={
                            control.defaultValuesRef.current
                              .menorMedidaSocioEducativa
                          }
                          required
                          helperText={errors.menorMedidaSocioEducativa?.message}
                          error={!!errors.menorMedidaSocioEducativa}
                          oneOf={simNao}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <TextFieldSelect
                        control={control}
                        name="raca"
                        label="Raça"
                        fullWidth
                        defaultValue={control.defaultValuesRef.current.raca}
                        required
                        helperText={errors.raca?.message}
                        error={!!errors.raca}
                        oneOf={raca}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {fields.map((phoneItem, index) => {
                        return (
                          <Grid key={phoneItem.id} container spacing={1}>
                            <input
                              ref={register()}
                              type="hidden"
                              name={`telefones[${index}].idTelefone`}
                              defaultValue={phoneItem.idTelefone}
                            />
                            <Grid item xs>
                              <MaskedInput
                                options={{
                                  mask: [
                                    {
                                      mask: '(00) 0000-0000',
                                    },
                                    {
                                      mask: '(00) 00000-0000',
                                    },
                                  ],
                                }}
                                control={control}
                                name={`telefones[${index}].telefone`}
                                label="Telefone"
                                type="tel"
                                variant="outlined"
                                fullWidth
                                defaultValue={phoneItem.telefone}
                                required
                                helperText={
                                  errors.telefones?.[index]?.telefone?.message
                                }
                                error={!!errors.telefones?.[index]?.telefone}
                              />
                            </Grid>

                            <Grid item xs>
                              <TextFieldSelect
                                control={control}
                                name={`telefones[${index}].tipoTelefone`}
                                label="Tipo Telefone"
                                fullWidth
                                defaultValue={phoneItem.tipoTelefone}
                                required
                                helperText={
                                  errors.telefones?.[index]?.tipoTelefone
                                    ?.message
                                }
                                error={
                                  !!errors.telefones?.[index]?.tipoTelefone
                                }
                                oneOf={tipoTelefone}
                              />
                            </Grid>

                            {fields.length > 1 && (
                              <Grid item xs={1}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  height="100%"
                                >
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() =>
                                      handleRemoveTelefone(
                                        phoneItem as Telefone,
                                        index,
                                      )
                                    }
                                  >
                                    <RemoveCircleOutline />
                                  </IconButton>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>

                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="flex-start"
                        justify="flex-start"
                      >
                        <Box textAlign="center">
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={fields.length === 3}
                            startIcon={<AddCircleOutline />}
                            onClick={() => {
                              if (fields.length === 3) return;
                              append(
                                {
                                  idTelefone: 0,
                                  telefone: '',
                                  tipoTelefone: '',
                                },
                                false,
                              );
                            }}
                          >
                            Adicionar Telefone
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldSelect
                        control={control}
                        name="possuiDeficiencia"
                        label="Possui deficiência?"
                        fullWidth
                        defaultValue={
                          control.defaultValuesRef.current.possuiDeficiencia
                        }
                        required
                        helperText={errors.possuiDeficiencia?.message}
                        error={!!errors.possuiDeficiencia}
                        oneOf={simNao}
                      />
                    </Grid>

                    {watchPossuiDeficiencia === 'SIM' && (
                      <Grid item xs={12}>
                        <TextFieldSelect
                          control={control}
                          name="deficienciaTipo"
                          label="Tipo deficiência"
                          fullWidth
                          defaultValue={
                            control.defaultValuesRef.current.deficienciaTipo
                          }
                          required
                          helperText={errors.deficienciaTipo?.message}
                          error={!!errors.deficienciaTipo}
                          oneOf={deficienciaTipo}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        name="profissao"
                        label="Profissão"
                        variant="outlined"
                        fullWidth
                        helperText={errors.profissao?.message}
                        error={!!errors.profissao}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MaskedInput
                        options={{
                          mask: 'R$ num',
                          blocks: {
                            num: {
                              mask: Number,
                              scale: 2,
                              signed: false,
                              thousandsSeparator: '.',
                              padFractionalZeros: false,
                              normalizeZeros: false,
                              radix: ',',
                              mapToRadix: [','],
                            },
                          },
                        }}
                        control={control}
                        name="renda"
                        label="Renda"
                        type="tel"
                        variant="outlined"
                        fullWidth
                        required
                        helperText={
                          errors.renda?.message
                            ? errors.renda?.message
                            : "Informe '0' caso não tenha renda"
                        }
                        error={!!errors.renda}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldSelect
                        control={control}
                        name="servicoAssistencial"
                        label="Serviço Assistencial"
                        fullWidth
                        style={{ whiteSpace: 'normal' }}
                        defaultValue={
                          control.defaultValuesRef.current.servicoAssistencial
                        }
                        helperText={errors.servicoAssistencial?.message}
                        error={!!errors.servicoAssistencial}
                        oneOf={servicoAssistencial}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldSelect
                        control={control}
                        name="estadoCivil"
                        label="Estado Civil"
                        fullWidth
                        defaultValue={
                          control.defaultValuesRef.current.estadoCivil
                        }
                        required
                        helperText={errors.estadoCivil?.message}
                        error={!!errors.estadoCivil}
                        oneOf={estadoCivil}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldSelect
                        control={control}
                        name="sexo"
                        label="Sexo"
                        fullWidth
                        defaultValue={control.defaultValuesRef.current.sexo}
                        required
                        helperText={errors.sexo?.message}
                        error={!!errors.sexo}
                        oneOf={sexo}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldSelect
                        control={control}
                        name="escolaridade"
                        label="Escolaridade"
                        fullWidth
                        defaultValue={
                          control.defaultValuesRef.current.escolaridade
                        }
                        required
                        helperText={errors.escolaridade?.message}
                        error={!!errors.escolaridade}
                        oneOf={escolaridade}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    alignItems="flex-end"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Button
                        className={classes.button}
                        onClick={handleClickOpen}
                        variant="contained"
                        color="secondary"
                        size={matches ? 'small' : 'medium'}
                      >
                        Cancelar
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        onClick={handleBack}
                        className={classes.button}
                        size={matches ? 'small' : 'medium'}
                      >
                        Anterior
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                        className={classes.button}
                        size={matches ? 'small' : 'medium'}
                      >
                        Próximo
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Dialog
          open={openDialog}
          onClose={() => handleClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Cancelar inscrição?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Os dados não salvos serão excluídos
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              Não
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <DevTool control={control} />
    </>
  );
};

export default StepTwo;

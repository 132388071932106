import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
  ColumnApi,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { GetApp as GetAppIcon } from '@material-ui/icons';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { format, isValid, parse, parseISO } from 'date-fns';
import { normalizeDiacritics } from 'normalize-text';
import { apiRendaFranca } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import TextFieldSelect from '../../components/TextFieldSelect';
import { situacaoInscricao } from '../../utils/enums';
import MaskedInput from '../../components/MaskedInput';
import OperacoesRenderer from './OperacoesRenderer';
import CustomLoadingOverlay from '../../components/CustomLoadingOverlay';
import { formataCPF } from '../../utils/functions';
import { InscricaoAberturaApi as InscricaoAberturaSelectApi } from '../../components/AppBar';
import DiarioOficial from './DiarioOficial';

const useStyles = makeStyles(theme =>
  createStyles({
    flex: {
      flex: 1,
    },
  }),
);

interface Membro {
  cpf: string;
  nome: string;
}

interface InscricaoAbertura {
  idInscricaoAbertura: number;
  descricaoAbertura: string;
}

interface Usuario {
  id: string;
  name: string;
  email: string;
  cpf: string;
}

interface Inscricao {
  idInscricao: string;

  inscricaoAbertura: InscricaoAbertura;
  numeroInscricao: string;

  idUsuario: string;
  usuario: Usuario;

  nome: string;
  cpfPrincipal: string;

  posicaoConf: number;

  somaPontuacaoConf: number;
  quantidadeMenoresDezoitoAnosConf: number;
  quantidadeMaioresSessentaAnosConf: number;
  quantidadeDeficientesConf: number;
  dataNascimentoConf: string;

  idSituacaoInscricao: number;
  situacaoInscricao: string;
  descricaoSituacaoInscricao: string;

  dataInscricao: Date;
  numeroMembrosFamilia: number;
  rendaPerCapta: string;

  membros: Membro[];

  gerouCupom: boolean;
  telefones: string;
}

interface SelectOption {
  title: string;
  value: number | string;
}

export interface InscricoesResponse {
  payload: Inscricao[];
}

interface AnaliseInscricoesProps {
  setAppBarComponent(component: ReactNode): void;
}

const AnaliseInscricoes: React.FC<AnaliseInscricoesProps> = ({
  setAppBarComponent,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();

  const { control, register } = useForm({
    defaultValues: {
      edicaoInscricao: '',
      numeroInscricaoInicial: '',
      numeroInscricaoFinal: '',
      numeroPosicaoInicial: '',
      numeroPosicaoFinal: '',
      situacaoInscricao: '',
      cpf: '',
      nome: '',
    },
  });

  useEffect(() => {
    setAppBarComponent(
      <Typography variant="h6" color="inherit" className={classes.flex}>
        Análise Inscrições
      </Typography>,
    );
  }, [setAppBarComponent, classes.flex]);

  useEffect(() => {
    document.title = 'Renda Franca | Análise de Inscrições';
  });

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const edicaoInscricaoFilter = useRef<number | string>('');
  const numeroInscricaoInicialFilter = useRef<number>(0);
  const numeroInscricaoFinalFilter = useRef<number>(9999);
  const numeroPosicaoInicialFilter = useRef<number>(0);
  const numeroPosicaoFinalFilter = useRef<number>(9999);
  const situacaoInscricaoFilter = useRef<number | string>('');
  const cpfFilter = useRef<string>('');
  const nomeFilter = useRef<string>('');
  const [rowCount, setRowCount] = useState(0);
  const [appBarHeight, setAppBarHeight] = useState(64);
  const cardRef = useRef<HTMLElement>(null);
  const [cardHeight, setCardHeight] = useState(180);

  const [rowData, setRowData] = useState<Inscricao[]>([]);
  const [inscricaoAberturasSelect, setInscricaoAberturasSelect] = useState<
    SelectOption[]
  >([]);

  const [openExportDiarioOficial, setOpenExportDiarioOficial] = useState(false);

  const loadData = useCallback(async () => {
    try {
      const responseInscricoes = await apiRendaFranca.get<InscricoesResponse>(
        `/inscrever/listar-todos`,
      );

      const responseInscricaoAbertura =
        await apiRendaFranca.get<InscricaoAberturaSelectApi>(
          '/cadastros/inscricao-abertura',
        );

      const inscricaoAberturasSelectResponse =
        responseInscricaoAbertura.data.payload.map<SelectOption>(item => {
          return {
            title: item.descricao,
            value: item.id,
          };
        });

      inscricaoAberturasSelectResponse.splice(0, 0, {
        title: 'Selecione uma opção',
        value: '',
      });

      setRowData(responseInscricoes.data.payload);
      setInscricaoAberturasSelect(inscricaoAberturasSelectResponse);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
        signOut();
        return;
      }
      enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
    } finally {
      gridApi?.hideOverlay();
    }
  }, [enqueueSnackbar, signOut, gridApi]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);

      params.api.showLoadingOverlay();

      loadData();
    },
    [loadData],
  );

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    params.columnApi.autoSizeAllColumns();

    params.columnApi.applyColumnState({
      state: [
        {
          colId: 'numeroInscricao',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });
  }, []);

  const isExternalFilterPresent = useCallback(() => {
    return (
      edicaoInscricaoFilter.current !== '' ||
      numeroInscricaoInicialFilter.current !== 0 ||
      numeroInscricaoFinalFilter.current !== 9999 ||
      numeroPosicaoInicialFilter.current !== 0 ||
      numeroPosicaoFinalFilter.current !== 9999 ||
      situacaoInscricaoFilter.current !== '' ||
      cpfFilter.current !== '' ||
      nomeFilter.current !== ''
    );
  }, []);

  const externalFilterSituacaoChanged = useCallback(
    (newValue: string) => {
      situacaoInscricaoFilter.current = newValue;
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterCpfChanged = useCallback(
    (newValue: string) => {
      cpfFilter.current = newValue;
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNomeChanged = useCallback(
    (newValue: string) => {
      nomeFilter.current = normalizeDiacritics(newValue.toUpperCase());
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterEdicaoInscricaoChanged = useCallback(
    (newValue: string) => {
      edicaoInscricaoFilter.current = newValue;
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroInscricaoInicialChanged = useCallback(
    (newValue: string) => {
      numeroInscricaoInicialFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroInscricaoFinalChanged = useCallback(
    (newValue: string) => {
      numeroInscricaoFinalFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroPosicaoInicialChanged = useCallback(
    (newValue: string) => {
      numeroPosicaoInicialFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const externalFilterNumeroPosicaoFinalChanged = useCallback(
    (newValue: string) => {
      numeroPosicaoFinalFilter.current = Number(newValue);
      gridApi?.onFilterChanged();
    },
    [gridApi],
  );

  const doesExternalFilterPass = useCallback((node: RowNode) => {
    let boolEdicaoInscricao = true;
    if (edicaoInscricaoFilter.current !== '') {
      boolEdicaoInscricao =
        node.data.inscricaoAbertura.idInscricaoAbertura ===
        edicaoInscricaoFilter.current;
    }

    let boolNumeroInscricaoInicial = true;
    if (numeroInscricaoInicialFilter.current !== 0) {
      boolNumeroInscricaoInicial =
        node.data.numeroInscricao >= numeroInscricaoInicialFilter.current;
    }

    let boolNumeroInscricaoFinal = true;
    if (numeroInscricaoFinalFilter.current !== 9999) {
      boolNumeroInscricaoFinal =
        node.data.numeroInscricao <= numeroInscricaoFinalFilter.current;
    }

    let boolNumeroPosicaoInicial = true;
    if (numeroPosicaoInicialFilter.current !== 0) {
      boolNumeroPosicaoInicial =
        node.data.posicaoConf >= numeroPosicaoInicialFilter.current;
    }

    let boolNumeroPosicaoFinal = true;
    if (numeroPosicaoFinalFilter.current !== 9999) {
      boolNumeroPosicaoFinal =
        node.data.posicaoConf <= numeroPosicaoFinalFilter.current;
    }

    let boolSituacao = true;
    if (situacaoInscricaoFilter.current !== '') {
      boolSituacao =
        node.data.idSituacaoInscricao === situacaoInscricaoFilter.current;
    }

    let boolCpf = true;
    if (cpfFilter.current !== '') {
      const cpfFilterMembros = node.data.membros.filter((item: any) => {
        return item.cpf === cpfFilter.current;
      });

      if (cpfFilterMembros.length > 0) {
        boolCpf = true;
      } else {
        boolCpf = false;
      }
    }

    let boolNome = true;
    if (nomeFilter.current !== '') {
      const nomeFilterMembros = node.data.membros.filter((item: any) => {
        return normalizeDiacritics(item.nome.toUpperCase()).includes(
          nomeFilter.current,
        );
      });

      if (nomeFilterMembros.length > 0) {
        boolNome = true;
      } else {
        boolNome = false;
      }
    }

    return (
      boolEdicaoInscricao &&
      boolNumeroInscricaoInicial &&
      boolNumeroInscricaoFinal &&
      boolNumeroPosicaoInicial &&
      boolNumeroPosicaoFinal &&
      boolSituacao &&
      boolCpf &&
      boolNome
    );
  }, []);

  const dateFormatterDataInscricao = useCallback(
    (params: ValueFormatterParams): string => {
      return format(parseISO(params.value), 'dd/MM/yyyy');
    },
    [],
  );

  const dateFormatterDataNascimento = useCallback(
    (params: ValueFormatterParams): string => {
      if (isValid(parse(params.value, 'yyyy-MM-dd', new Date()))) {
        return format(
          parse(params.value, 'yyyy-MM-dd', new Date()),
          'dd/MM/yyyy',
        );
      }

      return '';
    },
    [],
  );

  const booleanGetter = useCallback((value: boolean): string => {
    return value === false ? 'Não' : 'Sim';
  }, []);

  const cpfFormatter = useCallback((params: ValueFormatterParams): string => {
    return formataCPF(params.value);
  }, []);

  const dateGetterDataInscricao = useCallback(
    (params: ValueGetterParams): string => {
      return format(parseISO(params.data.dataInscricao), 'yyyy-MM-dd HH:mm:ss');
    },
    [],
  );

  const dateGetterDataNascimento = useCallback(
    (params: ValueGetterParams): string => {
      if (
        isValid(parse(params.data.dataNascimentoConf, 'yyyy-MM-dd', new Date()))
      ) {
        return format(
          parse(params.data.dataNascimentoConf, 'yyyy-MM-dd', new Date()),
          'yyyy-MM-dd',
        );
      }

      return '';
    },
    [],
  );

  const nomeGetter = useCallback((params: ValueGetterParams): string => {
    return normalizeDiacritics(params.data.nome.trim().toUpperCase());
  }, []);

  const defaultColDef = {
    resizable: true,
    filter: true,
    sortable: true,
  };

  const handleClickExportCSV = useCallback(() => {
    const date = format(new Date(), 'yyyy-MM-dd HH.mm.ss');

    gridApi?.exportDataAsCsv({
      columnSeparator: ';',
      columnKeys: [
        'numeroInscricao',
        'nome',
        'cpfPrincipal',
        'situacaoInscricao',
        'posicaoConf',
        'somaPontuacaoConf',
        'quantidadeMenoresDezoitoAnosConf',
        'quantidadeMaioresSessentaAnosConf',
        'quantidadeDeficientesConf',
        'dataNascimentoConf',
        'dataInscricao',
        'numeroMembrosFamilia',
        'rendaPerCapta',
        'telefones',
        'gerouCupom',
        'usuario.email',
      ],
      fileName: `rendafranca-CSV-${date}.csv`,
    });
  }, [gridApi]);

  const handleClickExportDiarioOficial = useCallback(() => {
    setOpenExportDiarioOficial(true);
  }, []);

  const handleCloseExportDiarioOficial = useCallback(() => {
    setOpenExportDiarioOficial(false);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setAppBarHeight(
        Number(localStorage.getItem('@RendaFranca:app_bar_height') || 64),
      );

      setCardHeight(cardRef.current?.clientHeight || 180);
    });
  }, []);

  return (
    <>
      <div
        style={{
          height: `calc(100vh - ${appBarHeight}px - ${cardHeight}px`,
          width: '100%',
        }}
      >
        <Card ref={cardRef} style={{ display: 'flex', flex: 1 }}>
          <CardContent style={{ width: '100%', paddingBottom: 0 }}>
            <Grid container spacing={1} alignContent="stretch">
              <Grid item xs sm md lg>
                <Paper
                  variant="outlined"
                  style={{ padding: 8, height: '100%' }}
                >
                  <Typography gutterBottom variant="h5" component="h2">
                    Filtros
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs sm md lg style={{ minWidth: 170 }}>
                      <TextFieldSelect
                        control={control}
                        name="edicaoInscricao"
                        label="Edição Inscricao"
                        value={edicaoInscricaoFilter}
                        onChangeExternal={value =>
                          externalFilterEdicaoInscricaoChanged(value as string)
                        }
                        fullWidth
                        oneOf={inscricaoAberturasSelect}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroInscricaoInicial"
                        label="Inscrição Inicial"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value.lenght === 0) {
                            externalFilterNumeroInscricaoInicialChanged('0');
                          } else {
                            externalFilterNumeroInscricaoInicialChanged(value);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroInscricaoFinal"
                        label="Inscrição Final"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value) {
                            externalFilterNumeroInscricaoFinalChanged(value);
                          } else {
                            externalFilterNumeroInscricaoFinalChanged('9999');
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroPosicaoInicial"
                        label="Posição Inicial"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value.lenght === 0) {
                            externalFilterNumeroPosicaoInicialChanged('0');
                          } else {
                            externalFilterNumeroPosicaoInicialChanged(value);
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 140 }}>
                      <MaskedInput
                        options={{
                          mask: Number,
                        }}
                        control={control}
                        name="numeroPosicaoFinal"
                        label="Posição Final"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          if (value) {
                            externalFilterNumeroPosicaoFinalChanged(value);
                          } else {
                            externalFilterNumeroPosicaoFinalChanged('9999');
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 110 }}>
                      <TextFieldSelect
                        control={control}
                        name="situacaoInscricao"
                        label="Situação"
                        value={situacaoInscricaoFilter}
                        onChangeExternal={value =>
                          externalFilterSituacaoChanged(value as string)
                        }
                        fullWidth
                        oneOf={situacaoInscricao}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 150 }}>
                      <MaskedInput
                        options={{
                          mask: '000.000.000-00',
                        }}
                        control={control}
                        name="cpf"
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        onAccept={({ value }) => {
                          const cpfOnlyNumbers = value.replace(
                            /[^\d]/g,
                            '',
                          ) as string;

                          if (cpfOnlyNumbers.length === 11) {
                            externalFilterCpfChanged(cpfOnlyNumbers);
                          } else {
                            externalFilterCpfChanged('');
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs sm md lg style={{ minWidth: 130 }}>
                      <TextField
                        inputRef={register}
                        name="nome"
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        onChange={event => {
                          externalFilterNomeChanged(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Paper
                  variant="outlined"
                  style={{ padding: 8, height: '100%' }}
                >
                  <Typography gutterBottom variant="h5" component="h2">
                    Opções
                  </Typography>
                  <Grid container spacing={1} alignContent="stretch">
                    <Grid item xs sm md lg>
                      <Tooltip title="exportar lista formato csv">
                        <Button
                          style={{ width: '100%', height: '100%' }}
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<GetAppIcon />}
                          onClick={handleClickExportCSV}
                        >
                          CSV
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs sm md lg>
                      <Tooltip title="exportar lista diário oficial">
                        <Button
                          style={{ width: '100%', height: '100%' }}
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<GetAppIcon />}
                          onClick={handleClickExportDiarioOficial}
                        >
                          Diário Oficial
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item>
                <Typography variant="subtitle1" component="h5">
                  <Box paddingTop={2} fontWeight="fontWeightMedium">
                    {rowCount} {rowCount <= 1 ? 'inscrição' : 'inscrições'}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <div
          className="ag-theme-alpine"
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <AgGridReact
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            animateRows
            rowData={rowData}
            getRowNodeId={data => data.idInscricao}
            defaultColDef={defaultColDef}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            loadingOverlayComponent="customLoadingOverlay"
            loadingOverlayComponentParams={{
              loadingMessage: 'Aguarde...',
            }}
            frameworkComponents={{
              operacoesRenderer: OperacoesRenderer,
              customLoadingOverlay: CustomLoadingOverlay,
            }}
            onFilterChanged={filter => {
              setRowCount(filter.api.getDisplayedRowCount());
            }}
            onRowDataChanged={event => {
              setRowCount(event.api.getDisplayedRowCount());
            }}
            enableCellTextSelection
            applyColumnDefOrder
          >
            <AgGridColumn
              filter={false}
              sortable={false}
              resizable={false}
              width={80}
              cellRenderer="operacoesRenderer"
              cellStyle={{
                height: '100%',
                display: 'flex ',
                justifyContent: 'center',
                alignItems: 'center ',
              }}
            />

            <AgGridColumn
              field="numeroInscricao"
              headerName="Núm. Inscrição"
              filter="agNumberColumnFilter"
            />

            <AgGridColumn
              field="inscricaoAbertura.descricaoAbertura"
              headerName="Edição Inscrição"
            />

            <AgGridColumn
              field="nome"
              headerName="Nome"
              valueGetter={nomeGetter}
            />
            <AgGridColumn
              field="cpfPrincipal"
              headerName="CPF"
              valueFormatter={cpfFormatter}
            />

            <AgGridColumn
              field="descricaoSituacaoInscricao"
              headerName="Situação"
            />

            <AgGridColumn
              field="posicaoConf"
              headerName="Posição"
              filter="agNumberColumnFilter"
            />

            <AgGridColumn
              field="somaPontuacaoConf"
              headerName="Pontuação"
              filter="agNumberColumnFilter"
            />
            <AgGridColumn
              field="quantidadeMenoresDezoitoAnosConf"
              headerName="Núm. menores 18 anos"
              filter="agNumberColumnFilter"
            />
            <AgGridColumn
              field="quantidadeMaioresSessentaAnosConf"
              headerName="Núm. maiores 60 anos"
              filter="agNumberColumnFilter"
            />
            <AgGridColumn
              field="quantidadeDeficientesConf"
              headerName="Núm. deficientes"
              filter="agNumberColumnFilter"
            />
            <AgGridColumn
              field="dataNascimentoConf"
              headerName="Data de Nascimento"
              filter="agDateColumnFilter"
              valueFormatter={dateFormatterDataNascimento}
              valueGetter={dateGetterDataNascimento}
            />

            <AgGridColumn
              field="dataInscricao"
              headerName="Data da Inscrição"
              filter="agDateColumnFilter"
              valueFormatter={dateFormatterDataInscricao}
              valueGetter={dateGetterDataInscricao}
            />
            <AgGridColumn
              field="numeroMembrosFamilia"
              headerName="Núm. membros familiares"
              filter="agNumberColumnFilter"
            />
            <AgGridColumn
              field="rendaPerCapta"
              headerName="Renda per capta"
              filter="agNumberColumnFilter"
            />

            <AgGridColumn field="telefones" headerName="Telefones" />

            <AgGridColumn
              field="gerouCupom"
              headerName="Gerou Cupom?"
              valueGetter={(params: ValueGetterParams) =>
                booleanGetter(params.data.gerouCupom)
              }
            />

            <AgGridColumn field="usuario.email" headerName="Email" />
          </AgGridReact>
        </div>
      </div>
      <DiarioOficial
        open={openExportDiarioOficial}
        onClose={handleCloseExportDiarioOficial}
        gridApi={gridApi}
        gridColumnApi={gridColumnApi}
      />
    </>
  );
};

export default AnaliseInscricoes;

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Router, Route, Link, useHistory } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import {
  Menu as MenuIcon,
  PowerSettingsNew,
  Dashboard as DashboardIcon,
  Favorite as FavoriteIcon,
  VpnKey as VpnKeyIcon,
  LockOpen as LockOpenIcon,
  Assessment as AssessmentIcon,
  Receipt as ReceiptIcon,
  MenuBook as MenuBookIcon,
  AccountBalance as AccountBalanceIcon,
} from '@material-ui/icons';

import {
  Box,
  Divider,
  Grid,
  ListItemIcon,
  ListSubheader,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Home from '../pages/Home';

import { useAuth } from '../hooks/auth';
import { parseJwt } from '../utils/jwt';
import GeneratePassword from '../pages/GeneratePassword';
import AnaliseInscricoes from '../pages/AnaliseInscricoes';
import Inscricoes from '../pages/Inscricoes';
import { apiRendaFranca } from '../services/api';
import RendaFranca from '../pages/Inscrever/RendaFranca';
import InscricaoAbertura from '../pages/InscricaoAbertura';
import DiarioOficial from '../pages/DiarioOficial';
import Pagamento from '../pages/Pagamento';

const drawerWidth = 240;

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    flex: {
      flex: 1,
    },
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    toolbarMargin: theme.mixins.toolbar,
    aboveDrawer: {
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      flexGrow: 1,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

interface MyToolbarProps {
  children: React.ReactNode;
  onMenuClick(): void;
}

const MyToolbar: React.FC<MyToolbarProps> = ({ children, onMenuClick }) => {
  const classes = useStyles();
  const refAppBar = useRef<HTMLElement>(null);
  const [heightAppBar, setHeightAppBar] = useState(64);

  const handleHeightAppBar = useCallback(() => {
    const height = refAppBar.current?.clientHeight || 0;

    setHeightAppBar(height);

    localStorage.setItem('@RendaFranca:app_bar_height', height.toString());
  }, []);

  useEffect(() => {
    handleHeightAppBar();

    window.addEventListener('resize', () => {
      handleHeightAppBar();
    });
  }, [handleHeightAppBar]);

  return (
    <>
      <AppBar ref={refAppBar} className={classes.aboveDrawer}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={onMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <div>{children}</div>
        </Toolbar>
      </AppBar>
      <div style={{ minHeight: heightAppBar }} />
    </>
  );
};

interface Role {
  name: string;
}

export interface InscricaoAbertura {
  id: number;
  nome: string;
  descricao: string;
}

export interface InscricaoAberturaApi {
  payload: InscricaoAbertura[];
}

interface MyDrawerProps {
  open: boolean;
  onClose(): void;
  onItemClick(): void;
  setAppBarComponent(component: ReactNode): void;
}

const MyDrawer: React.FC<MyDrawerProps> = ({
  open,
  onClose,
  onItemClick,
  setAppBarComponent,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut, access_token } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOperador, setIsOperador] = useState(false);
  const [isGestor, setIsGestor] = useState(false);
  const [inscricaoAberturas, setInscricaoAberturas] = useState<
    InscricaoAbertura[]
  >([]);

  useEffect(() => {
    const resultParse = parseJwt(access_token);

    const roles = (resultParse.usuario.roles as Role[]) || [];

    const roleAdminFilter = roles.filter(role => role.name === 'ROLE_ADMIN');
    const roleOperadorFilter = roles.filter(
      role => role.name === 'ROLE_OPERADOR',
    );
    const roleGestorFilter = roles.filter(role => role.name === 'ROLE_GESTOR');

    setIsAdmin(roleAdminFilter.length > 0);
    setIsOperador(roleOperadorFilter.length > 0);
    setIsGestor(roleGestorFilter.length > 0);
  }, [access_token]);

  useEffect(() => {
    (async () => {
      try {
        const response = await apiRendaFranca.get<InscricaoAberturaApi>(
          '/cadastros/inscricao-abertura',
        );

        setInscricaoAberturas(response.data.payload);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
          signOut();
          return;
        }
        if (err.response && err.response.status === 404) {
          return;
        }
        enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar, signOut]);

  return (
    <Router history={history}>
      <Drawer
        variant="temporary"
        open={open}
        onClose={onClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          <ListItem
            button
            component={Link}
            to="/home"
            onClick={() => {
              onItemClick();
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText>Início</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
            <ListItemText>Inscrições</ListItemText>
          </ListItem>

          {inscricaoAberturas.map(inscricaoAbertura => {
            return (
              <List key={inscricaoAbertura.id} component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  component={Link}
                  to={`/inscricoes/${inscricaoAbertura.id}`}
                  onClick={() => {
                    onItemClick();
                  }}
                >
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary={inscricaoAbertura.descricao} />
                </ListItem>
              </List>
            );
          })}

          {(isAdmin || isOperador || isGestor) && (
            <ListItem
              button
              component={Link}
              to="/analise"
              onClick={() => {
                onItemClick();
              }}
            >
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText>Análise Inscrições</ListItemText>
            </ListItem>
          )}

          {(isAdmin || isGestor) && (
            <ListItem
              button
              component={Link}
              to="/diario-oficial"
              onClick={() => {
                onItemClick();
              }}
            >
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText>Diário Oficial</ListItemText>
            </ListItem>
          )}

          {(isAdmin || isOperador || isGestor) && (
            <ListItem
              button
              component={Link}
              to="/pagamento"
              onClick={() => {
                onItemClick();
              }}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText>Pagamento</ListItemText>
            </ListItem>
          )}

          <Divider />

          {(isAdmin || isGestor) && (
            <>
              <List>
                <ListSubheader disableSticky>Configurações</ListSubheader>
                <ListItem
                  button
                  component={Link}
                  to="/inscricao-abertura"
                  onClick={() => {
                    onItemClick();
                  }}
                >
                  <ListItemIcon>
                    <LockOpenIcon />
                  </ListItemIcon>
                  <ListItemText>Abertura de Inscrições</ListItemText>
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          {(isAdmin || isGestor) && (
            <>
              <List>
                <ListSubheader disableSticky>Admin</ListSubheader>
                <ListItem
                  button
                  component={Link}
                  to="/generatepassword"
                  onClick={() => {
                    onItemClick();
                  }}
                >
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText>Gerar Nova Senha</ListItemText>
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          <List>
            <ListItem button component={Link} to="/" onClick={signOut}>
              <ListItemIcon>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText>Sair</ListItemText>
            </ListItem>
          </List>
        </List>
        <Grid container style={{ position: 'absolute', bottom: '0' }}>
          <Grid item style={{ width: '100%' }}>
            <ListItem>
              <Box textAlign="center" width="100%">
                <ListItemText
                  secondary={`Versão: ${process.env.REACT_APP_VERSION}`}
                />
              </Box>
            </ListItem>
          </Grid>
        </Grid>
      </Drawer>
      <main className={classes.content}>
        <Route
          path="/home"
          render={() => <Home setAppBarComponent={setAppBarComponent} />}
        />
        <Route
          path="/inscrever/:id"
          render={() => <RendaFranca setAppBarComponent={setAppBarComponent} />}
        />
        <Route
          path="/inscricoes/:id"
          render={() => <Inscricoes setAppBarComponent={setAppBarComponent} />}
        />
        <Route
          path="/analise"
          render={() => (
            <AnaliseInscricoes setAppBarComponent={setAppBarComponent} />
          )}
        />
        <Route
          path="/diario-oficial"
          render={() => (
            <DiarioOficial setAppBarComponent={setAppBarComponent} />
          )}
        />
        <Route
          path="/pagamento"
          render={() => <Pagamento setAppBarComponent={setAppBarComponent} />}
        />
        <Route
          path="/inscricao-abertura"
          render={() => (
            <InscricaoAbertura setAppBarComponent={setAppBarComponent} />
          )}
        />
        <Route
          path="/generatepassword"
          render={() => (
            <GeneratePassword setAppBarComponent={setAppBarComponent} />
          )}
        />
      </main>
    </Router>
  );
};

const AppBarInteraction: React.FC = () => {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [appBarComponent, setAppBarComponent] = useState<React.ReactNode>(null);

  const toggleDrawer = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  const onItemClick = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  return (
    <div className={classes.root}>
      <MyToolbar onMenuClick={toggleDrawer}>{appBarComponent}</MyToolbar>
      <MyDrawer
        open={drawer}
        onClose={toggleDrawer}
        onItemClick={onItemClick}
        setAppBarComponent={setAppBarComponent}
      />
    </div>
  );
};

export default AppBarInteraction;

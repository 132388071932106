/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useState } from 'react';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Grid,
  CardContent,
  Button,
  InputAdornment,
  Box,
  IconButton,
  Collapse,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  AccountCircle,
  Lock,
  Visibility,
  VisibilityOff,
  Close as CloseIcon,
} from '@material-ui/icons';

import { Alert } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import logosIndex from '../../assets/logos_index.png';

import { useAuth } from '../../hooks/auth';

import { Background, BackgroundOverlay, LoginCard, LogosIndex } from './style';

interface SignInFormData {
  email: string;
  password: string;
}

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .required('E-mail obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória'),
});

const SignIn: React.FC = () => {
  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitting },
  } = useForm<SignInFormData>({
    resolver: yupResolver(SignInSchema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleMouseUpDownPassword = (): void => setShowPassword(!showPassword);
  const [openError, setOpenError] = React.useState(false);

  const { signIn } = useAuth();

  const history = useHistory();

  useEffect(() => {
    document.title = 'Renda Franca | Login';
  });

  const handleSubmitCallBack = useCallback(
    async (values: SignInFormData) => {
      try {
        await signIn({
          email: values.email,
          password: values.password,
        });
      } catch (err) {
        setOpenError(true);

        setTimeout(() => {
          setOpenError(false);
        }, 5000);
      }
    },
    [signIn],
  );

  return (
    <Background container>
      <BackgroundOverlay container justify="center" alignItems="center">
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <LoginCard
              style={{
                maxWidth: 400,
              }}
              elevation={12}
            >
              <CardContent>
                <LogosIndex src={logosIndex} alt="Logomarcas indexa" />

                <form noValidate onSubmit={handleSubmit(handleSubmitCallBack)}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <TextField
                        inputRef={register}
                        type="email"
                        name="email"
                        label="E-mail"
                        variant="outlined"
                        fullWidth
                        helperText={errors.email?.message}
                        error={!!errors.email}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box marginBottom={1}>
                        <TextField
                          inputRef={register}
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          label="Senha"
                          variant="outlined"
                          fullWidth
                          helperText={errors.password?.message}
                          error={!!errors.password}
                          InputProps={{
                            endAdornment: (
                              <>
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onMouseUp={handleMouseUpDownPassword}
                                    onMouseDown={handleMouseUpDownPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                                <InputAdornment position="end">
                                  <Lock />
                                </InputAdornment>
                              </>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12}>
                      <Collapse in={openError} timeout="auto">
                        <Alert
                          severity="error"
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenError(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          Ocorreu um erro ao fazer login, confira as
                          credenciais.
                        </Alert>
                      </Collapse>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        fullWidth
                        disabled={isSubmitting}
                      >
                        Login
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Box marginBottom={1}>
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          fullWidth
                          onClick={() => history.push('/signup')}
                        >
                          Cadastre-se
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
                <Typography gutterBottom variant="body1">
                  <Link to="/password/email">Esqueci minha senha</Link>
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                  <p>
                    ATEN&Ccedil;&Atilde;O! Antes de efetuar o seu cadastro, leia
                    atentamente as orienta&ccedil;&otilde;es do programa
                    clicando{' '}
                    <a
                      href="https://www.franca.sp.gov.br/acao-social/orientacoes-programa-renda-franca"
                      target="_blank"
                    >
                      aqui
                    </a>
                    .
                  </p>
                </Typography>
              </CardContent>
            </LoginCard>
          </Grid>
        </Grid>
      </BackgroundOverlay>
    </Background>
  );
};

export default SignIn;

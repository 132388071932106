import React from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Done as DoneIcon,
} from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { green } from '@material-ui/core/colors';
import {
  Background,
  BackgroundOverlay,
  PasswordEmailSuccessCard,
} from './style';

interface State {
  email: string;
}

const PasswordEmailSuccess: React.FC = () => {
  const history = useHistory();

  const location = useLocation();
  const { email } = (location.state as State) || '';

  return (
    <Background container>
      <BackgroundOverlay container alignContent="center" justify="center">
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <PasswordEmailSuccessCard style={{ maxWidth: 400 }} elevation={12}>
              <CardContent style={{ paddingBottom: 0 }}>
                <Box
                  marginBottom={1}
                  display="flex"
                  alignContent="center"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="h5" component="h2">
                    Tudo Pronto!
                  </Typography>

                  <CheckCircleIcon
                    fontSize="large"
                    style={{ height: '100%', color: green[500] }}
                  />
                </Box>
                <Typography variant="body1" component="h2">
                  Caso haja algum cadastro que corresponda aos dados informados,
                  você receberá no email informado (
                  <Typography display="inline">
                    <Box display="inline" fontWeight="fontWeightMedium">
                      {email}
                    </Box>
                  </Typography>
                  ) as instruções para alteração da senha.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DoneIcon />}
                  onClick={() => history.replace('/', {})}
                >
                  OK
                </Button>
              </CardActions>
            </PasswordEmailSuccessCard>
          </Grid>
        </Grid>
      </BackgroundOverlay>
    </Background>
  );
};

export default PasswordEmailSuccess;

import React, { useEffect, useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { DoneAll as DoneAllIcon, Clear as ClearIcon } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

interface PontuacaoList {
  namePontuacao: string;
  titlePontuacao: string;
  valuePontuacaoUsuario: number;
  pontuacaoConfirmada: string | null;
  valuePontuacaoConfirmada: number | null;
}

interface PontuacaoDetalheProps {
  pontuacaoList: PontuacaoList[];
}

const PaperComponent: React.FC = ({ children }) => (
  <Paper style={{ overflowX: 'auto' }}>{children}</Paper>
);

const PontuacaoDetalheConfirmada: React.FC<PontuacaoDetalheProps> = ({
  pontuacaoList,
}) => {
  const [pontuacaoGeralList, setPontuacaoGeralList] = useState<PontuacaoList[]>(
    [],
  );
  const [pontuacaoDesclassifList, setPontuacaoDesclassifList] = useState<
    PontuacaoList[]
  >([]);

  useEffect(() => {
    if (pontuacaoList) {
      const novaPontuacaoDesclassifList = [] as PontuacaoList[];

      const resideMunicipioMaisTresAnos = pontuacaoList.find(
        item => item.namePontuacao === 'resideMunicipioMaisTresAnos',
      );
      if (resideMunicipioMaisTresAnos) {
        novaPontuacaoDesclassifList.push(resideMunicipioMaisTresAnos);
      }

      const desempregadoSeisMeses = pontuacaoList.find(
        item => item.namePontuacao === 'desempregadoSeisMeses',
      );
      if (desempregadoSeisMeses) {
        novaPontuacaoDesclassifList.push(desempregadoSeisMeses);
      }

      const rendaPerCaptaAteMeioSalarioMinimo = pontuacaoList.find(
        item => item.namePontuacao === 'rendaPerCaptaAteMeioSalarioMinimo',
      );
      if (rendaPerCaptaAteMeioSalarioMinimo) {
        novaPontuacaoDesclassifList.push(rendaPerCaptaAteMeioSalarioMinimo);
      }

      setPontuacaoDesclassifList(novaPontuacaoDesclassifList);

      const novaPontuacaoGeralList = [] as PontuacaoList[];

      const resideParceiro = pontuacaoList.find(
        item => item.namePontuacao === 'resideParceiro',
      );
      if (resideParceiro) {
        novaPontuacaoGeralList.push(resideParceiro);
      }

      const existeMembroDeficiente = pontuacaoList.find(
        item => item.namePontuacao === 'existeMembroDeficiente',
      );
      if (existeMembroDeficiente) {
        novaPontuacaoGeralList.push(existeMembroDeficiente);
      }

      const existeParticipanteServicoAssistencial = pontuacaoList.find(
        item => item.namePontuacao === 'existeParticipanteServicoAssistencial',
      );
      if (existeParticipanteServicoAssistencial) {
        novaPontuacaoGeralList.push(existeParticipanteServicoAssistencial);
      }

      const existeMenorMedidaProtetiva = pontuacaoList.find(
        item => item.namePontuacao === 'existeMenorMedidaProtetiva',
      );
      if (existeMenorMedidaProtetiva) {
        novaPontuacaoGeralList.push(existeMenorMedidaProtetiva);
      }

      const existeMenorMedidaSocioEducativa = pontuacaoList.find(
        item => item.namePontuacao === 'existeMenorMedidaSocioEducativa',
      );
      if (existeMenorMedidaSocioEducativa) {
        novaPontuacaoGeralList.push(existeMenorMedidaSocioEducativa);
      }

      setPontuacaoGeralList(novaPontuacaoGeralList);
    }
  }, [pontuacaoList]);

  return (
    <TableContainer component={PaperComponent}>
      <Table size="small">
        <TableHead>
          <TableRow style={{ border: '2px solid rgb(82 128 177)' }}>
            <TableCell>Item</TableCell>
            <TableCell align="center">Pontuação Calculada</TableCell>
            <TableCell align="center">Pontuação Confirmada</TableCell>
            <TableCell align="center">Confirmado?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pontuacaoDesclassifList.map((item, index) => {
            let colorTableRow;
            if (item.pontuacaoConfirmada === 'sim') {
              const { 200: colorGreen } = green;
              colorTableRow = colorGreen;
            } else if (item.pontuacaoConfirmada === 'nao') {
              const { 200: colorGreen } = red;
              colorTableRow = colorGreen;
            }

            let borderTop;
            let borderBottom;
            if (index === 0) {
              borderTop = '2px solid rgb(82 128 177)';
            }

            if (index === pontuacaoDesclassifList.length - 1) {
              borderBottom = '2px solid rgb(82 128 177)';
            }

            return (
              <TableRow
                key={item.titlePontuacao}
                hover
                style={{
                  backgroundColor: colorTableRow,
                  borderTop,
                  borderLeft: '2px solid rgb(82 128 177)',
                  borderRight: '2px solid rgb(82 128 177)',
                  borderBottom,
                }}
              >
                <TableCell component="th" scope="row">
                  {item.titlePontuacao}
                </TableCell>

                <TableCell align="center">
                  {item.valuePontuacaoUsuario}
                </TableCell>
                <TableCell align="center">
                  {item.valuePontuacaoConfirmada}
                </TableCell>
                <TableCell align="center">
                  <ToggleButtonGroup value={item.pontuacaoConfirmada} exclusive>
                    <ToggleButton size="small" value="sim">
                      <DoneAllIcon htmlColor={green[700]} />
                    </ToggleButton>
                    <ToggleButton size="small" value="nao">
                      <ClearIcon htmlColor={red[700]} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}

          {pontuacaoGeralList.map((item, index) => {
            let colorTableRow;
            if (item.pontuacaoConfirmada === 'sim') {
              const { 200: colorGreen } = green;
              colorTableRow = colorGreen;
            } else if (item.pontuacaoConfirmada === 'nao') {
              const { 200: colorGreen } = red;
              colorTableRow = colorGreen;
            }

            let borderTop;
            let borderBottom;
            if (index === 0) {
              borderTop = '2px solid rgb(82 128 177)';
            }

            if (index === pontuacaoGeralList.length - 1) {
              borderBottom = '2px solid rgb(82 128 177)';
            }

            return (
              <TableRow
                key={item.titlePontuacao}
                hover
                style={{
                  backgroundColor: colorTableRow,
                  borderTop,
                  borderLeft: '2px solid rgb(82 128 177)',
                  borderRight: '2px solid rgb(82 128 177)',
                  borderBottom,
                }}
              >
                <TableCell component="th" scope="row">
                  {item.titlePontuacao}
                </TableCell>
                <TableCell align="center">
                  {item.valuePontuacaoUsuario}
                </TableCell>
                <TableCell align="center">
                  {item.valuePontuacaoConfirmada}
                </TableCell>
                <TableCell align="center">
                  <ToggleButtonGroup value={item.pontuacaoConfirmada} exclusive>
                    <ToggleButton size="small" value="sim">
                      <DoneAllIcon htmlColor={green[700]} />
                    </ToggleButton>
                    <ToggleButton size="small" value="nao">
                      <ClearIcon htmlColor={red[700]} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PontuacaoDetalheConfirmada;

import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Collapse,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  DoneAll as DoneAllIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import {
  Alert,
  AlertTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';

interface PontuacaoList {
  namePontuacao: string;
  titlePontuacao: string;
  valuePontuacaoUsuario: number;
  pontuacaoConfirmada: string | null;
  valuePontuacaoConfirmada: number | null;
}

interface PontuacaoDetalheProps {
  pontuacaoList: PontuacaoList[];
  somaPontuacaoOriginal: number;
  onDesclassif(isDesclassif: boolean): void;
  onChange(pontuacaoList: PontuacaoList[]): void;
}

const PaperComponent: React.FC = ({ children }) => <Paper>{children}</Paper>;

const PontuacaoDetalhe: React.FC<PontuacaoDetalheProps> = ({
  pontuacaoList,
  somaPontuacaoOriginal,
  onDesclassif,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [pontuacaoGeralList, setPontuacaoGeralList] = useState<PontuacaoList[]>(
    [],
  );
  const [pontuacaoDesclassifList, setPontuacaoDesclassifList] = useState<
    PontuacaoList[]
  >([]);
  const [isDesclassif, setIsDesclassif] = useState(false);
  const [somaPontuacaoConfirmada, setSomaPontuacaoConfirmada] = useState<
    number | null
  >(null);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    onDesclassif(isDesclassif);
  }, [isDesclassif, onDesclassif]);

  useEffect(() => {
    const pontuacaoDesclassifNotNull = pontuacaoDesclassifList.filter(
      item => item.valuePontuacaoConfirmada !== null,
    );

    const pontuacaoGeralNotNull = pontuacaoGeralList.filter(
      item => item.valuePontuacaoConfirmada !== null,
    );

    if (
      pontuacaoDesclassifNotNull.length > 0 ||
      pontuacaoGeralNotNull.length > 0
    ) {
      let somaConfirmadaDesclassif = 0;
      pontuacaoDesclassifNotNull.forEach(item => {
        somaConfirmadaDesclassif += item.valuePontuacaoConfirmada || 0;
      });

      let somaConfirmadaGeral = 0;
      pontuacaoGeralList.forEach(item => {
        somaConfirmadaGeral += item.valuePontuacaoConfirmada || 0;
      });

      setSomaPontuacaoConfirmada(
        somaConfirmadaDesclassif + somaConfirmadaGeral,
      );
    } else {
      setSomaPontuacaoConfirmada(null);
    }
  }, [pontuacaoDesclassifList, pontuacaoGeralList]);

  const handleConfirmacaoDesclassif = useCallback(
    (newConfirmacao: string, index: number) => {
      setPontuacaoDesclassifList(previous => {
        const newPontuacaoDesclassifList = [...previous];

        newPontuacaoDesclassifList[index].pontuacaoConfirmada = newConfirmacao;

        if (newConfirmacao === 'sim') {
          newPontuacaoDesclassifList[index].valuePontuacaoConfirmada =
            newPontuacaoDesclassifList[index].valuePontuacaoUsuario;
        } else if (newConfirmacao === 'nao') {
          if (newPontuacaoDesclassifList[index].valuePontuacaoUsuario === 0) {
            newPontuacaoDesclassifList[index].valuePontuacaoConfirmada = 1;
          } else if (
            newPontuacaoDesclassifList[index].valuePontuacaoUsuario === 1
          ) {
            newPontuacaoDesclassifList[index].valuePontuacaoConfirmada = 0;
          }
        } else {
          newPontuacaoDesclassifList[index].valuePontuacaoConfirmada = null;
        }

        let desclassif = false;
        newPontuacaoDesclassifList.forEach(item => {
          if (item.valuePontuacaoConfirmada === 0) {
            desclassif = true;
          }
        });
        setIsDesclassif(desclassif);

        return newPontuacaoDesclassifList;
      });

      onChange([...pontuacaoDesclassifList, ...pontuacaoGeralList]);
    },
    [onChange, pontuacaoDesclassifList, pontuacaoGeralList],
  );

  const handleConfirmacaoGeral = useCallback(
    (newConfirmacao: string, index: number) => {
      setPontuacaoGeralList(previous => {
        const newPontuacaoList = [...previous];

        newPontuacaoList[index].pontuacaoConfirmada = newConfirmacao;

        if (newConfirmacao === 'sim') {
          newPontuacaoList[index].valuePontuacaoConfirmada =
            newPontuacaoList[index].valuePontuacaoUsuario;
        } else if (newConfirmacao === 'nao') {
          if (newPontuacaoList[index].valuePontuacaoUsuario === 0) {
            newPontuacaoList[index].valuePontuacaoConfirmada = 1;
          } else if (newPontuacaoList[index].valuePontuacaoUsuario === 1) {
            newPontuacaoList[index].valuePontuacaoConfirmada = 0;
          }
        } else {
          newPontuacaoList[index].valuePontuacaoConfirmada = null;
        }

        return newPontuacaoList;
      });

      onChange([...pontuacaoDesclassifList, ...pontuacaoGeralList]);
    },
    [onChange, pontuacaoDesclassifList, pontuacaoGeralList],
  );

  useEffect(() => {
    if (pontuacaoList) {
      const novaPontuacaoDesclassifList = [] as PontuacaoList[];

      const resideMunicipioMaisTresAnos = pontuacaoList.find(
        item => item.namePontuacao === 'resideMunicipioMaisTresAnos',
      );
      if (resideMunicipioMaisTresAnos) {
        novaPontuacaoDesclassifList.push(resideMunicipioMaisTresAnos);
      }

      const desempregadoSeisMeses = pontuacaoList.find(
        item => item.namePontuacao === 'desempregadoSeisMeses',
      );
      if (desempregadoSeisMeses) {
        novaPontuacaoDesclassifList.push(desempregadoSeisMeses);
      }

      const rendaPerCaptaAteMeioSalarioMinimo = pontuacaoList.find(
        item => item.namePontuacao === 'rendaPerCaptaAteMeioSalarioMinimo',
      );
      if (rendaPerCaptaAteMeioSalarioMinimo) {
        novaPontuacaoDesclassifList.push(rendaPerCaptaAteMeioSalarioMinimo);
      }

      let desclassif = false;
      novaPontuacaoDesclassifList.forEach(item => {
        if (item.valuePontuacaoConfirmada === 0) {
          desclassif = true;
        }
      });
      setIsDesclassif(desclassif);

      setPontuacaoDesclassifList(novaPontuacaoDesclassifList);

      const novaPontuacaoGeralList = [] as PontuacaoList[];

      const resideParceiro = pontuacaoList.find(
        item => item.namePontuacao === 'resideParceiro',
      );
      if (resideParceiro) {
        novaPontuacaoGeralList.push(resideParceiro);
      }

      const existeMembroDeficiente = pontuacaoList.find(
        item => item.namePontuacao === 'existeMembroDeficiente',
      );
      if (existeMembroDeficiente) {
        novaPontuacaoGeralList.push(existeMembroDeficiente);
      }

      const existeParticipanteServicoAssistencial = pontuacaoList.find(
        item => item.namePontuacao === 'existeParticipanteServicoAssistencial',
      );
      if (existeParticipanteServicoAssistencial) {
        novaPontuacaoGeralList.push(existeParticipanteServicoAssistencial);
      }

      const existeMenorMedidaProtetiva = pontuacaoList.find(
        item => item.namePontuacao === 'existeMenorMedidaProtetiva',
      );
      if (existeMenorMedidaProtetiva) {
        novaPontuacaoGeralList.push(existeMenorMedidaProtetiva);
      }

      const existeMenorMedidaSocioEducativa = pontuacaoList.find(
        item => item.namePontuacao === 'existeMenorMedidaSocioEducativa',
      );
      if (existeMenorMedidaSocioEducativa) {
        novaPontuacaoGeralList.push(existeMenorMedidaSocioEducativa);
      }

      setPontuacaoGeralList(novaPontuacaoGeralList);
    }
  }, [pontuacaoList]);

  const getHtmlColorButton = useCallback(
    (color: string): string | undefined => {
      if (isDesclassif) {
        return undefined;
      }
      return color;
    },
    [isDesclassif],
  );

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={`Pontuação: ${
            somaPontuacaoConfirmada !== null
              ? somaPontuacaoConfirmada
              : somaPontuacaoOriginal
          }`}
          secondary="(Clique para mais detalhes)"
          primaryTypographyProps={{ variant: 'subtitle1' }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box paddingLeft={2}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs sm md lg style={{ maxWidth: 820 }}>
              <TableContainer component={PaperComponent}>
                <Table size="small">
                  <TableHead>
                    <TableRow style={{ border: '2px solid rgb(82 128 177)' }}>
                      <TableCell>Item</TableCell>
                      <TableCell align="center">Pontuação Calculada</TableCell>
                      <TableCell align="center">Pontuação Confirmada</TableCell>
                      <TableCell align="center">Confirmado?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pontuacaoDesclassifList.map((item, index) => {
                      let colorTableRow;
                      if (item.pontuacaoConfirmada === 'sim') {
                        const { 200: colorGreen } = green;
                        colorTableRow = colorGreen;
                      } else if (item.pontuacaoConfirmada === 'nao') {
                        const { 200: colorGreen } = red;
                        colorTableRow = colorGreen;
                      }

                      let borderTop;
                      let borderBottom;
                      if (index === 0) {
                        borderTop = '2px solid rgb(82 128 177)';
                      }

                      if (index === pontuacaoDesclassifList.length - 1) {
                        borderBottom = '2px solid rgb(82 128 177)';
                      }

                      return (
                        <TableRow
                          key={item.titlePontuacao}
                          hover
                          style={{
                            backgroundColor: colorTableRow,
                            borderTop,
                            borderLeft: '2px solid rgb(82 128 177)',
                            borderRight: '2px solid rgb(82 128 177)',
                            borderBottom,
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {item.titlePontuacao}
                          </TableCell>

                          <TableCell align="center">
                            {item.valuePontuacaoUsuario}
                          </TableCell>
                          <TableCell align="center">
                            {item.valuePontuacaoConfirmada}
                          </TableCell>
                          <TableCell align="center">
                            <ToggleButtonGroup
                              value={item.pontuacaoConfirmada}
                              exclusive
                              onChange={(event, value) =>
                                handleConfirmacaoDesclassif(value, index)
                              }
                            >
                              <ToggleButton size="small" value="sim">
                                <DoneAllIcon htmlColor={green[700]} />
                              </ToggleButton>
                              <ToggleButton size="small" value="nao">
                                <ClearIcon htmlColor={red[700]} />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {pontuacaoGeralList.map((item, index) => {
                      let colorTableRow;
                      if (item.pontuacaoConfirmada === 'sim') {
                        const { 200: colorGreen } = green;
                        colorTableRow = colorGreen;
                      } else if (item.pontuacaoConfirmada === 'nao') {
                        const { 200: colorGreen } = red;
                        colorTableRow = colorGreen;
                      }

                      let borderTop;
                      let borderBottom;
                      if (index === 0) {
                        borderTop = '2px solid rgb(82 128 177)';
                      }

                      if (index === pontuacaoGeralList.length - 1) {
                        borderBottom = '2px solid rgb(82 128 177)';
                      }

                      return (
                        <TableRow
                          key={item.titlePontuacao}
                          hover
                          style={{
                            backgroundColor: colorTableRow,
                            borderTop,
                            borderLeft: '2px solid rgb(82 128 177)',
                            borderRight: '2px solid rgb(82 128 177)',
                            borderBottom,
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {item.titlePontuacao}
                          </TableCell>
                          <TableCell align="center">
                            {item.valuePontuacaoUsuario}
                          </TableCell>
                          <TableCell align="center">
                            {item.valuePontuacaoConfirmada}
                          </TableCell>
                          <TableCell align="center">
                            <ToggleButtonGroup
                              value={item.pontuacaoConfirmada}
                              exclusive
                              onChange={(event, value) =>
                                handleConfirmacaoGeral(value, index)
                              }
                            >
                              <ToggleButton
                                disabled={isDesclassif}
                                size="small"
                                value="sim"
                              >
                                <DoneAllIcon
                                  htmlColor={getHtmlColorButton(green[700])}
                                />
                              </ToggleButton>
                              <ToggleButton
                                disabled={isDesclassif}
                                size="small"
                                value="nao"
                              >
                                <ClearIcon
                                  htmlColor={getHtmlColorButton(red[700])}
                                />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              item
              xs
              sm
              md
              lg
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {isDesclassif && (
                <Alert severity="error" style={{ maxWidth: 600, margin: 16 }}>
                  <AlertTitle>Candidato desclassificado</AlertTitle>
                  <Grid container style={{ width: '100%' }}>
                    <Grid item style={{ width: '100%' }}>
                      <Typography gutterBottom variant="body2">
                        O candidato será desclassificado se não atender um dos
                        itens abaixo:
                      </Typography>

                      <Typography variant="body2">
                        <ul>
                          <li>Residir no município há mais de 3 (três) anos</li>
                          <li>
                            Estar em situação de desemprego há pelo menos 6
                            (seis) meses
                          </li>
                          <li>
                            Apresentar renda familiar mensal per capita de até
                            meio salário mínimo (igual ou inferior a R$ 550,00)
                          </li>
                        </ul>
                      </Typography>
                    </Grid>
                  </Grid>
                </Alert>
              )}
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </>
  );
};

export default PontuacaoDetalhe;

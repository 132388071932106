import styled from 'styled-components';
import { Card, Grid } from '@material-ui/core';

import signInBackgroundImg from '../../../assets/francaFundoAumentar.jpg';

export const Background = styled(Grid)`
  min-height: 100vh;
  background: url(${signInBackgroundImg}) no-repeat fixed;
  background-size: cover;
`;

export const BackgroundOverlay = styled(Grid)`
  background-color: rgba(0, 81, 162, 0.75);
  min-height: 100vh;
`;

export const PasswordResetSuccessCard = styled(Card)`
  min-width: 310px;
  margin: 8px;
  padding: 0 10px;
`;

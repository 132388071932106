import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import RendaFrancaLogo from '../../assets/renda_franca_logo.jpeg';
import { Params } from '../../components/AppBarMenu';
import { apiRendaFranca } from '../../services/api';
import {
  InscricaoAberturaApi,
  InscricaoAbertura,
} from '../../components/AppBar';
import { useAuth } from '../../hooks/auth';

interface InscreverProps {
  setAppBarComponent(component: ReactNode): void;
}

const Inscrever: React.FC<InscreverProps> = ({ setAppBarComponent }) => {
  const history = useHistory();
  const params = useParams<Params>();
  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [inscricaoAberturas, setInscricaoAberturas] = useState<
    InscricaoAbertura[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await apiRendaFranca.get<InscricaoAberturaApi>(
          '/cadastros/inscricao-abertura',
        );

        setInscricaoAberturas(response.data.payload);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
          signOut();
          return;
        }
        if (err.response && err.response.status === 404) {
          return;
        }
        enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar, signOut]);

  const getTitle = useCallback((): string => {
    const findInscricaoAberturas = inscricaoAberturas.find(
      inscricaoAbertura => inscricaoAbertura.id === parseInt(params.id, 10),
    );

    return findInscricaoAberturas?.descricao || '';
  }, [inscricaoAberturas, params.id]);

  return (
    <Grid container justify="space-evenly" alignItems="stretch">
      <Grid item xs sm md lg style={{ maxWidth: 340, alignSelf: 'center' }}>
        <Card elevation={5}>
          <CardMedia
            style={{ height: 140 }}
            component="img"
            image={RendaFrancaLogo}
            title="RendaFrancaLogo"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h4">
              {getTitle()}
            </Typography>
            <CardActionArea
              onClick={e => {
                e.preventDefault();
                window.open(
                  'https://www.franca.sp.gov.br/acao-social/orientacoes-programa-renda-franca',
                  '_blank',
                );
              }}
            >
              <Typography variant="body2" color="textSecondary" component="p">
                <p>
                  ATEN&Ccedil;&Atilde;O! Antes de iniciar sua
                  inscri&ccedil;&atilde;o, leia atentamente as
                  orienta&ccedil;&otilde;es do programa clicando{' '}
                  <a
                    href="https://www.franca.sp.gov.br/acao-social/orientacoes-programa-renda-franca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    aqui
                  </a>
                </p>
              </Typography>
            </CardActionArea>
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setAppBarComponent('Renda Franca');
                history.push(`/inscrever/${params.id}`);
              }}
              variant="outlined"
            >
              Efetuar inscrição
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Inscrever;

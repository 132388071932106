import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons';
import * as Yup from 'yup';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';

import { addServerErrors } from '../../../utils/transformErrors';
import { apiUsuario } from '../../../services/api';
import { Background, BackgroundOverlay, PasswordResetCard } from './style';
import { parseJwt } from '../../../utils/jwt';

interface PasswordResetFormData {
  password: string;
  passwordConfirmation: string;
}

interface TokenData {
  email: string;
  name: string;
}

const PasswordEmailSchema = Yup.object().shape({
  password: Yup.string()
    .required('Campo senha obrigatório')
    .matches(
      /^(?=.*[0-9])(?=.*[A-z]).{8,}$/,
      'A senha deverá conter pelo menos 8 caracteres com no mínimo uma letra e um número',
    ),
  passwordConfirmation: Yup.string()
    .required('Campo confirmar senha obrigatório')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
});

const PasswordReset: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [tokenData, setTokenData] = useState<TokenData>({
    email: '',
    name: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting },
  } = useForm<PasswordResetFormData>({
    resolver: yupResolver(PasswordEmailSchema),
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const { tokenLogin, tokenEmail } = queryString.parse(location.search);

        let params = {};
        let token = '' as string;
        if (tokenLogin) {
          token = tokenLogin as string;
          params = {
            tokenLogin,
          };
        }
        if (tokenEmail) {
          token = tokenEmail as string;
          params = {
            tokenEmail,
          };
        }

        await apiUsuario.get(`/password/reset/check-token`, {
          params,
        });

        const resultParse = parseJwt(token as string);

        const tokenDataParsed =
          resultParse.usuario || ({ email: '', name: '' } as TokenData);

        setTokenData(tokenDataParsed);
      } catch (err) {
        if (err.response && err.response.status === 400) {
          let title;
          const { errorDetails } = err.response?.data || [];

          if (errorDetails.length) {
            title = errorDetails[0]?.messageUser || 'erro ao consultar token';
          }

          let body;
          if (title === 'token expirado') {
            body = 'O link que você clicou para alteração de senha expirou.';
          } else if (title === 'token inválido') {
            body = 'o token do link que você clicou é inválido.';
          } else {
            body = '';
          }

          history.push('/password/reset/error', {
            title,
            body,
          });

          return;
        }
        enqueueSnackbar('Erro ao consultar token', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    })();
  }, [enqueueSnackbar, location.search, history]);

  const handleSubmitCallBack = useCallback(
    async (values: PasswordResetFormData) => {
      try {
        const { tokenLogin, tokenEmail } = queryString.parse(location.search);

        let token = '' as string;
        let tipoTokenHeader = {};
        if (tokenLogin) {
          token = tokenLogin as string;
          tipoTokenHeader = { tokenLogin: true };
        }
        if (tokenEmail) {
          token = tokenEmail as string;
          tipoTokenHeader = { tokenEmail: true };
        }

        await apiUsuario.post('/password/reset', values, {
          headers: {
            authorization: `Bearer ${token}`,
            ...tipoTokenHeader,
          },
        });

        history.replace('/password/reset/success');
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;

          if (errorDetails) {
            addServerErrors<PasswordResetFormData>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao solicitar alteração de senha', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, setError, location.search, history],
  );

  return (
    <Background container>
      <BackgroundOverlay container alignContent="center" justify="center">
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <PasswordResetCard style={{ maxWidth: 400 }} elevation={12}>
              <CardContent style={{ paddingBottom: 16 }}>
                {loading && (
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={80}
                    >
                      <CircularProgress />
                    </Box>
                  </Grid>
                )}

                {!loading && (
                  <>
                    <Typography gutterBottom variant="h5" component="h2">
                      Alterar senha
                    </Typography>

                    <form
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit(handleSubmitCallBack)}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        spacing={1}
                      >
                        <Grid item xs={12}>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle1"
                              component="h5"
                              style={{ paddingRight: 4 }}
                            >
                              Email:
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightMedium">
                                {tokenData.email}
                              </Box>
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="subtitle1"
                              component="h5"
                              style={{ paddingRight: 4 }}
                            >
                              Nome:
                            </Typography>
                            <Typography>
                              <Box fontWeight="fontWeightMedium">
                                {tokenData.name}
                              </Box>
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            inputRef={register}
                            name="password"
                            label="Senha"
                            type="password"
                            variant="outlined"
                            fullWidth
                            required
                            helperText={errors.password?.message}
                            error={!!errors.password}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Box marginBottom={2}>
                            <TextField
                              inputRef={register}
                              name="passwordConfirmation"
                              label="Confirmar Senha"
                              type="password"
                              variant="outlined"
                              fullWidth
                              required
                              helperText={errors.passwordConfirmation?.message}
                              error={!!errors.passwordConfirmation}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        alignItems="flex-end"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            startIcon={<CloseIcon />}
                            onClick={() => history.replace('/')}
                          >
                            Cancelar
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            size="small"
                            color="primary"
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting}
                          >
                            Alterar
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </>
                )}
              </CardContent>
            </PasswordResetCard>
          </Grid>
        </Grid>
      </BackgroundOverlay>
    </Background>
  );
};

export default PasswordReset;

import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';
// eslint-disable-next-line import/no-duplicates
import {
  KeyboardDateTimePicker as KeyboardDateTimePickerMaterialUi,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const KeyBoardDateTimePicker: React.FC<KeyboardDateTimePickerProps> = props => {
  const {
    name,
    label,
    value,
    required,
    minDate,
    maxDate,
    helperText,
    error,
    inputValue,
    onChange,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <KeyboardDateTimePickerMaterialUi
        ampm={false}
        name={name}
        label={label}
        type="tel"
        inputVariant="outlined"
        value={value}
        inputValue={inputValue}
        fullWidth
        required={required}
        invalidDateMessage=""
        minDateMessage=""
        maxDateMessage=""
        minDate={minDate}
        maxDate={maxDate}
        helperText={helperText}
        error={error}
        onChange={onChange}
        format="dd/MM/yyyy HH:mm:ss"
        cancelLabel="Cancelar"
      />
    </MuiPickersUtilsProvider>
  );
};

export default KeyBoardDateTimePicker;

import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

interface CustomLoadingOverlayProps {
  loadingMessage: string;
}

const CustomLoadingOverlay: React.FC<CustomLoadingOverlayProps> = ({
  loadingMessage,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <CircularProgress size={60} style={{ margin: 16 }} />
      <Typography>{loadingMessage}</Typography>
    </Box>
  );
};

export default CustomLoadingOverlay;

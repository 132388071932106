import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { InscricaoAbertura } from '..';

import { apiRendaFranca } from '../../../services/api';
import { addServerErrors } from '../../../utils/transformErrors';

interface DialogRenomearInscricaoAberturaProps {
  open: boolean;
  onClose: (cancel: boolean) => void;
  inscricaoAbertura: InscricaoAbertura | null;
}

const FormSchema = Yup.object().shape({
  descricao: Yup.string().required('Campo obrigatório'),
});

interface FormValues {
  descricao: string;
}

const DialogRenomearInscricaoAbertura: React.FC<DialogRenomearInscricaoAberturaProps> =
  ({ open, onClose, inscricaoAbertura }) => {
    const { enqueueSnackbar } = useSnackbar();

    const {
      control,
      register,
      handleSubmit,
      errors,
      setError,
      watch,
      setValue,
      formState: { isSubmitting },
    } = useForm<FormValues>({
      resolver: yupResolver(FormSchema),
      defaultValues: {
        descricao: inscricaoAbertura ? inscricaoAbertura.descricao : '',
      },
    });

    const handleCancel = useCallback(() => {
      onClose(true);
    }, [onClose]);

    const handleSubmitCallBack = useCallback(
      async (values: FormValues) => {
        try {
          await apiRendaFranca.put(
            `/inscricao-abertura/${
              inscricaoAbertura ? inscricaoAbertura.id : 0
            }`,
            values,
          );

          enqueueSnackbar('Inscrição renomeada com sucesso!', {
            variant: 'success',
          });

          onClose(false);
        } catch (err) {
          if (err.response) {
            const { errorDetails } = err.response.data;

            if (errorDetails) {
              addServerErrors<FormValues>(errorDetails, setError);
            }
          }
          enqueueSnackbar('Erro ao renomear inscrição', {
            variant: 'error',
          });
        }
      },
      [enqueueSnackbar, setError, onClose, inscricaoAbertura],
    );

    return (
      <>
        <DevTool control={control} />
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="sm"
          open={open}
        >
          <DialogTitle>Renomear inscrição</DialogTitle>

          <DialogContent dividers>
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(handleSubmitCallBack)}
              id="formPrincipalId"
              style={{ marginTop: 16 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    inputRef={register}
                    name="descricao"
                    label="Descrição"
                    variant="outlined"
                    fullWidth
                    required
                    helperText={errors.descricao?.message}
                    error={!!errors.descricao}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel} color="primary">
              Cancelar
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
              form="formPrincipalId"
            >
              Renomear Inscrição
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

export default DialogRenomearInscricaoAbertura;

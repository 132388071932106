import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Collapse,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  DoneAll as DoneAllIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { green, red } from '@material-ui/core/colors';
import IMask from 'imask';

import DialogCriterioDesempate from './DialogCriterioDesempate';

interface CriteriosList {
  nameCriterio: string;
  titleCriterio: string;
  valueCriterioUsuario: string;
  criterioConfirmado: string | null;
  valueCriterioConfirmado: string | null;
  options: IMask.AnyMaskedOptions;
}

interface PontuacaoDetalheProps {
  criteriosList: CriteriosList[];
  onChange(criteriosList: CriteriosList[]): void;
}

interface EditCriterioDesempate {
  index: number;
  name: string;
  label: string;
  value: string;
  options: IMask.AnyMaskedOptions;
}

const PaperComponent: React.FC = ({ children }) => (
  <Paper variant="outlined">{children}</Paper>
);

const CriteriosDesempate: React.FC<PontuacaoDetalheProps> = ({
  criteriosList: criteriosListExternal,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [criteriosList, setCriteriosList] = useState<CriteriosList[]>([]);

  const [editCriterioDesempate, setEditCriterioDesempate] = useState({
    index: 0,
    name: '',
    label: '',
    value: '',
    options: {
      mask: String,
    },
  } as EditCriterioDesempate);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleCloseDialog = useCallback(
    (value: string | undefined) => {
      if (value) {
        setCriteriosList(previous => {
          const newCriterioList = [...previous];

          newCriterioList[editCriterioDesempate.index].criterioConfirmado =
            'nao';

          newCriterioList[editCriterioDesempate.index].valueCriterioConfirmado =
            value;

          return newCriterioList;
        });
      }

      setOpenDialog(false);

      setEditCriterioDesempate({
        index: 0,
        name: '',
        label: '',
        value: '',
        options: {
          mask: String,
        },
      } as EditCriterioDesempate);

      onChange(criteriosList);
    },
    [editCriterioDesempate.index, criteriosList, onChange],
  );

  const handleConfirmacaoCriterio = useCallback(
    (newConfirmacao: string, index: number) => {
      setCriteriosList(previous => {
        const newCriterioList = [...previous];

        if (newConfirmacao === 'sim') {
          newCriterioList[index].criterioConfirmado = newConfirmacao;

          newCriterioList[index].valueCriterioConfirmado =
            newCriterioList[index].valueCriterioUsuario;
        } else if (newConfirmacao === 'nao') {
          setEditCriterioDesempate({
            index,
            name: newCriterioList[index].nameCriterio,
            label: newCriterioList[index].titleCriterio,
            value: newCriterioList[index].valueCriterioConfirmado || '',
            options: newCriterioList[index].options,
          });

          setOpenDialog(true);
        } else {
          newCriterioList[index].criterioConfirmado = newConfirmacao;

          newCriterioList[index].valueCriterioConfirmado = null;
        }

        return newCriterioList;
      });

      onChange(criteriosList);
    },
    [criteriosList, onChange],
  );

  useEffect(() => {
    if (criteriosListExternal) {
      setCriteriosList([...criteriosListExternal]);
    }
  }, [criteriosListExternal]);

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary="Critérios de Desempate"
          secondary="(Clique para mais detalhes)"
          primaryTypographyProps={{ variant: 'subtitle1' }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box paddingLeft={2}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs sm md lg style={{ minWidth: 499, maxWidth: 820 }}>
              <TableContainer component={PaperComponent}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell align="center">Critério Calculado</TableCell>
                      <TableCell align="center">Critério Confirmado</TableCell>
                      <TableCell align="center">Confirmado?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {criteriosList.map((item, index) => {
                      let colorTableRow;
                      if (item.criterioConfirmado === 'sim') {
                        const { 200: colorGreen } = green;
                        colorTableRow = colorGreen;
                      } else if (item.criterioConfirmado === 'nao') {
                        const { 200: colorGreen } = red;
                        colorTableRow = colorGreen;
                      }

                      return (
                        <TableRow
                          key={item.titleCriterio}
                          hover
                          style={{
                            backgroundColor: colorTableRow,
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {item.titleCriterio}
                          </TableCell>

                          <TableCell align="center">
                            {item.valueCriterioUsuario}
                          </TableCell>
                          <TableCell align="center">
                            {item.valueCriterioConfirmado}
                          </TableCell>
                          <TableCell align="center">
                            <ToggleButtonGroup
                              value={item.criterioConfirmado}
                              exclusive
                              onChange={(event, value) =>
                                handleConfirmacaoCriterio(value, index)
                              }
                            >
                              <ToggleButton size="small" value="sim">
                                <DoneAllIcon htmlColor={green[700]} />
                              </ToggleButton>
                              <ToggleButton size="small" value="nao">
                                <ClearIcon htmlColor={red[700]} />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              item
              xs
              sm
              md
              lg
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div
                hidden
                style={{
                  maxWidth: 600,
                  margin: 16,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <DialogCriterioDesempate
        editCriterioDesempate={editCriterioDesempate}
        open={openDialog}
        onClose={handleCloseDialog}
      />
    </>
  );
};

export default CriteriosDesempate;

import React from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { format, parse } from 'date-fns';
import { Prazo } from '../..';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'flex-start',
    },
    missingOppositeContent: {
      '&::before': {
        display: 'none',
      },
    },
  }),
);

interface CronogramaProps {
  prazos: Prazo[];
}

const Cronograma: React.FC<CronogramaProps> = ({ prazos }) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" style={{ padding: 16 }}>
      <Typography>
        <Box marginBottom={1} fontWeight="fontWeightMedium">
          Cronograma
        </Box>
      </Typography>

      <Timeline align="left">
        {prazos.map((prazo, index) => {
          return (
            <TimelineItem
              key={prazo.id}
              className={classes.missingOppositeContent}
            >
              <TimelineSeparator>
                {prazo.prazoAberto && <TimelineDot color="secondary" />}
                {!prazo.prazoAberto && <TimelineDot />}
                {index !== prazos.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  color={prazo.prazoAberto ? 'initial' : 'textSecondary'}
                >
                  {prazo.descricao}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={prazo.prazoAberto ? 'initial' : 'textSecondary'}
                >
                  {' '}
                  {`${format(
                    parse(
                      prazo.dataHoraInicio,
                      'yyyy-MM-dd HH:mm:ss',
                      new Date(),
                    ),
                    "dd/MM/yyyy HH'h'mm",
                  )} - ${format(
                    parse(prazo.dataHoraFim, 'yyyy-MM-dd HH:mm:ss', new Date()),
                    "dd/MM/yyyy HH'h'mm",
                  )}`}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>

      <Typography variant="caption">
        <sup>*</sup>Sujeito a alterações
      </Typography>
    </Paper>
  );
};

export default Cronograma;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  PaperProps,
  Typography,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import Draggable from 'react-draggable';

const PaperComponent: React.FC = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

interface Ocorrencia {
  id: number;
  descricao: string;
  tipo: string;
  dataHora: string;
  nomeUsuario: string;
}

interface DialogShowRecursoProps {
  open: boolean;
  onClose(): void;
  recurso: Ocorrencia;
}

const DialogShowRecurso: React.FC<DialogShowRecursoProps> = ({
  open,
  onClose,
  recurso,
}) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Recurso ${recurso.id}`}
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0}>
          {recurso.id && (
            <>
              <Box margin={1}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    style={{ minWidth: 80, maxWidth: 80 }}
                  >
                    <Typography variant="subtitle1" component="h5">
                      Tipo:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">{recurso.tipo}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box margin={1}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    style={{ minWidth: 80, maxWidth: 80 }}
                  >
                    <Typography variant="subtitle1" component="h5">
                      Descrição:
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {recurso.descricao.split('\n').map((item, key) => {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box margin={1}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    style={{ minWidth: 80, maxWidth: 80 }}
                  >
                    <Typography variant="subtitle1" component="h5">
                      Usuário:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {recurso.nomeUsuario}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogShowRecurso;

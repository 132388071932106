import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  Backdrop,
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  useTheme,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  useStateMachine,
  Beneficiario,
  BeneficiarioTelefone,
} from 'little-state-machine';
import { useParams } from 'react-router-dom';

import { format, parse } from 'date-fns';
import Recurso from './Recurso';
import { parseJwt } from '../../utils/jwt';
import { apiRendaFranca } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import Pontuacao from './Pontuacao';
import Membros from './Membros';
import Anexos from './Anexos';
import Situacao from './Situacao';
import CaminhoEmprego from './CaminhoEmprego';
import { updateAllSteps } from '../../utils/updateActions';
import AppBarMenu, { Params } from '../../components/AppBarMenu';
import Inscrever from '../Inscrever';
import Criterios from './Criterios';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    flex: {
      flex: 1,
    },
  }),
);

function a11yProps(index: any): any {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

interface Pontuacao {
  resideParceiro: number;
  resideMunicipioMaisTresAnos: number;
  desempregadoSeisMeses: number;
  existeMembroDeficiente: number;
  rendaPerCaptaAteMeioSalarioMinimo: number;
  existeParticipanteServicoAssistencial: number;
  existeMenorMedidaProtetiva: number;
  existeMenorMedidaSocioEducativa: number;
  somaPontuacao: number;
}

interface PontuacaoConfirmada {
  resideParceiroConf: string;
  resideParceiroConfValue: number;

  resideMunicipioMaisTresAnosConf: string;
  resideMunicipioMaisTresAnosConfValue: number;

  desempregadoSeisMesesConf: string;
  desempregadoSeisMesesConfValue: number;

  existeMembroDeficienteConf: string;
  existeMembroDeficienteConfValue: number;

  rendaPerCaptaAteMeioSalarioMinimoConf: string;
  rendaPerCaptaAteMeioSalarioMinimoConfValue: number;

  existeParticipanteServicoAssistencialConf: string;
  existeParticipanteServicoAssistencialConfValue: number;

  existeMenorMedidaProtetivaConf: string;
  existeMenorMedidaProtetivaConfValue: number;

  existeMenorMedidaSocioEducativaConf: string;
  existeMenorMedidaSocioEducativaConfValue: number;

  somaPontuacaoConf: number;
}

interface Criterios {
  quantidadeMenoresDezoitoAnos: number;
  quantidadeMaioresSessentaAnos: number;
  quantidadeDeficientes: number;
  dataNascimento: string;
}

interface CriteriosConfirmados {
  quantidadeMenoresDezoitoAnosConf: string;
  quantidadeMenoresDezoitoAnosConfValue: number;

  quantidadeMaioresSessentaAnosConf: string;
  quantidadeMaioresSessentaAnosConfValue: number;

  quantidadeDeficientesConf: string;
  quantidadeDeficientesConfValue: number;

  dataNascimentoConf: string;
  dataNascimentoConfValue: string;
}

export interface Ocorrencia {
  id: number;
  tipo: string;
  descricao: string;
  dataHora: string;
  nomeUsuario: string;
}

interface Parentesco {
  id: number;
  nome: string;
}

interface ServicoAssistencial {
  id: number;
  nome: string;
}

interface Telefone {
  id: number;
  telefone: string;
  tipo: string;
}

export interface Membro {
  id: string;
  nome: string;
  nomeSocial: string;
  parentesco: Parentesco;
  cpf: string;
  rg: string;
  nis: string;
  dataNascimento: string;
  menorMedidaProtecao: string;
  menorMedidaSocioEducativa: string;
  raca: string;
  possuiDeficiencia: string;
  tipoDeficiencia: string;
  email: string;
  profissao: string;
  renda: string;
  servicoAssistencial: ServicoAssistencial;
  estadoCivil: string;
  sexo: string;
  escolaridade: string;
  endereco: Endereco;
  telefones: Telefone[];
}

interface MembroMetadata {
  rendaTotal: string;
  qtdeMembrosFamiliares: string;
}

interface Anexos {
  nomeArq: string;
  path: string;
  tipo: string;
  lastModified: string;
}

export interface AnexosInfo {
  anexos: Anexos[];
  qtdeAnexos: number;
}

export interface Cupom {
  id: number;
  idCpfCupom: number;
  cupom: string;
}

export interface Endereco {
  cep: string;
  bairro_id: number;
  bairro_nome: string;
  logradouro_id: number;
  logradouro_nome: string;
  zona_id: number;
  zona_nome: string;
  numero: string;
  complemento: string;
}

export interface Prazo {
  id: number;
  nome: string;
  descricao: string;
  dataHoraInicio: string;
  dataHoraFim: string;
  prazoAberto: boolean;
}

export interface InscricaoAbertura {
  idInscricaoAbertura: number;
  descricaoInscricaoAbertura: string;
  prazos: Prazo[];
}

export interface Inscricao {
  id: string;
  inscricaoAbertura: InscricaoAbertura;
  numeroInscricao: number;
  dataHora: string;
  situacao: string;
  descricaoSituacao: string;
  nome: string;
  pontuacao: Pontuacao;
  pontuacaoConfirmada: PontuacaoConfirmada;
  criterios: Criterios;
  criteriosConfirmados: CriteriosConfirmados;
  membros: Membro[];
  membrosMetadata: MembroMetadata;
  anexosInfo: AnexosInfo;
  podeEditarInscricao: boolean;
  prazoEditarInscricao: Prazo;
  podeRecursoInscricao: boolean;
  prazoRecursoInscricao: Prazo;
  recursoInscricao: Ocorrencia;
  respostaRecursoInscricao: Ocorrencia;
  podeBannerAnaliseInscricao: boolean;
  prazoBannerAnalise: Prazo;
  podeGeracaoCupom: boolean;
  prazoGeracaoCupom: Prazo;
  cupomGerado: Cupom;
  endereco: Endereco;
  ocorrenciasRespostaDesclassif: Ocorrencia[];
}

export interface PontuacaoList {
  namePontuacao: string;
  titlePontuacao: string;
  valuePontuacaoUsuario: number;
  pontuacaoConfirmada: string | null;
  valuePontuacaoConfirmada: number | null;
}

export interface CriteriosList {
  nameCriterio: string;
  titleCriterio: string;
  valueCriterioUsuario: string;
  criterioConfirmado: string | null;
  valueCriterioConfirmado: string | null;
}

interface InscricoesProps {
  setAppBarComponent(component: ReactNode): void;
}

const Inscricoes: React.FC<InscricoesProps> = ({ setAppBarComponent }) => {
  const classes = useStyles();
  const params = useParams<Params>();
  const theme = useTheme();
  const [valueTab, setValueTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { signOut, access_token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [inscricaoRealizada, setInscricaoRealizada] = useState(false);
  const { state, actions } = useStateMachine({
    updateAllSteps,
  });

  const [inscricao, setInscricao] = useState<Inscricao>({
    id: '',
    inscricaoAbertura: { prazos: [] as Prazo[] } as InscricaoAbertura,
    numeroInscricao: 0,
    dataHora: '',
    nome: '',
    situacao: '',
    pontuacao: {} as Pontuacao,
    pontuacaoConfirmada: {} as PontuacaoConfirmada,
    membros: [{} as Membro],
  } as Inscricao);
  const [pontuacaoList, setPontuacaoList] = useState<PontuacaoList[]>([]);
  const [criterioList, setCriterioList] = useState<CriteriosList[]>([]);

  useEffect(() => {
    setAppBarComponent(<AppBarMenu />);
  }, [setAppBarComponent, classes.flex]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newValue: number) => {
      setValueTab(newValue);
    },
    [],
  );

  const handleChangeIndex = useCallback((index: number) => {
    setValueTab(index);
  }, []);

  const getPontuacaoList = useCallback(
    (inscricaoApi: Inscricao): PontuacaoList[] => {
      const pontuacaoListInitial = [] as PontuacaoList[];

      pontuacaoListInitial.push({
        namePontuacao: 'resideMunicipioMaisTresAnos',
        titlePontuacao: 'Reside no município há mais de três anos',
        valuePontuacaoUsuario:
          inscricaoApi.pontuacao.resideMunicipioMaisTresAnos,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.resideMunicipioMaisTresAnosConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.resideMunicipioMaisTresAnosConfValue,
      });

      pontuacaoListInitial.push({
        namePontuacao: 'desempregadoSeisMeses',
        titlePontuacao: 'Desempregado(a) há pelo menos seis meses',
        valuePontuacaoUsuario: inscricaoApi.pontuacao.desempregadoSeisMeses,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.desempregadoSeisMesesConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.desempregadoSeisMesesConfValue,
      });

      pontuacaoListInitial.push({
        namePontuacao: 'rendaPerCaptaAteMeioSalarioMinimo',
        titlePontuacao: 'Renda per capta até meio salário mínimo',
        valuePontuacaoUsuario:
          inscricaoApi.pontuacao.rendaPerCaptaAteMeioSalarioMinimo,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada
            .rendaPerCaptaAteMeioSalarioMinimoConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada
            .rendaPerCaptaAteMeioSalarioMinimoConfValue,
      });

      pontuacaoListInitial.push({
        namePontuacao: 'resideParceiro',
        titlePontuacao: 'Família monoparental',
        valuePontuacaoUsuario: inscricaoApi.pontuacao.resideParceiro,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.resideParceiroConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.resideParceiroConfValue,
      });

      pontuacaoListInitial.push({
        namePontuacao: 'existeMembroDeficiente',
        titlePontuacao: 'Algum membro familiar deficiente',
        valuePontuacaoUsuario: inscricaoApi.pontuacao.existeMembroDeficiente,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.existeMembroDeficienteConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.existeMembroDeficienteConfValue,
      });

      pontuacaoListInitial.push({
        namePontuacao: 'existeParticipanteServicoAssistencial',
        titlePontuacao: 'Algum membro participante de serviço assistencial',
        valuePontuacaoUsuario:
          inscricaoApi.pontuacao.existeParticipanteServicoAssistencial,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada
            .existeParticipanteServicoAssistencialConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada
            .existeParticipanteServicoAssistencialConfValue,
      });

      pontuacaoListInitial.push({
        namePontuacao: 'existeMenorMedidaProtetiva',
        titlePontuacao: 'Existe criança com medida protetiva',
        valuePontuacaoUsuario:
          inscricaoApi.pontuacao.existeMenorMedidaProtetiva,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.existeMenorMedidaProtetivaConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.existeMenorMedidaProtetivaConfValue,
      });

      pontuacaoListInitial.push({
        namePontuacao: 'existeMenorMedidaSocioEducativa',
        titlePontuacao:
          'Existe criança ou adolescente com medida socioeducativa',
        valuePontuacaoUsuario:
          inscricaoApi.pontuacao.existeMenorMedidaSocioEducativa,
        pontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada.existeMenorMedidaSocioEducativaConf,
        valuePontuacaoConfirmada:
          inscricaoApi.pontuacaoConfirmada
            .existeMenorMedidaSocioEducativaConfValue,
      });

      return pontuacaoListInitial;
    },
    [],
  );

  const getCriterioList = useCallback(
    (inscricaoApi: Inscricao): CriteriosList[] => {
      const criterioListInicial = [] as CriteriosList[];

      criterioListInicial.push({
        nameCriterio: 'quantidadeMenoresDezoitoAnos',
        titleCriterio: 'Quantidade menores de dezoito anos',
        valueCriterioUsuario:
          inscricaoApi.criterios.quantidadeMenoresDezoitoAnos.toString(),
        criterioConfirmado:
          inscricaoApi.criteriosConfirmados.quantidadeMenoresDezoitoAnosConf,
        valueCriterioConfirmado:
          inscricaoApi.criteriosConfirmados
            .quantidadeMenoresDezoitoAnosConfValue !== null
            ? inscricaoApi.criteriosConfirmados.quantidadeMenoresDezoitoAnosConfValue.toString()
            : '',
      });

      criterioListInicial.push({
        nameCriterio: 'quantidadeMaioresSessentaAnos',
        titleCriterio: 'Quantidade maiores ou iguais a sessenta anos',
        valueCriterioUsuario:
          inscricaoApi.criterios.quantidadeMaioresSessentaAnos.toString(),
        criterioConfirmado:
          inscricaoApi.criteriosConfirmados.quantidadeMaioresSessentaAnosConf,
        valueCriterioConfirmado:
          inscricaoApi.criteriosConfirmados
            .quantidadeMaioresSessentaAnosConfValue !== null
            ? inscricaoApi.criteriosConfirmados.quantidadeMaioresSessentaAnosConfValue.toString()
            : '',
      });

      criterioListInicial.push({
        nameCriterio: 'quantidadeDeficientes',
        titleCriterio: 'Quantidade pessoas com deficiência',
        valueCriterioUsuario:
          inscricaoApi.criterios.quantidadeDeficientes.toString(),
        criterioConfirmado:
          inscricaoApi.criteriosConfirmados.quantidadeDeficientesConf,
        valueCriterioConfirmado:
          inscricaoApi.criteriosConfirmados.quantidadeDeficientesConfValue !==
          null
            ? inscricaoApi.criteriosConfirmados.quantidadeDeficientesConfValue.toString()
            : '',
      });

      criterioListInicial.push({
        nameCriterio: 'dataNascimento',
        titleCriterio: 'Data de nascimento membro principal',
        valueCriterioUsuario: format(
          parse(
            inscricaoApi.criterios.dataNascimento,
            'yyyy-MM-dd',
            new Date(),
          ),
          'dd/MM/yyyy',
        ),
        criterioConfirmado:
          inscricaoApi.criteriosConfirmados.dataNascimentoConf,
        valueCriterioConfirmado:
          inscricaoApi.criteriosConfirmados.dataNascimentoConfValue !== null
            ? format(
                parse(
                  inscricaoApi.criteriosConfirmados.dataNascimentoConfValue,
                  'yyyy-MM-dd',
                  new Date(),
                ),
                'dd/MM/yyyy',
              )
            : '',
      });

      return criterioListInicial;
    },
    [],
  );

  const handleEditarInscricao = useCallback(() => {
    actions.updateAllSteps({
      idInscricao: inscricao.id,
      beneficiarioList: inscricao.membros.map<Beneficiario>(value => {
        return {
          idBeneficiario: value.id,
          nome: value.nome,
          nomeSocial: value.nomeSocial,
          parentescoMembroPrincipal:
            value.parentesco.id === 0 ? '' : value.parentesco.id,
          cpf: value.cpf,
          endereco: {
            cep: value.endereco.cep,
            bairro_id: value.endereco.bairro_id,
            bairro_nome: value.endereco.bairro_nome,
            logradouro_id: value.endereco.logradouro_id,
            logradouro_nome: value.endereco.logradouro_nome,
            zona_id: value.endereco.zona_id,
            zona_nome: value.endereco.zona_nome,
          },
          rg: value.rg,
          nis: value.nis,
          dataNascimento: value.dataNascimento,
          raca: value.raca,
          telefones: value.telefones.map<BeneficiarioTelefone>(
            valueTelefone => {
              return {
                idTelefone: valueTelefone.id,
                telefone: valueTelefone.telefone,
                tipoTelefone: valueTelefone.tipo,
              };
            },
          ),
          deleteTelefones: [],
          possuiDeficiencia: value.possuiDeficiencia,
          deficienciaTipo: value.tipoDeficiencia,
          email: value.email,
          profissao: value.profissao,
          renda: value.renda,
          servicoAssistencial:
            value.servicoAssistencial.id === 0
              ? ''
              : value.servicoAssistencial.id,
          estadoCivil: value.estadoCivil,
          sexo: value.sexo,
          escolaridade: value.escolaridade,
          menorMedidaProtecao: value.menorMedidaProtecao,
          menorMedidaSocioEducativa: value.menorMedidaSocioEducativa,
          numero: value.endereco.numero,
          complemento: value.endereco.complemento,
        };
      }),
      deleteBeneficiarios: [],
      observacao: '',
      resideMunicipioMaisTresAnos:
        inscricao.pontuacao.resideMunicipioMaisTresAnos === 1 ? 'SIM' : 'NAO',
      desempregadoPeloMenosSeisMeses:
        inscricao.pontuacao.desempregadoSeisMeses === 1 ? 'SIM' : 'NAO',
      rendaTotal: inscricao.membrosMetadata.rendaTotal,
      qtdeMembrosFamiliares: inscricao.membrosMetadata.qtdeMembrosFamiliares,
      anexosInfo: inscricao.anexosInfo,
      deleteAnexos: [],
    });
  }, [actions, inscricao]);

  const handleRefreshData = useCallback(async () => {
    try {
      setLoading(true);

      const resultParse = parseJwt(access_token);

      const { idUsuario } = resultParse.usuario || { idUsuario: '0' };

      const response = await apiRendaFranca.get(
        `/inscrever/${params.id}/usuario/${idUsuario}`,
      );

      setInscricao(response.data.payload);
      setPontuacaoList(getPontuacaoList(response.data.payload));
      setCriterioList(getCriterioList(response.data.payload));
    } catch (err) {
      if (err.response && err.response.status === 401) {
        enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
        signOut();
        return;
      }
      if (err.response && err.response.status === 404) {
        setInscricao({
          id: '',
          inscricaoAbertura: { prazos: [] as Prazo[] } as InscricaoAbertura,
          numeroInscricao: 0,
          dataHora: '',
          nome: '',
          situacao: '',
          pontuacao: {} as Pontuacao,
          pontuacaoConfirmada: {} as PontuacaoConfirmada,
          membros: [{} as Membro],
        } as Inscricao);
        return;
      }
      enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [
    access_token,
    enqueueSnackbar,
    signOut,
    getPontuacaoList,
    getCriterioList,
    params.id,
  ]);

  useEffect(() => {
    (async () => {
      await handleRefreshData();
    })();
  }, [handleRefreshData]);

  const handleRefreshScreen = useCallback(async () => {
    await handleRefreshData();
  }, [handleRefreshData]);

  useEffect(() => {
    if (inscricao.id) {
      setInscricaoRealizada(true);
    } else {
      setInscricaoRealizada(false);
    }
  }, [inscricao.id]);

  return (
    <Paper className={classes.root} variant="outlined">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!inscricaoRealizada && (
        <Inscrever setAppBarComponent={setAppBarComponent} />
      )}

      {inscricaoRealizada && (
        <>
          <Tabs
            value={valueTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            aria-label="full width tabs example"
          >
            <Tab label="Situação" {...a11yProps(0)} />
            <Tab label="Caminho Emprego" {...a11yProps(1)} />
            <Tab label="Recurso" {...a11yProps(2)} />
            <Tab label="Pontuação" {...a11yProps(3)} />
            <Tab label="Criterios Desempate" {...a11yProps(4)} />
            <Tab label="Membros" {...a11yProps(5)} />
            <Tab label="Anexos" {...a11yProps(6)} />
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={valueTab}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={valueTab} index={0} dir={theme.direction}>
              <Situacao
                idInscricao={inscricao.id}
                podeBannerAnaliseInscricao={
                  inscricao.podeBannerAnaliseInscricao
                }
                prazoBannerAnalise={inscricao.prazoBannerAnalise}
                podeEditarInscricao={inscricao.podeEditarInscricao}
                prazoEditarInscricao={inscricao.prazoEditarInscricao}
                podeRecursoInscricao={inscricao.podeRecursoInscricao}
                prazoRecursoInscricao={inscricao.prazoRecursoInscricao}
                podeGeracaoCupom={inscricao.podeGeracaoCupom}
                prazoGeracaoCupom={inscricao.prazoGeracaoCupom}
                inscricaoAbertura={inscricao.inscricaoAbertura}
                numeroInscricao={inscricao.numeroInscricao}
                dataHora={inscricao.dataHora}
                situacao={inscricao.situacao}
                descricaoSituacao={inscricao.descricaoSituacao}
                ocorrenciasRespostaDesclassif={
                  inscricao.ocorrenciasRespostaDesclassif
                }
                handleRefreshScreen={handleRefreshScreen}
                handleEditarInscricao={handleEditarInscricao}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={1} dir={theme.direction}>
              <CaminhoEmprego
                cupomGerado={inscricao.cupomGerado}
                endereco={inscricao.endereco}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={2} dir={theme.direction}>
              <Recurso
                recursoInscricao={inscricao.recursoInscricao}
                respostaRecursoInscricao={inscricao.respostaRecursoInscricao}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={3} dir={theme.direction}>
              <Pontuacao
                podeBannerAnaliseInscricao={
                  inscricao.podeBannerAnaliseInscricao
                }
                prazoBannerAnalise={inscricao.prazoBannerAnalise}
                pontuacaoList={pontuacaoList}
                somaPontuacaoOriginal={inscricao.pontuacao.somaPontuacao}
                somaPontuacaoConfirmada={
                  inscricao.pontuacaoConfirmada.somaPontuacaoConf
                }
              />
            </TabPanel>
            <TabPanel value={valueTab} index={4} dir={theme.direction}>
              <Criterios
                podeBannerAnaliseInscricao={
                  inscricao.podeBannerAnaliseInscricao
                }
                prazoBannerAnalise={inscricao.prazoBannerAnalise}
                criteriosList={criterioList}
              />
            </TabPanel>
            <TabPanel value={valueTab} index={5} dir={theme.direction}>
              <Membros membros={inscricao.membros} />
            </TabPanel>
            <TabPanel value={valueTab} index={6} dir={theme.direction}>
              <Anexos anexosInfo={inscricao.anexosInfo} />
            </TabPanel>
          </SwipeableViews>
        </>
      )}
    </Paper>
  );
};

export default Inscricoes;

import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AnexosInfo, useStateMachine } from 'little-state-machine';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { createIntl, createIntlCache } from 'react-intl';
import { clearState } from '../../../../utils/updateActions';
import SendFormDataAndFiles from './SendFormDataAndFiles';
import AnexosList from './AnexosList';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'pt-BR',
    messages: {},
  },
  cache,
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
    previewChip: {
      minWidth: 160,
      maxWidth: 230,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    paper: {
      width: '80%',
      maxHeight: 435,
    },
  }),
);

const StepFourSchema = Yup.object().shape({
  // endereco: Yup.object()
  //   .typeError('Campo inválido')
  //   .shape({
  //     cep: Yup.string().required('Campo obrigatório'),
  //   }),
});

const StepFour: React.FC<any> = ({ handleBack }) => {
  const classes = useStyles();
  const { handleSubmit, control, errors } = useForm<any>({
    resolver: yupResolver(StepFourSchema),
  });
  const { state, actions } = useStateMachine({
    clearState,
  });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const history = useHistory();

  const [openSendDataDialog, setOpenSendDataDialog] = useState(false);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<File[]>([]);
  const [maxFilesString, setMaxFilesString] = useState('0 MB');

  const [anexosJaEnviados, setAnexosJaEnviados] = useState<AnexosInfo>(
    state.anexosInfo,
  );

  useEffect(() => {
    let sizeInBytes = 0;
    files.forEach(file => {
      sizeInBytes += file.size;
    });
    const sizeInMB = sizeInBytes / (1024 * 1024);

    const sizeInMBString = intl.formatNumber(sizeInMB, {
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setMaxFilesString(`${sizeInMBString} MB`);
  }, [files]);

  const handleClickCancelDialog = useCallback(() => {
    setOpenCancelDialog(true);
  }, []);

  const handleClickConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);

  const handleCancelClose = useCallback(
    (aceitou: boolean) => {
      if (aceitou) {
        actions.clearState(undefined);
        history.push('/home');
      }
      setOpenCancelDialog(false);
    },
    [history, actions],
  );

  const handleCloseSendDataDialog = useCallback(
    (success: boolean) => {
      setOpenSendDataDialog(false);
      if (success) {
        actions.clearState(undefined);
        history.push('/home');
      }
    },
    [actions, history],
  );

  const handleConfirmClose = useCallback((aceitou: boolean) => {
    if (aceitou) {
      setOpenSendDataDialog(true);
    }
    setOpenConfirmDialog(false);
  }, []);

  const handleChangeAnexosJaEnviados = useCallback(() => {
    setAnexosJaEnviados({ ...state.anexosInfo });
  }, [state.anexosInfo]);

  return (
    <Grid container alignContent="center" justify="center">
      <Grid item xs={12} sm={9} md={7} lg={5}>
        <Box maxWidth={530} marginX="auto">
          <Card elevation={12}>
            <CardContent>
              <Grid container alignItems="center" justify="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <Box fontWeight="fontWeightMedium">
                      <p>
                        Leia as instruções sobre os anexos clicando{' '}
                        <a
                          href="https://www.franca.sp.gov.br/acao-social/orientacoes-programa-renda-franca"
                          target="_blank"
                          rel="noreferrer"
                        >
                          aqui
                        </a>
                      </p>
                    </Box>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Paper variant="outlined" style={{ padding: 6 }}>
                    <Typography variant="h6">Anexos já enviados:</Typography>
                    <AnexosList
                      anexosInfo={anexosJaEnviados}
                      onChange={handleChangeAnexosJaEnviados}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper variant="outlined" style={{ padding: 6 }}>
                    <Typography variant="h6">
                      Requisitos para o envio dos arquivos:
                    </Typography>
                    <Typography variant="body2">
                      Tipos de arquivos permitidos: .jpg, .jpeg, .png e .pdf
                    </Typography>
                    <Typography variant="body2">
                      Quantidade máxima de arquivos: 50
                    </Typography>
                    <Typography variant="body2">
                      Tamanho máximo de cada arquivo: 5 MB
                    </Typography>
                    <Typography variant="body2">
                      Tamanho máximo de todos os arquivos: 100 MB
                    </Typography>
                    <DropzoneArea
                      filesLimit={50}
                      acceptedFiles={[
                        'image/jpeg',
                        'image/png',
                        'application/pdf',
                      ]}
                      maxFileSize={5242880}
                      useChipsForPreview
                      previewGridProps={{
                        container: { spacing: 1, direction: 'row' },
                      }}
                      previewChipProps={{
                        classes: { root: classes.previewChip },
                      }}
                      showPreviews
                      showPreviewsInDropzone={false}
                      previewText="Lista dos arquivos inseridos:"
                      getFileLimitExceedMessage={filesLimit => {
                        return `Máximo número de arquivos atingido. São permitidos até ${filesLimit} arquivos.`;
                      }}
                      showAlerts={['error', 'info']}
                      onDrop={e => {
                        e.forEach(item =>
                          enqueueSnackbar(`Arquivo ${item.name} adicionado`, {
                            variant: 'success',
                          }),
                        );
                      }}
                      getFileRemovedMessage={fileName => {
                        return `Arquivo ${fileName} removido.`;
                      }}
                      getDropRejectMessage={rejectedFile => {
                        return `Arquivo ${rejectedFile.name} foi rejeitado.`;
                      }}
                      dropzoneText="Clique ou arraste seus arquivos aqui."
                      onChange={filesDropZoneArea => {
                        setFiles(filesDropZoneArea);
                      }}
                    />
                    <Box marginTop={2}>
                      <Typography variant="subtitle2" align="left">
                        Quantidade de arquivos inseridos: {files.length}
                      </Typography>
                      <Typography variant="subtitle2" align="left">
                        Tamanho total dos arquivos inseridos: {maxFilesString}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Box marginTop={1}>
                <Grid
                  container
                  spacing={1}
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <Button
                      onClick={handleClickCancelDialog}
                      variant="contained"
                      color="secondary"
                      size={matches ? 'small' : 'medium'}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={handleBack}
                      className={classes.button}
                      size={matches ? 'small' : 'medium'}
                    >
                      Anterior
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        files.length === 0 && state.idInscricao.length === 0
                      }
                      className={classes.button}
                      onClick={handleClickConfirmDialog}
                      size={matches ? 'small' : 'medium'}
                    >
                      Concluir
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Dialog
        open={openCancelDialog}
        onClose={() => handleCancelClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancelar inscrição?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Os dados não salvos serão excluídos
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelClose(false)} color="primary">
            Não
          </Button>
          <Button
            onClick={() => handleCancelClose(true)}
            color="primary"
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={() => handleConfirmClose(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          As informações estão corretas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Declaro que são verdadeiras e me responsabilizo pelas informações
            prestadas nesta inscrição.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleConfirmClose(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleConfirmClose(true)}
            color="primary"
            autoFocus
          >
            De acordo
          </Button>
        </DialogActions>
      </Dialog>
      <SendFormDataAndFiles
        classes={{
          paper: classes.paper,
        }}
        id="ringtone-menu"
        open={openSendDataDialog}
        onClose={handleCloseSendDataDialog}
        files={files}
        formDataValues={state}
      />
    </Grid>
  );
};

export default StepFour;

import React from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { Error as ErrorIcon, Done as DoneIcon } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { red } from '@material-ui/core/colors';
import { Background, BackgroundOverlay, PasswordResetErrorCard } from './style';

interface State {
  title: string;
  body: string;
}

const PasswordResetError: React.FC = () => {
  const history = useHistory();

  const location = useLocation();
  const { title, body } = (location.state as State) || { title: '', body: '' };

  const doubleQuote = '"';

  return (
    <Background container>
      <BackgroundOverlay container alignContent="center" justify="center">
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <PasswordResetErrorCard style={{ maxWidth: 400 }} elevation={12}>
              <CardContent style={{ paddingBottom: 0 }}>
                <Box
                  marginBottom={1}
                  display="flex"
                  alignContent="center"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="h5" component="h2">
                    {title}
                  </Typography>

                  <ErrorIcon
                    fontSize="large"
                    style={{ height: '100%', color: red[500] }}
                  />
                </Box>
                <Typography variant="body1" component="h2">
                  {body} Solicite uma nova redefinição de senha na opção{' '}
                  {doubleQuote}
                  Esqueci minha senha{doubleQuote} na página inicial do sistema.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DoneIcon />}
                  onClick={() => history.replace('/', {})}
                >
                  OK
                </Button>
              </CardActions>
            </PasswordResetErrorCard>
          </Grid>
        </Grid>
      </BackgroundOverlay>
    </Background>
  );
};

export default PasswordResetError;

import React, { Fragment } from 'react';

import { Box, Divider, Paper, Link, List } from '@material-ui/core';

interface Anexo {
  nomeArq: string;
  path: string;
}

interface AnexosListProps {
  anexos: Anexo[];
}

const AnexosList: React.FC<AnexosListProps> = ({ anexos }) => {
  return (
    <List style={{ width: '100%' }}>
      {anexos &&
        anexos.map(value => {
          return (
            <Fragment key={value.nomeArq}>
              <Box paddingLeft={2}>
                <Paper elevation={0}>
                  <Box margin={1}>
                    <Link href={value.path} target="_blank" variant="body2">
                      {value.nomeArq}
                    </Link>
                  </Box>
                </Paper>
              </Box>
              <Divider />
            </Fragment>
          );
        })}
    </List>
  );
};

export default AnexosList;

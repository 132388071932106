import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import IMask from 'imask';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import MaskedInput from '../../../components/MaskedInput';

interface EditCriterioDesempate {
  index: number;
  name: string;
  label: string;
  value: string;
  options: IMask.AnyMaskedOptions;
}

interface DialogCriterioDesempateProps {
  editCriterioDesempate: EditCriterioDesempate;
  open: boolean;
  onClose: (value?: string) => void;
}

interface FormDataValues {
  criterio: string;
}

const FormSchema = Yup.object().shape({
  criterio: Yup.string()
    .required('Campo obrigatório')
    .when(
      '$criterioAtual',
      (criterioAtual: string, schema: Yup.StringSchema): Yup.StringSchema => {
        return criterioAtual && criterioAtual.length > 0
          ? schema.notOneOf(
              [criterioAtual],
              `Valor deve ser diferente de ${criterioAtual}`,
            )
          : schema;
      },
    ),
});

const DialogCriterioDesempate: React.FC<DialogCriterioDesempateProps> = ({
  editCriterioDesempate,
  open,
  onClose,
}) => {
  const {
    control,
    handleSubmit,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(FormSchema),
    context: { criterioAtual: editCriterioDesempate.value },
    defaultValues: {
      criterio: '',
    },
  });

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmitCallBack = useCallback(
    (values: FormDataValues) => {
      onClose(values.criterio);
    },
    [onClose],
  );

  const handleOnEntered = useCallback(() => {
    reset({ criterio: editCriterioDesempate.value });
  }, [reset, editCriterioDesempate.value]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onEntered={handleOnEntered}
    >
      <DialogTitle id="confirmation-dialog-title">
        Critério Confirmado
      </DialogTitle>

      <form noValidate autoComplete="off">
        <DialogContent dividers>
          <Box minWidth={420} minHeight={75}>
            <MaskedInput
              options={editCriterioDesempate.options}
              control={control}
              name="criterio"
              label={editCriterioDesempate.label}
              variant="outlined"
              defaultValue={editCriterioDesempate.value}
              fullWidth
              required
              helperText={errors.criterio?.message}
              error={!!errors.criterio}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit(handleSubmitCallBack)}
            color="primary"
            disabled={isSubmitting}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCriterioDesempate;

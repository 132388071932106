import styled from 'styled-components';
import { Card, Grid } from '@material-ui/core';

import signInBackgroundImg from '../../assets/francaFundoAumentar.jpg';

export const Background = styled(Grid)`
  min-height: 100vh;
  background: url(${signInBackgroundImg}) no-repeat;
  background-size: cover;
`;

export const BackgroundOverlay = styled(Grid)`
  background-color: rgba(0, 81, 162, 0.75);
  min-height: 100vh;
`;

export const LoginCard = styled(Card)`
  text-align: center;
  margin: 8px;
  padding: 0 10px;
  min-width: 310px;
`;

export const Logo = styled.img`
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const LogosIndex = styled.img`
  padding-top: 30px;
  padding-bottom: 30px;
`;

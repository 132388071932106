import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { NovaOcorrencia } from './DialogEdicao';
import DialogShowRecurso from './DialogShowRecurso';

interface Ocorrencia {
  id: number;
  descricao: string;
  tipo: string;
  dataHora: string;
  nomeUsuario: string;
}

interface DialogOcorrenciaProps {
  idInscricao: string;
  open: boolean;
  onClose: (cancel: boolean, novaOcorrencia: NovaOcorrencia) => void;
  title: string;
  recurso: Ocorrencia | null;
}

interface FormValues {
  ocorrencia: string;
  respostaDesclassif: boolean;
}

const FormSchema = Yup.object().shape({
  ocorrencia: Yup.string()
    .required('Campo obrigatório')
    .min(10, 'Mínimo de 10 caracteres'),
});

const DialogOcorrencia: React.FC<DialogOcorrenciaProps> = ({
  onClose,
  open,
  idInscricao,
  title,
  recurso,
}) => {
  const [openDialogRecurso, setOpenDialogRecurso] = useState(false);

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
    control,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      ocorrencia: '',
      respostaDesclassif: false,
    },
  });

  const handleCancel = useCallback(() => {
    onClose(true, {} as NovaOcorrencia);
  }, [onClose]);

  const handleSubmitCallBack = useCallback(
    (values: FormValues) => {
      onClose(false, {
        idInscricao,
        idTempOcorrencia: uuidv4(),
        ocorrencia: values.ocorrencia,
        idRespostaRecurso: recurso && recurso.id,
        respostaDesclassif: values.respostaDesclassif,
      });
    },
    [onClose, idInscricao, recurso],
  );

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          {recurso && (
            <Box marginBottom={1}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<VisibilityIcon />}
                onClick={() => setOpenDialogRecurso(true)}
              >
                {`Ver Recurso ${recurso.id}`}
              </Button>
            </Box>
          )}

          <form noValidate autoComplete="off">
            <TextField
              inputRef={register}
              name="ocorrencia"
              variant="outlined"
              fullWidth
              required
              helperText={errors.ocorrencia?.message}
              error={!!errors.ocorrencia}
              multiline
              rows={15}
            />

            <Controller
              name="respostaDesclassif"
              control={control}
              render={({ name, ref, value }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputRef={ref}
                        name={name}
                        checked={value}
                        onChange={(event, checked) =>
                          setValue('respostaDesclassif', checked)
                        }
                        color="primary"
                      />
                    }
                    label="É uma ocorrência de resposta ao motivo de desclassificação"
                  />
                );
              }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit(handleSubmitCallBack)}
            color="primary"
            disabled={isSubmitting}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <DialogShowRecurso
        open={openDialogRecurso}
        onClose={() => setOpenDialogRecurso(false)}
        recurso={recurso || ({} as Ocorrencia)}
      />
    </>
  );
};

export default DialogOcorrencia;

import {
  Box,
  Collapse,
  Divider,
  Fab,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import {
  ExpandLess,
  ExpandMore,
  Reply as ReplyIcon,
  QuestionAnswer as QuestionAnswerIcon,
} from '@material-ui/icons';
import { format, parse } from 'date-fns';

import React, { Fragment, useCallback, useState } from 'react';

interface Ocorrencia {
  id: number;
  descricao: string;
  tipo: string;
  dataHora: string;
  nomeUsuario: string;
  idRespostaRecurso: number | null;
}

interface CustomListItemProps {
  ocorrencia: Ocorrencia;
  onClickRecurso(recurso: Ocorrencia): void;
}

const OcorrenciaList: React.FC<CustomListItemProps> = ({
  ocorrencia,
  onClickRecurso,
}) => {
  const [open, setOpen] = useState(true);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Fragment key={ocorrencia.id}>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={
            ocorrencia.dataHora &&
            format(
              parse(ocorrencia.dataHora, 'yyyy-MM-dd HH:mm:ss', new Date()),
              "dd/MM/yyyy 'às' HH:mm:ss",
            )
          }
          secondary="(Clique para mais detalhes)"
          primaryTypographyProps={{ variant: 'subtitle1' }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box paddingLeft={2}>
          <Paper elevation={0} style={{ position: 'relative' }}>
            {ocorrencia.tipo === 'Recurso Efetuado' && (
              <Tooltip title="Recurso Efetuado">
                <Fab
                  size="small"
                  style={{
                    position: 'absolute',
                    left: '100%',
                    marginLeft: -62,
                    top: 4,
                    backgroundColor: '#F49C44',
                  }}
                  onClick={() => {
                    onClickRecurso(ocorrencia);
                  }}
                >
                  <ReplyIcon />
                </Fab>
              </Tooltip>
            )}
            {ocorrencia.tipo === 'Resposta Recurso' && (
              <Tooltip title="Resposta Recurso">
                <Fab
                  size="small"
                  style={{
                    position: 'absolute',
                    left: '100%',
                    marginLeft: -62,
                    top: 4,
                    backgroundColor: '#F49C44',
                  }}
                >
                  <QuestionAnswerIcon />
                </Fab>
              </Tooltip>
            )}
            {ocorrencia.tipo === 'Resposta Desclassificação' && (
              <Tooltip title="Resposta Desclassificação">
                <Fab
                  size="small"
                  style={{
                    position: 'absolute',
                    left: '100%',
                    marginLeft: -62,
                    top: 4,
                    backgroundColor: red[500],
                  }}
                >
                  <QuestionAnswerIcon />
                </Fab>
              </Tooltip>
            )}

            <Box margin={1}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{ minWidth: 80, maxWidth: 80 }}
                >
                  <Typography variant="subtitle1" component="h5">
                    Número:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">{ocorrencia.id}</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box margin={1}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{ minWidth: 80, maxWidth: 80 }}
                >
                  <Typography variant="subtitle1" component="h5">
                    Tipo:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">{ocorrencia.tipo}</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {ocorrencia.idRespostaRecurso && (
              <Box margin={1}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    style={{ minWidth: 160, maxWidth: 160 }}
                  >
                    <Typography variant="subtitle1" component="h5">
                      Resposta ao recurso:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {ocorrencia.idRespostaRecurso}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box margin={1}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{ minWidth: 80, maxWidth: 80 }}
                >
                  <Typography variant="subtitle1" component="h5">
                    Descrição:
                  </Typography>
                </Grid>

                <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">
                      {ocorrencia.descricao.split('\n').map((item, key) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box margin={1}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{ minWidth: 80, maxWidth: 80 }}
                >
                  <Typography variant="subtitle1" component="h5">
                    Usuário:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">
                      {ocorrencia.nomeUsuario}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Collapse>
      <Divider />
    </Fragment>
  );
};

export default OcorrenciaList;

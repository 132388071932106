import { GlobalState } from 'little-state-machine';

export function updateStepOne(
  state: GlobalState,
  payload: {
    resideMunicipioMaisTresAnos: string;
    desempregadoPeloMenosSeisMeses: string;
    rendaTotal: any;
    qtdeMembrosFamiliares: any;
  },
): GlobalState {
  return {
    ...state,
    ...payload,
  };
}

export function updateDataNascimentoStepOne(
  state: GlobalState,
  dataNascimento: any,
): GlobalState {
  state.beneficiarioList[0].dataNascimento = dataNascimento;
  return state;
}

interface BeneficiarioTelefone {
  idTelefone: number;
  telefone: string;
  tipoTelefone: string;
}

interface Endereco {
  cep: string;
  bairro_id: number;
  bairro_nome: string;
  logradouro_id: number;
  logradouro_nome: string;
  zona_id: number;
  zona_nome: string;
}

interface Anexo {
  nomeArq: string;
  path: string;
  tipo: string;
  lastModified: string;
}

interface AnexosInfo {
  anexos: Anexo[];
  qtdeAnexos: number;
}

export function updateStepTwo(
  state: GlobalState,
  payload: {
    idBeneficiario: string;
    nome: string;
    nomeSocial: string;
    cpf: string;
    endereco: Endereco;
    rg: string;
    nis: string;
    raca: string;
    dataNascimento: any;
    telefones: BeneficiarioTelefone[];
    deleteTelefones: BeneficiarioTelefone[];
    possuiDeficiencia: string;
    deficienciaTipo: string;
    email: string;
    profissao: string;
    renda: string;
    servicoAssistencial: string | number;
    sexo: string;
    escolaridade: string;
    menorMedidaProtecao: string;
    menorMedidaSocioEducativa: string;
    numero: string;
    complemento: string;
  },
): GlobalState {
  state.beneficiarioList[0] = payload;
  return state;
}

export function updateStepTwoRemoveTelefone(
  state: GlobalState,
  payload: BeneficiarioTelefone,
): GlobalState {
  state.beneficiarioList[0].deleteTelefones.push(payload);
  return state;
}

export function updateStepThreeRemoveTelefone(
  state: GlobalState,
  payload: { index: number; telefone: BeneficiarioTelefone },
): GlobalState {
  state.beneficiarioList[payload.index].deleteTelefones.push(payload.telefone);
  return state;
}

export function updateStepThreeRemoveBeneficiario(
  state: GlobalState,
  payload: {
    idBeneficiario: string;
    nome: string;
    cpf: string;
  },
): GlobalState {
  state.deleteBeneficiarios.push(payload);
  return state;
}

export function updateStepThree(
  state: GlobalState,
  payload: {
    index: number;
    beneficiario: {
      idBeneficiario: string;
      nome: string;
      nomeSocial: string;
      parentescoMembroPrincipal: string | number;
      cpf: string;
      rg: string;
      nis: string;
      dataNascimento: any;
      raca: string;
      telefones: BeneficiarioTelefone[];
      deleteTelefones: BeneficiarioTelefone[];
      possuiDeficiencia: string;
      deficienciaTipo: string;
      email: string;
      profissao: string;
      renda: string;
      servicoAssistencial: string | number;
      sexo: string;
      escolaridade: string;
      menorMedidaProtecao: string;
      menorMedidaSocioEducativa: string;
      numero: string;
      complemento: string;
    };
  },
): GlobalState {
  state.beneficiarioList[payload.index] = payload.beneficiario;
  return state;
}

export function updateStepFourRemoveAnexo(
  state: GlobalState,
  payload: Anexo,
): GlobalState {
  state.deleteAnexos.push(payload);
  return state;
}

export function deleteAnexoAtIndex(
  state: GlobalState,
  index: number,
): GlobalState {
  state.anexosInfo.anexos.splice(index, 1);
  state.anexosInfo.qtdeAnexos -= 1;
  return state;
}

export function updateAllSteps(
  state: GlobalState,
  payload: {
    idInscricao: string;
    beneficiarioList: {
      idBeneficiario: string;
      nome: string;
      nomeSocial: string;
      parentescoMembroPrincipal?: string | number;
      cpf: string;
      endereco?: Endereco;
      rg: string;
      nis: string;
      dataNascimento: string;
      raca: string;
      telefones: BeneficiarioTelefone[];
      deleteTelefones: BeneficiarioTelefone[];
      possuiDeficiencia: string;
      deficienciaTipo: string;
      email: string;
      profissao: string;
      renda: string;
      servicoAssistencial: string | number;
      estadoCivil?: string;
      sexo: string;
      escolaridade: string;
      menorMedidaProtecao: string;
      menorMedidaSocioEducativa: string;
      numero: string;
      complemento: string;
    }[];
    deleteBeneficiarios: {
      idBeneficiario: string;
      nome: string;
      cpf: string;
    }[];
    observacao: string;
    resideMunicipioMaisTresAnos: string;
    desempregadoPeloMenosSeisMeses: string;
    rendaTotal: any;
    qtdeMembrosFamiliares: any;
    anexosInfo: AnexosInfo;
    deleteAnexos: Anexo[];
  },
): GlobalState {
  // eslint-disable-next-line no-param-reassign
  state = payload;
  return state;
}

export function deleteMemberAtIndex(
  state: GlobalState,
  index: number,
): GlobalState {
  state.beneficiarioList.splice(index, 1);
  return state;
}

export function clearState(): GlobalState {
  return {
    idInscricao: '',
    beneficiarioList: [
      {
        idBeneficiario: '',
        nome: '',
        nomeSocial: '',
        parentescoMembroPrincipal: '',
        cpf: '',
        endereco: {
          cep: '',
          bairro_id: 0,
          bairro_nome: '',
          logradouro_id: 0,
          logradouro_nome: '',
          zona_id: 0,
          zona_nome: '',
        },
        rg: '',
        nis: '',
        dataNascimento: '',
        raca: '',
        telefones: [
          {
            idTelefone: 0,
            telefone: '',
            tipoTelefone: '',
          },
        ],
        deleteTelefones: [],
        possuiDeficiencia: '',
        deficienciaTipo: '',
        email: '',
        profissao: '',
        renda: '',
        servicoAssistencial: '',
        estadoCivil: '',
        sexo: '',
        escolaridade: '',
        menorMedidaProtecao: '',
        menorMedidaSocioEducativa: '',
        numero: '',
        complemento: '',
      },
    ],
    deleteBeneficiarios: [],
    observacao: '',
    resideMunicipioMaisTresAnos: '',
    desempregadoPeloMenosSeisMeses: '',
    rendaTotal: '',
    qtdeMembrosFamiliares: '',
    anexosInfo: {
      qtdeAnexos: 0,
      anexos: [],
    },
    deleteAnexos: [],
  };
}

import React, { Fragment, useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItemText, Typography } from '@material-ui/core';
import { Control, useController } from 'react-hook-form';
import { apiCep } from '../services/api';

interface EnderecoApi {
  cep: string;
  bairro_id: number;
  bairro_nome: string;
  logradouro_id: number;
  logradouro_nome: string;
  zona_id: number;
  zona_nome: string;
}

type Props = {
  control: Control<any>;
  name: string;
} & TextFieldProps;

const AutoCompleteAsync: React.FC<Props> = ({
  control,
  name,
  label,
  variant,
  fullWidth,
  InputProps,
  defaultValue,
  required,
  helperText,
  error,
}) => {
  const {
    field: { value: valueController },
    meta: { invalid },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<EnderecoApi[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<EnderecoApi | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultController = defaultValue as EnderecoApi;
    setValue(defaultController);
  }, [defaultValue]);

  useEffect(() => {
    setLoading(open && inputValue.length === 8 && options.length === 0);
  }, [inputValue.length, open, options.length]);

  useEffect(() => {
    let active = true;

    if (inputValue.length < 8) {
      setOptions([]);
    }

    if (!loading) {
      return undefined;
    }

    async function fetchData(): Promise<void> {
      try {
        const response = await apiCep.get<EnderecoApi[]>(`/${inputValue}`);
        const enderecos = response.data;

        if (active) {
          setOptions(enderecos);
        }
      } catch (err) {
        setLoading(false);
      }
    }

    if (inputValue.length === 8) {
      fetchData();
    }

    return () => {
      active = false;
    };
  }, [inputValue, loading, open]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        clearText="Limpar"
        closeText="Fechar"
        loadingText="Carregando..."
        noOptionsText="Nenhum CEP encontrado"
        openText="Abrir"
        getOptionSelected={(option, valueOptionSelected) => {
          return (
            `${option.logradouro_id}|${option.bairro_id}` ===
            `${valueOptionSelected.logradouro_id}|${valueOptionSelected.bairro_id}`
          );
        }}
        getOptionLabel={option => option.cep}
        options={options}
        loading={loading}
        value={value}
        onChange={(event: any, newValue: EnderecoApi | null) => {
          setValue(newValue);
          control.setValue(name, newValue || ({} as EnderecoApi), {
            shouldValidate: invalid,
            shouldDirty: true,
          });
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          const onlyNumbers = newInputValue.match(/\d{1,8}/)?.join('');
          setInputValue(onlyNumbers || '');
        }}
        renderOption={option => {
          return (
            <Fragment key={`${option.logradouro_id}|${option.bairro_id}`}>
              <ListItemText disableTypography>
                <Typography variant="h6">{option.logradouro_nome}</Typography>
                <Typography
                  display="block"
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {`Bairro: ${option.bairro_nome}`}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {`Zona: ${option.zona_nome}`}
                </Typography>
              </ListItemText>
            </Fragment>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              name={name}
              label={label}
              variant={variant}
              fullWidth={fullWidth}
              required={required}
              helperText={helperText}
              error={error}
              type="tel"
              InputProps={{
                autoComplete: 'new-password',
                ...params.InputProps,
                ...InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default AutoCompleteAsync;

import React, { Fragment, useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItemText, Typography } from '@material-ui/core';
import { Control, useController } from 'react-hook-form';
import { apiUsuario } from '../../services/api';

interface Usuario {
  cpf: string;
  email: string;
  name: string;
}

interface UsuarioPayloadApi {
  payload: {
    [index: number]: Usuario;
  };
}

type Props = {
  control: Control<any>;
  name: string;
} & TextFieldProps;

const AutoCompleteAsyncCPF: React.FC<Props> = ({
  control,
  name,
  label,
  variant,
  fullWidth,
  InputProps,
  defaultValue,
  required,
  helperText,
  error,
}) => {
  const {
    field: { value: valueController },
    meta: { invalid },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Usuario[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<Usuario | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultController = defaultValue as Usuario;
    setValue(defaultController);
  }, [defaultValue]);

  useEffect(() => {
    setLoading(open && inputValue.length === 11 && options.length === 0);
  }, [inputValue.length, open, options.length]);

  useEffect(() => {
    let active = true;

    if (inputValue.length < 11) {
      setOptions([]);
    }

    if (!loading) {
      return undefined;
    }

    async function fetchData(): Promise<void> {
      try {
        const response = await apiUsuario.get<UsuarioPayloadApi>(
          '/users/selecionar-usuario',
          {
            params: {
              cpf: inputValue,
            },
          },
        );

        const usuario = response.data.payload;

        if (active) {
          setOptions(Object.values(usuario));
        }
      } catch (err) {
        setLoading(false);
      }
    }

    if (inputValue.length === 11) {
      fetchData();
    }

    return () => {
      active = false;
    };
  }, [inputValue, loading, open]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        clearText="Limpar"
        closeText="Fechar"
        loadingText="Carregando..."
        noOptionsText="Nenhum usuário encontrado"
        openText="Abrir"
        getOptionSelected={(option, valueOptionSelected) => {
          return option.cpf === valueOptionSelected.cpf;
        }}
        getOptionLabel={option => option.cpf}
        options={options}
        loading={loading}
        value={value}
        onChange={(event: any, newValue: Usuario | null) => {
          setValue(newValue);
          control.setValue(name, newValue || ({} as Usuario), {
            shouldValidate: invalid,
            shouldDirty: true,
          });
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          const onlyNumbers = newInputValue.match(/\d{1,11}/)?.join('');
          setInputValue(onlyNumbers || '');
        }}
        renderOption={option => {
          return (
            <Fragment key={option.cpf}>
              <ListItemText disableTypography>
                <Typography variant="h6">{option.name}</Typography>
                <Typography
                  display="block"
                  variant="subtitle2"
                  color="textSecondary"
                >
                  {`Email: ${option.email}`}
                </Typography>
              </ListItemText>
            </Fragment>
          );
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              name={name}
              label={label}
              variant={variant}
              fullWidth={fullWidth}
              required={required}
              helperText={helperText}
              error={error}
              type="tel"
              InputProps={{
                autoComplete: 'new-password',
                ...params.InputProps,
                ...InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default AutoCompleteAsyncCPF;

import React from 'react';
import {
  Box,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { Cupom, Endereco } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      width: '100%',
    },
  }),
);

interface CaminhoEmpregoProps {
  cupomGerado: Cupom;
  endereco: Endereco;
}

const CaminhoEmprego: React.FC<CaminhoEmpregoProps> = ({
  cupomGerado,
  endereco,
}) => {
  const classes = useStyles();

  return (
    <>
      {cupomGerado.id > 0 && (
        <Box marginY={1}>
          <Alert severity="success" classes={{ message: classes.message }}>
            <AlertTitle>Cupom gerado</AlertTitle>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 210, maxWidth: 210 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Cupom para o curso:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">{cupomGerado.cupom}</Box>
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="caption">
              Esse cupom é exclusivo, não o informe para ninguém.
            </Typography>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 210, maxWidth: 210 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Região restrita para o curso:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">{endereco.zona_nome}</Box>
                </Typography>
              </Grid>
            </Grid>

            <Typography style={{ marginTop: 8 }} variant="body2">
              Acesse o site do caminho para o emprego{' '}
              <Link
                href="https://www.franca.sp.gov.br/caminho-emprego-portal"
                target="_blank"
              >
                https://www.franca.sp.gov.br/caminho-emprego-portal
              </Link>{' '}
              e efetue sua inscrição no curso desejado com os dados do cupom e
              região informados acima.
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default CaminhoEmprego;

const simNao = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  {
    title: 'Sim',
    value: 'SIM',
  },
  {
    title: 'Não',
    value: 'NAO',
  },
];

const raca = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  {
    title: 'Amarela',
    value: 'AMARELA',
  },
  {
    title: 'Branca',
    value: 'BRANCA',
  },
  {
    title: 'Indígena',
    value: 'INDIGENA',
  },
  {
    title: 'Parda',
    value: 'PARDA',
  },
  {
    title: 'Preta',
    value: 'PRETA',
  },
  {
    title: 'Não declarada',
    value: 'NAO_DECLARADA',
  },
];

const tipoTelefone = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  {
    title: 'Celular',
    value: 'CELULAR',
  },
  {
    title: 'Fixo',
    value: 'FIXO',
  },
  {
    title: 'Recado',
    value: 'RECADO',
  },
];

const deficienciaTipo = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  {
    title: 'Visual',
    value: 'VISUAL',
  },
  {
    title: 'Auditiva',
    value: 'AUDITIVA',
  },
  {
    title: 'Mental',
    value: 'MENTAL',
  },
  {
    title: 'Física',
    value: 'FISICA',
  },
  {
    title: 'Múltipla',
    value: 'MULTIPLA',
  },
];

const servicoAssistencial = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  { title: 'CRAS Centro', value: 1 },
  { title: 'CRAS Leste', value: 2 },
  { title: 'CRAS Norte', value: 3 },
  { title: 'CRAS Oeste', value: 4 },
  { title: 'CRAS Sul', value: 5 },
  { title: 'CREAS Centro', value: 6 },
  { title: 'CREAS Moema', value: 7 },
  { title: 'CENTRO Pop', value: 8 },
  {
    title:
      'Serviço de Convivência e Fortalecimento de Vínculos para Crianças e Adolescentes',
    value: 9,
  },
  {
    title:
      'Serviço de Convivência e Fortalecimento de Vínculos para Pessoas Idosas',
    value: 10,
  },
  {
    title:
      'Serviço de Proteção Social Básica no Domicílio para Pessoas com Deficiência e Idosas',
    value: 11,
  },
  {
    title:
      'Serviço de Proteção Social a Adolescentes em Cumprimento de Medida Socioeducativa de Liberdade Assistida (LA), e de Prestação de Serviços à Comunidade (PSC)',
    value: 12,
  },
  {
    title:
      'Serviço de Proteção Social Especial para Pessoas com Deficiência, Idosas e suas Famílias – Centro Dia Idosos',
    value: 13,
  },
  {
    title:
      'Serviço de Proteção Social Especial para Pessoas com Deficiência, Idosas e suas Famílias – Centro Dia Pessoa com Deficiência',
    value: 14,
  },
  {
    title:
      'Serviço de Proteção Social Especial para Pessoas com Deficiência, Idosas e suas Famílias – Unidade Referenciada Pessoa com Deficiência',
    value: 15,
  },
  {
    title:
      'Serviço de Proteção Social Especial para Pessoas com Deficiência, Idosas e suas Famílias – No Domicílio',
    value: 16,
  },
  { title: 'Serviço de Acolhimento em Família Acolhedora', value: 17 },
  {
    title:
      'Serviço de Acolhimento Institucional para Adultos e Famílias – Abrigo Provisório',
    value: 18,
  },
  {
    title:
      'Serviço de Acolhimento Institucional para Adultos e Famílias – Casa de Passagem',
    value: 19,
  },
  {
    title: 'Serviço de Acolhimento Institucional para Crianças e Adolescentes',
    value: 20,
  },
  {
    title: 'Serviço de Acolhimento Institucional para Pessoas Idosas – ILPI',
    value: 21,
  },
  {
    title:
      'Serviço de Acolhimento Institucional para Pessoas com Deficiência – Residencia Inclusiva',
    value: 22,
  },
  { title: ' Serviço de Acolhimento em República', value: 23 },
  {
    title: ' Serviço de acolhimento para mulheres em situação de violência',
    value: 24,
  },
];

const sexo = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  {
    title: 'Masculino',
    value: 'MASCULINO',
  },
  {
    title: 'Feminino',
    value: 'FEMININO',
  },
];

const escolaridade = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  { title: ' 	Analfabeto', value: '	ANALFABETO	' },
  { title: ' 	Ensino fundamental incompleto', value: '	FUNDAMENTALINCOMPLETO	' },
  { title: ' 	Ensino fundamental completo', value: '	FUNDAMENTALCOMPLETO	' },
  { title: ' 	Ensino médio incompleto', value: '	MEDIOINCOMPLETO	' },
  { title: ' 	Ensino médio completo', value: '	MEDIOCOMPLETO	' },
  { title: ' 	Ensino superior incompleto', value: '	SUPERIORINCOMPLETO	' },
  { title: ' 	Ensino superior completo', value: '	SUPERIORCOMPLETO	' },
  { title: ' 	Pós-graduação', value: '	POSGRADUCAO	' },
];

const parentescoMembroPrincipal = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  { title: ' 	Avô/Avó', value: 1 },
  { title: ' 	Bisneto(a)', value: 2 },
  { title: ' 	Companheiro(a)', value: 3 },
  { title: ' 	Cunhado(a)', value: 4 },
  { title: ' 	Enteado(a)', value: 5 },
  { title: ' 	Esposo(a)', value: 6 },
  { title: ' 	Filho(a)', value: 7 },
  { title: ' 	Genro/Nora', value: 8 },
  { title: ' 	Irmão/Irmã', value: 9 },
  { title: ' 	Mãe', value: 10 },
  { title: ' 	Neto(a)', value: 11 },
  { title: ' 	Outro', value: 12 },
  { title: ' 	Padrasto/Madrasta', value: 13 },
  { title: ' 	Pai', value: 14 },
  { title: ' 	Primo(a)', value: 15 },
  { title: ' 	Sem parentesco', value: 16 },
  { title: ' 	Sobrinho(a)', value: 17 },
  { title: ' 	Sogro(a)', value: 18 },
  { title: ' 	Tio(a)', value: 19 },
];

const situacaoInscricao = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  { title: 'Solicitado', value: 1 },
  { title: 'Classificado', value: 3 },
  { title: 'Recurso Impetrado', value: 6 },
  { title: 'Desclassificado', value: 7 },
  { title: 'Excluído', value: 8 },
];

const estadoCivil = [
  {
    title: 'Selecione uma opção',
    value: '',
  },
  { title: 'Solteiro', value: 'SOLTEIRO' },
  { title: 'Casado', value: 'CASADO' },
  { title: 'Separado', value: 'SEPARADO' },
  { title: 'Divorciado', value: 'DIVORCIADO' },
  { title: 'Viúvo', value: 'VIUVO' },
];

export {
  simNao,
  raca,
  tipoTelefone,
  deficienciaTipo,
  servicoAssistencial,
  sexo,
  escolaridade,
  parentescoMembroPrincipal,
  situacaoInscricao,
  estadoCivil,
};

import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { DoneAll as DoneAllIcon, Clear as ClearIcon } from '@material-ui/icons';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { green, red } from '@material-ui/core/colors';

const PaperComponent: React.FC = ({ children }) => (
  <Paper style={{ overflowX: 'auto' }}>{children}</Paper>
);

interface CriteriosList {
  nameCriterio: string;
  titleCriterio: string;
  valueCriterioUsuario: string;
  criterioConfirmado: string | null;
  valueCriterioConfirmado: string | null;
}

interface CriterioConfirmadoProps {
  criteriosList: CriteriosList[];
}

const CriterioConfirmado: React.FC<CriterioConfirmadoProps> = ({
  criteriosList,
}) => {
  return (
    <TableContainer component={PaperComponent}>
      <Table size="small">
        <TableHead>
          <TableRow style={{ border: '2px solid rgb(82 128 177)' }}>
            <TableCell>Item</TableCell>
            <TableCell align="center">Critério Calculado</TableCell>
            <TableCell align="center">Critério Confirmado</TableCell>
            <TableCell align="center">Confirmado?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {criteriosList.map((item, index) => {
            let colorTableRow;
            if (item.criterioConfirmado === 'sim') {
              const { 200: colorGreen } = green;
              colorTableRow = colorGreen;
            } else if (item.criterioConfirmado === 'nao') {
              const { 200: colorGreen } = red;
              colorTableRow = colorGreen;
            }

            let borderTop;
            let borderBottom;
            if (index === 0) {
              borderTop = '2px solid rgb(82 128 177)';
            }

            if (index === criteriosList.length - 1) {
              borderBottom = '2px solid rgb(82 128 177)';
            }

            return (
              <TableRow
                key={item.titleCriterio}
                hover
                style={{
                  backgroundColor: colorTableRow,
                  borderTop,
                  borderLeft: '2px solid rgb(82 128 177)',
                  borderRight: '2px solid rgb(82 128 177)',
                  borderBottom,
                }}
              >
                <TableCell component="th" scope="row">
                  {item.titleCriterio}
                </TableCell>

                <TableCell align="center">
                  {item.valueCriterioUsuario}
                </TableCell>
                <TableCell align="center">
                  {item.valueCriterioConfirmado}
                </TableCell>
                <TableCell align="center">
                  <ToggleButtonGroup value={item.criterioConfirmado} exclusive>
                    <ToggleButton size="small" value="sim">
                      <DoneAllIcon htmlColor={green[700]} />
                    </ToggleButton>
                    <ToggleButton size="small" value="nao">
                      <ClearIcon htmlColor={red[700]} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CriterioConfirmado;

import React, { Fragment, useCallback, useState } from 'react';

import {
  Box,
  Collapse,
  Divider,
  ListItem,
  ListItemText,
  Link,
  List,
  Typography,
  Grid,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import { apiRendaFranca } from '../../../services/api';

interface Anexo {
  nomeArq: string;
  path: string;
  tipo: string;
  lastModified: string;
}

interface AnexosInfo {
  anexos: Anexo[];
  qtdeAnexos: number;
}

interface AnexosListProps {
  anexosInfo: AnexosInfo;
}

const AnexosList: React.FC<AnexosListProps> = ({ anexosInfo }) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <List style={{ width: '100%' }}>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={`Quantidade: ${anexosInfo.qtdeAnexos}`}
          secondary="(Clique para mais detalhes)"
          primaryTypographyProps={{ variant: 'subtitle1' }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense>
          {anexosInfo.anexos &&
            anexosInfo.anexos.map((value, index) => {
              const path = `${
                apiRendaFranca.defaults.baseURL + value.path
              }?token=${apiRendaFranca.defaults.headers.authorization}`;

              return (
                <Fragment key={value.nomeArq}>
                  <ListItem>
                    <Box width="100%" paddingLeft={2}>
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          style={{ textAlign: 'center', margin: 'auto 0' }}
                        >
                          <Typography variant="body2">{index + 1}</Typography>
                        </Grid>

                        <Grid item xs sm md lg>
                          <Link href={path} target="_blank" variant="body2">
                            {value.nomeArq}
                          </Link>
                          <Typography variant="body2">
                            {format(
                              parseISO(value.lastModified),
                              'dd/MM/yyyy HH:mm:ss',
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </ListItem>

                  <Divider variant="middle" />
                </Fragment>
              );
            })}
        </List>
      </Collapse>
    </List>
  );
};

export default AnexosList;

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { createStyles, Fab, makeStyles, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import DialogInsercao from './DialogInsercao';

const useStyles = makeStyles(theme =>
  createStyles({
    flex: {
      flex: 1,
    },
  }),
);

interface DiarioOficialProps {
  setAppBarComponent(component: ReactNode): void;
}

const DiarioOficial: React.FC<DiarioOficialProps> = ({
  setAppBarComponent,
}) => {
  const classes = useStyles();
  const [openDialogInsercao, setOpenDialogInsercao] = useState(false);

  useEffect(() => {
    setAppBarComponent(
      <Typography variant="h6" color="inherit" className={classes.flex}>
        Publicações Diário Oficial
      </Typography>,
    );
  }, [setAppBarComponent, classes.flex]);

  const handleOpenDialogInsercao = useCallback(() => {
    setOpenDialogInsercao(true);
  }, []);

  const handleCloseDialogInsercao = useCallback(() => {
    setOpenDialogInsercao(false);
  }, []);

  return (
    <>
      <h1>Publicações Diário Oficial</h1>
      <DialogInsercao
        open={openDialogInsercao}
        onClose={handleCloseDialogInsercao}
      />
      <Fab
        style={{
          margin: 0,
          top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',
          position: 'fixed',
        }}
        color="primary"
        aria-label="add"
        size="large"
        onClick={handleOpenDialogInsercao}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default DiarioOficial;

import React, { useCallback, useEffect, useState } from 'react';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { RowNode } from 'ag-grid-community';
import MaskedInput from '../../components/MaskedInput';
import TextFieldSelect from '../../components/TextFieldSelect';
import { useAuth } from '../../hooks/auth';
import { apiRendaFranca } from '../../services/api';
import { addServerErrors } from '../../utils/transformErrors';
import { Pagamento } from '.';

interface DialogDadosBancariosProps {
  open: boolean;
  onClose: (cancel: boolean) => void;
  rowNodes: RowNode[];
}

const FormSchema = Yup.object().shape({
  tipoContaValue: Yup.string().required('Campo obrigatório'),
  banco: Yup.object().shape({
    nome: Yup.string().required('Campo obrigatório'),
  }),
  agencia: Yup.string()
    .required('Campo obrigatório')
    .length(4, 'Deve conter 4 dígitos'),
  operacao: Yup.string().when('$exigeOperacao', {
    is: (exigeOperacao: boolean) => exigeOperacao,
    then: Yup.string()
      .required('Campo obrigatório')
      .min(3, 'Deve conter no mínimo 3 dígitos'),
    otherwise: Yup.string(),
  }),
  conta: Yup.string().required('Campo obrigatório'),
  digito: Yup.string().required('Campo obrigatório'),
});

interface FormValues {
  idInscricao: string;
  tipoContaValue: number | string;
  banco: {
    id: number | string;
    nome: string;
    exigeOperacao: boolean;
  };
  agencia: number | string;
  operacao?: number | string;
  conta: number | string;
  digito: number | string;
}

interface Banco {
  id: number | string;
  nome: string;
  exigeOperacao: boolean;
}

interface BancoApi {
  payload: Banco[];
}

interface TipoContaSelect {
  title: string;
  value: number | string;
}

interface TipoConta {
  id: number | string;
  nome: string;
}

interface TiposContaApi {
  payload: TipoConta[];
}

const DialogDadosBancarios: React.FC<DialogDadosBancariosProps> = ({
  onClose,
  open,
  rowNodes,
}) => {
  const pagamento = rowNodes[0].data as Pagamento;

  const { signOut } = useAuth();
  const [bancosSelect, setBancosSelect] = useState<Banco[]>([]);
  const [tiposContaSelect, setTiposContaSelect] = useState<TipoContaSelect[]>([
    {
      title: 'Selecione uma opção',
      value: '',
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const [exigeOperacao, setExigeOperacao] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    context: { exigeOperacao },
    defaultValues: {
      idInscricao: pagamento.idInscricao,
      tipoContaValue: pagamento.dadosBancarios.tipoContaId,
      banco: {
        id: pagamento.dadosBancarios.bancoId,
        nome: pagamento.dadosBancarios.bancoNome,
        exigeOperacao: !!pagamento.dadosBancarios.operacao,
      },
      agencia: pagamento.dadosBancarios.agencia,
      operacao: pagamento.dadosBancarios.operacao
        ? pagamento.dadosBancarios.operacao
        : '',
      conta: pagamento.dadosBancarios.numero.toString(),
      digito: pagamento.dadosBancarios.digito,
    },
  });

  const watchBanco = watch('banco');

  useEffect(() => {
    setExigeOperacao(watchBanco.exigeOperacao);
  }, [watchBanco]);

  useEffect(() => {
    if (open) {
      (async () => {
        try {
          const responseBancos = await apiRendaFranca.get<BancoApi>(
            '/cadastros/bancos',
          );

          setBancosSelect(responseBancos.data.payload);

          const responseTiposConta = await apiRendaFranca.get<TiposContaApi>(
            '/cadastros/tipos-conta',
          );

          const tiposContaSelectResponse = responseTiposConta.data.payload.map(
            tipoConta => {
              return {
                title: tipoConta.nome,
                value: tipoConta.id,
              };
            },
          );

          tiposContaSelectResponse.splice(0, 0, {
            title: 'Selecione uma opção',
            value: '',
          });

          setTiposContaSelect(tiposContaSelectResponse);
        } catch (err) {
          if (err.response && err.response.status === 401) {
            enqueueSnackbar('Favor fazer login novamente', {
              variant: 'error',
            });
            signOut();
            return;
          }
          enqueueSnackbar('Erro ao consultar bancos', { variant: 'error' });
        }
      })();
    }
  }, [enqueueSnackbar, setError, signOut, open]);

  const handleCancel = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSubmitCallBack = useCallback(
    async (values: FormValues) => {
      try {
        await apiRendaFranca.put(`/inscricao/pagamento/dados-bancarios`, {
          ...values,
          idBanco: values.banco.id,
          idTipoConta: values.tipoContaValue,
        });

        enqueueSnackbar('Dados bancários alterados com sucesso!', {
          variant: 'success',
        });

        onClose(false);
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;

          if (errorDetails) {
            addServerErrors<FormValues>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao alterar dados bancários', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, setError, onClose],
  );

  return (
    <>
      <DevTool control={control} />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={open}
      >
        <DialogTitle>Editar Dados Bancários</DialogTitle>

        <DialogContent dividers>
          <Paper variant="outlined" style={{ padding: 16 }}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle1"
                component="h5"
                style={{ paddingRight: 4 }}
              >
                Nome:
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightMedium">
                  {pagamento.beneficiarioPrincipal[0].nome}
                </Box>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle1"
                component="h5"
                style={{ paddingRight: 4 }}
              >
                CPF:
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightMedium">
                  {pagamento.beneficiarioPrincipal[0].cpf}
                </Box>
              </Typography>
            </Box>
          </Paper>

          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSubmitCallBack)}
            id="formPrincipalId"
            style={{ marginTop: 16 }}
          >
            <Grid container spacing={2}>
              <input ref={register} type="hidden" name="idInscricao" />

              <Grid item xs={12}>
                <TextFieldSelect
                  control={control}
                  name="tipoContaValue"
                  label="Tipo de conta"
                  fullWidth
                  defaultValue={control.defaultValuesRef.current.tipoContaValue}
                  required
                  helperText={errors.tipoContaValue?.message}
                  error={!!errors.tipoContaValue}
                  oneOf={tiposContaSelect}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="banco"
                  control={control}
                  render={({ ref, value, name }) => {
                    return (
                      <Autocomplete
                        value={value}
                        onChange={(event: any, newValue: Banco | null) => {
                          setValue('banco', newValue || ({} as Banco), {
                            shouldValidate: true,
                          });
                        }}
                        getOptionSelected={(option, valueOptionSelected) => {
                          return option.id === valueOptionSelected.id;
                        }}
                        getOptionLabel={option => option.nome}
                        options={bancosSelect}
                        fullWidth
                        clearText="Limpar"
                        openText="Abrir"
                        closeText="Fechar"
                        noOptionsText="Nenhum banco encontrado"
                        renderInput={params => (
                          <TextField
                            inputRef={ref}
                            {...params}
                            name={name}
                            label="Banco"
                            variant="outlined"
                            required
                            helperText={errors.banco?.nome?.message}
                            error={!!errors.banco?.nome}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <MaskedInput
                  options={{
                    mask: '0000',
                  }}
                  control={control}
                  name="agencia"
                  label="Agencia"
                  variant="outlined"
                  type="tel"
                  fullWidth
                  required
                  helperText={
                    errors.agencia?.message
                      ? errors.agencia?.message
                      : 'sem dígito verificador. Exemplo: 1234'
                  }
                  error={!!errors.agencia}
                />
              </Grid>

              {watchBanco.exigeOperacao && (
                <Grid item xs={12}>
                  <MaskedInput
                    options={{
                      mask: '0000',
                    }}
                    control={control}
                    name="operacao"
                    label="Operação"
                    variant="outlined"
                    type="tel"
                    fullWidth
                    required
                    helperText={
                      errors.operacao?.message
                        ? errors.operacao?.message
                        : 'Exemplo: 001, 013, 023, 1288'
                    }
                    error={!!errors.operacao}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container spacing={1} direction="row">
                  <Grid item xs>
                    <MaskedInput
                      options={{
                        mask: '00000000000000000000',
                      }}
                      control={control}
                      name="conta"
                      label="Conta"
                      variant="outlined"
                      type="tel"
                      fullWidth
                      required
                      helperText={errors.conta?.message}
                      error={!!errors.conta}
                    />
                  </Grid>

                  <Grid item xs>
                    <MaskedInput
                      options={{
                        mask: [
                          {
                            mask: '00',
                          },
                          {
                            mask: '0a',
                          },
                          {
                            mask: 'a0',
                          },
                          {
                            mask: 'aa',
                          },
                        ],
                      }}
                      control={control}
                      name="digito"
                      label="Dígito Verificador"
                      variant="outlined"
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      fullWidth
                      required
                      helperText={errors.digito?.message}
                      error={!!errors.digito}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isSubmitting}
            form="formPrincipalId"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogDadosBancarios;

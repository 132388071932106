import React, { createRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core';

import { SnackbarProvider, SnackbarKey } from 'notistack';
import { StateMachineProvider } from 'little-state-machine';
import Routes from './routes';
import GlobalStyle from './styles/global';
import AppProvider from './hooks';
import SnackbarCloseButton from './components/SnackBarCloseButton';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0051a2',
    },
    secondary: {
      main: '#FB3640',
    },
  },
});

theme = responsiveFontSizes(theme);

const App: React.FC = () => {
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          action={key => <SnackbarCloseButton onClick={onClickDismiss(key)} />}
        >
          <StateMachineProvider>
            <AppProvider>
              <Routes />
            </AppProvider>
          </StateMachineProvider>
          <GlobalStyle />
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  Schedule as ScheduleIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { green, red } from '@material-ui/core/colors';
import { format, parseISO } from 'date-fns';
import { apiRendaFranca } from '../../services/api';
import { useAuth } from '../../hooks/auth';
import DialogEdicaoInscricaoPrazo from './DialogEdicaoInscricaoPrazo';
import DialogInsercaoInscricaoAbertura from './DialogInsercaoInscricaoAbertura';
import DialogRenomearInscricaoAbertura from './DialogRenomearInscricaoAbertura';

const useStyles = makeStyles(theme =>
  createStyles({
    flex: {
      flex: 1,
    },
  }),
);

export interface Prazo {
  id: number;
  nome: string;
  descricao: string;
  dataHoraInicio: string;
  dataHoraFim: string;
}

export interface InscricaoAbertura {
  id: number;
  nome: string;
  descricao: string;
  analiseEncerrada: boolean;
  prazos: Prazo[];
}

interface InscricaoAberturaApi {
  payload: InscricaoAbertura[];
}

interface InscricaoAberturaProps {
  setAppBarComponent(component: ReactNode): void;
}

export interface EditInscricaoPrazoState {
  open: boolean;
  idInscricaoAbertura: number;
  prazo: Prazo;
}

const InscricaoAberturaPg: React.FC<InscricaoAberturaProps> = ({
  setAppBarComponent,
}) => {
  const classes = useStyles();
  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [openDialogEdicaoInscricaoPrazo, setOpenDialogEdicaoInscricaoPrazo] =
    useState<EditInscricaoPrazoState>({
      open: false,
      idInscricaoAbertura: 0,
      prazo: {} as Prazo,
    });

  const [
    openDialogInsercaoInscricaoAbertura,
    setOpenDialogInsercaoInscricaoAbertura,
  ] = useState(false);

  const [
    openDialogRenomearInscricaoAbertura,
    setOpenDialogRenomearInscricaoAbertura,
  ] = useState(false);

  const [inscricaoAberturas, setInscricaoAberturas] = useState<
    InscricaoAbertura[]
  >([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const inscricaoAberturaMenu = useRef<InscricaoAbertura | null>(null);

  useEffect(() => {
    setAppBarComponent(
      <Typography variant="h6" color="inherit" className={classes.flex}>
        Abertura de Inscrições
      </Typography>,
    );
  }, [setAppBarComponent, classes.flex]);

  useEffect(() => {
    document.title = 'Renda Franca | Abertura de Inscrições';
  });

  const handleClickSettings = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      inscricaoAbertura: InscricaoAbertura,
    ) => {
      setAnchorEl(event.currentTarget);
      inscricaoAberturaMenu.current = inscricaoAbertura;
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    inscricaoAberturaMenu.current = null;
  }, []);

  const refreshData = useCallback(async () => {
    try {
      const response = await apiRendaFranca.get<InscricaoAberturaApi>(
        '/inscricao-abertura',
      );

      setInscricaoAberturas(response.data.payload);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
        signOut();
        return;
      }
      if (err.response && err.response.status === 404) {
        return;
      }
      enqueueSnackbar('Erro ao consultar aberturas de inscrições', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, signOut]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const handleToogleAnaliseEncerrada = useCallback(async () => {
    try {
      const response = await apiRendaFranca.put(
        `/inscricao-abertura/${inscricaoAberturaMenu.current?.id}`,
        {
          analiseEncerrada: !inscricaoAberturaMenu.current?.analiseEncerrada,
        },
      );

      inscricaoAberturaMenu.current = response.data
        .payload as InscricaoAbertura;

      refreshData();
    } catch (err) {
      if (err.response && err.response.status === 401) {
        enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
        signOut();
        return;
      }
      if (err.response && err.response.status === 404) {
        return;
      }
      enqueueSnackbar('Erro ao alterar registro de abertura de inscrições', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, refreshData, signOut]);

  const handleOpenDialogInsercaoInscricaoAbertura = useCallback(() => {
    setOpenDialogInsercaoInscricaoAbertura(true);
  }, []);

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2} justify="space-evenly">
          {inscricaoAberturas.map(inscricaoAbertura => {
            return (
              <Grid
                key={inscricaoAbertura.id}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
              >
                <Card elevation={4}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={event => {
                          handleClickSettings(event, inscricaoAbertura);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={inscricaoAbertura.descricao}
                  />

                  <CardContent>
                    <List component="div" disablePadding>
                      {inscricaoAbertura.analiseEncerrada && (
                        <ListItem style={{ backgroundColor: red[200] }}>
                          <ListItemIcon>
                            <LockIcon />
                          </ListItemIcon>

                          <ListItemText primary="Análise encerrada" />
                        </ListItem>
                      )}

                      {!inscricaoAbertura.analiseEncerrada && (
                        <ListItem style={{ backgroundColor: green[200] }}>
                          <ListItemIcon>
                            <LockOpenIcon />
                          </ListItemIcon>

                          <ListItemText primary="Análise aberta" />
                        </ListItem>
                      )}
                    </List>

                    <Typography variant="h6">Prazos:</Typography>
                    <List dense>
                      {inscricaoAbertura.prazos.map(prazo => {
                        return (
                          <ListItem key={prazo.id}>
                            <ListItemIcon>
                              <ScheduleIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={prazo.descricao}
                              secondary={`${format(
                                parseISO(prazo.dataHoraInicio),
                                "dd/MM/yyyy HH'h'mm",
                              )} - ${format(
                                parseISO(prazo.dataHoraFim),
                                "dd/MM/yyyy HH'h'mm",
                              )}`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={() => {
                                  setOpenDialogEdicaoInscricaoPrazo({
                                    open: true,
                                    idInscricaoAbertura: inscricaoAbertura.id,
                                    prazo,
                                  });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      {anchorEl && (
        <Menu
          id="menu-settings"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem button={false}>
            {!inscricaoAberturaMenu.current?.analiseEncerrada && (
              <>
                <ListItemIcon>
                  <LockOpenIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-analise-encerrada"
                  primary="Análise aberta"
                />
              </>
            )}

            {inscricaoAberturaMenu.current?.analiseEncerrada && (
              <>
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                <ListItemText
                  id="switch-list-label-analise-encerrada"
                  primary="Análise encerrada"
                />
              </>
            )}

            <Switch
              edge="end"
              onChange={handleToogleAnaliseEncerrada}
              checked={
                inscricaoAberturaMenu.current !== null
                  ? inscricaoAberturaMenu.current?.analiseEncerrada
                  : false
              }
              inputProps={{
                'aria-labelledby': '"switch-list-label-analise-encerrada"',
              }}
            />
          </MenuItem>

          <MenuItem
            onClick={() => setOpenDialogRenomearInscricaoAbertura(true)}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-analise-encerrada"
              primary="Renomear inscrição"
            />
          </MenuItem>
        </Menu>
      )}

      {openDialogEdicaoInscricaoPrazo.open && (
        <DialogEdicaoInscricaoPrazo
          open={openDialogEdicaoInscricaoPrazo.open}
          onClose={cancel => {
            if (!cancel) {
              refreshData();
            }

            setOpenDialogEdicaoInscricaoPrazo({
              open: false,
              idInscricaoAbertura: 0,
              prazo: {} as Prazo,
            });
          }}
          editInscricaoPrazoState={openDialogEdicaoInscricaoPrazo}
        />
      )}

      {openDialogInsercaoInscricaoAbertura && (
        <DialogInsercaoInscricaoAbertura
          open={openDialogInsercaoInscricaoAbertura}
          onClose={cancel => {
            if (!cancel) {
              refreshData();
            }

            setOpenDialogInsercaoInscricaoAbertura(false);
          }}
        />
      )}

      {openDialogRenomearInscricaoAbertura && (
        <DialogRenomearInscricaoAbertura
          open={openDialogRenomearInscricaoAbertura}
          onClose={cancel => {
            if (!cancel) {
              handleClose();
              refreshData();
            }

            setOpenDialogRenomearInscricaoAbertura(false);
          }}
          inscricaoAbertura={inscricaoAberturaMenu.current}
        />
      )}

      <Fab
        style={{
          margin: 0,
          top: 'auto',
          right: 20,
          bottom: 20,
          left: 'auto',
          position: 'fixed',
        }}
        color="primary"
        aria-label="add"
        size="large"
        onClick={handleOpenDialogInsercaoInscricaoAbertura}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

export default InscricaoAberturaPg;

import React from 'react';

import {
  Box,
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Done as DoneIcon,
} from '@material-ui/icons';

import { green } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';

import {
  Background,
  BackgroundOverlay,
  PasswordResetSuccessCard,
} from './style';

const PasswordResetSuccess: React.FC = () => {
  const history = useHistory();

  return (
    <Background container>
      <BackgroundOverlay container alignContent="center" justify="center">
        <Grid item xs={12} sm={8} md={6} lg={6}>
          <Grid container alignItems="center" justify="center">
            <PasswordResetSuccessCard style={{ maxWidth: 400 }} elevation={12}>
              <CardContent style={{ paddingBottom: 0 }}>
                <Box
                  marginBottom={1}
                  display="flex"
                  alignContent="center"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography variant="h5" component="h2">
                    Senha alterada!
                  </Typography>

                  <CheckCircleIcon
                    fontSize="large"
                    style={{ height: '100%', color: green[500] }}
                  />
                </Box>
                <Typography variant="body1" component="h2">
                  Agora você já pode realizar o login no sistema com a nova
                  senha.
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DoneIcon />}
                  onClick={() => history.replace('/', {})}
                >
                  Ir para Login
                </Button>
              </CardActions>
            </PasswordResetSuccessCard>
          </Grid>
        </Grid>
      </BackgroundOverlay>
    </Background>
  );
};

export default PasswordResetSuccess;

import React from 'react';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format, parse } from 'date-fns';

import { Ocorrencia } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      width: '100%',
    },
  }),
);

interface RecursoProps {
  recursoInscricao: Ocorrencia;
  respostaRecursoInscricao: Ocorrencia;
}

const Recurso: React.FC<RecursoProps> = ({
  recursoInscricao,
  respostaRecursoInscricao,
}) => {
  const classes = useStyles();

  return (
    <>
      {recursoInscricao.id > 0 && (
        <Box marginY={1}>
          <Alert severity="warning" classes={{ message: classes.message }}>
            <AlertTitle>Recurso efetuado</AlertTitle>

            {!respostaRecursoInscricao.id && (
              <Typography gutterBottom>
                Aguarde o prazo para o término da análise.
              </Typography>
            )}

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 130, maxWidth: 130 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Número Recurso:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">{recursoInscricao.id}</Box>
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 130, maxWidth: 130 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Data/Hora:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">
                    {recursoInscricao.dataHora &&
                      format(
                        parse(
                          recursoInscricao.dataHora,
                          'yyyy-MM-dd HH:mm:ss',
                          new Date(),
                        ),
                        "dd/MM/yyyy 'às' HH:mm:ss",
                      )}
                  </Box>
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 130, maxWidth: 130 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Descrição:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">
                    {recursoInscricao.descricao.split('\n').map((item, key) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </Box>
                </Typography>
              </Grid>
            </Grid>

            {recursoInscricao.nomeUsuario && (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  style={{ minWidth: 130, maxWidth: 130 }}
                >
                  <Typography variant="subtitle1" component="h5">
                    Usuário:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">
                      {recursoInscricao.nomeUsuario}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Alert>
        </Box>
      )}

      {respostaRecursoInscricao.id > 0 && (
        <Box marginY={1}>
          <Alert severity="warning" classes={{ message: classes.message }}>
            <AlertTitle>Resposta recurso</AlertTitle>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 140, maxWidth: 140 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Número Resposta:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">
                    {respostaRecursoInscricao.id}
                  </Box>
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 140, maxWidth: 140 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Data/Hora:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">
                    {respostaRecursoInscricao.dataHora &&
                      format(
                        parse(
                          respostaRecursoInscricao.dataHora,
                          'yyyy-MM-dd HH:mm:ss',
                          new Date(),
                        ),
                        "dd/MM/yyyy 'às' HH:mm:ss",
                      )}
                  </Box>
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                style={{ minWidth: 140, maxWidth: 140 }}
              >
                <Typography variant="subtitle1" component="h5">
                  Descrição:
                </Typography>
              </Grid>
              <Grid item xs={12} sm md lg style={{ alignSelf: 'center' }}>
                <Typography>
                  <Box fontWeight="fontWeightMedium">
                    {respostaRecursoInscricao.descricao
                      .split('\n')
                      .map((item, key) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default Recurso;

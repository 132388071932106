import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import * as React from 'react';

interface SnackbarCloseButtonProps {
  onClick?(): void;
}

const SnackbarCloseButton: React.FC<SnackbarCloseButtonProps> = ({
  onClick,
}) => {
  return (
    <IconButton style={{ color: '#fff' }} onClick={onClick}>
      <IconClose />
    </IconButton>
  );
};

export default SnackbarCloseButton;

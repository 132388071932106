import {
  Box,
  Collapse,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { parse, format } from 'date-fns';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  deficienciaTipo,
  escolaridade,
  estadoCivil,
  raca,
  sexo,
  simNao,
} from '../../../utils/enums';
import { formataCPF } from '../../../utils/functions';

interface Parentesco {
  id: number;
  nome: string;
}

interface ServicoAssistencial {
  id: number;
  nome: string;
}

interface Membro {
  tipo: string;
  nome: string;
  nomeSocial: string;
  parentesco: Parentesco;
  cpf: string;
  rg: string;
  nis: string;
  dataNascimento: string;
  menorMedidaProtecao: string;
  menorMedidaSocioEducativa: string;
  raca: string;
  possuiDeficiencia: string;
  tipoDeficiencia: string;
  email: string;
  profissao: string;
  renda: string;
  servicoAssistencial: ServicoAssistencial;
  estadoCivil: string;
  sexo: string;
  escolaridade: string;
}

interface CustomListItemProps {
  membro: Membro;
}

const MembrosList: React.FC<CustomListItemProps> = ({ membro }) => {
  const [open, setOpen] = useState(false);

  const [menorProtecaoTitle, setMenorProtecaoTitle] = useState<
    string | undefined
  >(undefined);
  const [menorSocioTitle, setMenorSocioTitle] = useState<string | undefined>(
    undefined,
  );
  const [racaTitle, setRacaTitle] = useState<string | undefined>(undefined);
  const [possuiDeficienciaTitle, setPossuiDeficienciaTitle] = useState<
    string | undefined
  >(undefined);
  const [deficienciaTipoTitle, setDeficienciaTipoTitle] = useState<
    string | undefined
  >(undefined);
  const [estadoCivilTitle, setEstadoCivilTitle] = useState<string | undefined>(
    undefined,
  );
  const [sexoTitle, setSexoTitle] = useState<string | undefined>(undefined);
  const [escolaridadeTitle, setEscolaridadeTitle] = useState<
    string | undefined
  >(undefined);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    const findItemMenorProtecao = simNao.find(
      ({ value }) => value === membro.menorMedidaProtecao,
    );
    setMenorProtecaoTitle(findItemMenorProtecao?.title);

    const findItemMenorSocio = simNao.find(
      ({ value }) => value === membro.menorMedidaSocioEducativa,
    );
    setMenorSocioTitle(findItemMenorSocio?.title);

    const findItemRaca = raca.find(({ value }) => value === membro.raca);
    setRacaTitle(findItemRaca?.title);

    const findItemPossuiDeficiencia = simNao.find(
      ({ value }) => value === membro.possuiDeficiencia,
    );
    setPossuiDeficienciaTitle(findItemPossuiDeficiencia?.title);

    const findItemDeficienciaTipo = deficienciaTipo.find(
      ({ value }) => value === membro.tipoDeficiencia,
    );
    setDeficienciaTipoTitle(findItemDeficienciaTipo?.title);

    const findItemEstadoCivil = estadoCivil.find(
      ({ value }) => value === membro.estadoCivil,
    );
    setEstadoCivilTitle(findItemEstadoCivil?.title);

    const findItemSexo = sexo.find(({ value }) => value === membro.sexo);
    setSexoTitle(findItemSexo?.title);

    if (membro.escolaridade) {
      const findItemEscolaridade = escolaridade.find(
        ({ value }) => value === membro.escolaridade,
      );
      setEscolaridadeTitle(findItemEscolaridade?.title);
    }
  }, [
    membro.menorMedidaProtecao,
    membro.menorMedidaSocioEducativa,
    membro.raca,
    membro.possuiDeficiencia,
    membro.tipoDeficiencia,
    membro.estadoCivil,
    membro.sexo,
    membro.escolaridade,
  ]);

  return (
    <Fragment key={membro.cpf}>
      <ListItem button onClick={handleClick}>
        <ListItemText secondary="(Clique para mais detalhes)">
          <Typography style={{ display: 'flex' }} variant="subtitle1">
            <span>{`${membro.nome}`}</span>
            {membro.tipo === 'PRINCIPAL' && (
              <Box marginLeft={1} fontWeight="fontWeightMedium">
                (Membro Principal)
              </Box>
            )}
          </Typography>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box paddingLeft={2}>
          <Paper elevation={0}>
            {membro.parentesco.nome && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Parentesco com Membro Principal:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {membro.parentesco.nome}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box margin={1}>
              <Grid container>
                <Grid item style={{ marginRight: 4 }}>
                  <Typography variant="subtitle1" component="h5">
                    CPF:
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">
                      {formataCPF(membro.cpf)}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {membro.rg && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      RG:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">{membro.rg}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {membro.nis && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      NIS:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">{membro.nis}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box margin={1}>
              <Grid container alignItems="flex-end">
                <Grid item style={{ marginRight: 4 }}>
                  <Typography variant="subtitle1" component="h5">
                    Data de Nascimento:
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">
                      {membro.dataNascimento &&
                        format(
                          parse(
                            membro.dataNascimento,
                            'yyyy-MM-dd',
                            new Date(),
                          ),
                          'dd/MM/yyyy',
                        )}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {menorProtecaoTitle && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Possui Medida Protetiva?:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {menorProtecaoTitle}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {menorSocioTitle && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Cumprindo Medida Socioeducativa?:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {membro.menorMedidaSocioEducativa}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box margin={1}>
              <Grid container>
                <Grid item style={{ marginRight: 4 }}>
                  <Typography variant="subtitle1" component="h5">
                    Raça:
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">{racaTitle}</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Listar Telefones */}

            {possuiDeficienciaTitle && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Possui deficiência?:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {possuiDeficienciaTitle}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {deficienciaTipoTitle && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Tipo deficiência:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {deficienciaTipoTitle}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {membro.email && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">{membro.email}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {membro.profissao && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Profissão:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {membro.profissao}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box margin={1}>
              <Grid container>
                <Grid item style={{ marginRight: 4 }}>
                  <Typography variant="subtitle1" component="h5">
                    Renda:
                  </Typography>
                </Grid>
                <Grid item style={{ alignSelf: 'center' }}>
                  <Typography>
                    <Box fontWeight="fontWeightMedium">{membro.renda}</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {membro.servicoAssistencial.nome && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Serviço Assistencial:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {membro.servicoAssistencial.nome}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {sexoTitle && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Sexo:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">{sexoTitle}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {estadoCivilTitle && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Estado Civil:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {estadoCivilTitle}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {escolaridadeTitle && (
              <Box margin={1}>
                <Grid container>
                  <Grid item style={{ marginRight: 4 }}>
                    <Typography variant="subtitle1" component="h5">
                      Escolaridade:
                    </Typography>
                  </Grid>
                  <Grid item style={{ alignSelf: 'center' }}>
                    <Typography>
                      <Box fontWeight="fontWeightMedium">
                        {escolaridadeTitle}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>
        </Box>
      </Collapse>
      <Divider />
    </Fragment>
  );
};

export default MembrosList;

import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Error as ErrorIcon, Done as DoneIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { red } from '@material-ui/core/colors';

import { InscricaoClassificada as Inscricao } from '../..';
import { formataCPF } from '../../../../../utils/functions';

interface InscricaoClassificadaProps {
  inscricao: Inscricao;
}

const InscricaoClassificada: React.FC<InscricaoClassificadaProps> = ({
  inscricao,
}) => {
  const history = useHistory();

  return (
    <Card
      style={{
        minWidth: 310,
        maxWidth: 400,
        margin: '8px auto',
        padding: '0px 10px',
      }}
      elevation={12}
    >
      <CardContent style={{ paddingBottom: 0 }}>
        <Box
          marginBottom={1}
          display="flex"
          alignContent="center"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="h5" component="h2">
            Não é possível continuar com a inscrição!
          </Typography>

          <ErrorIcon
            fontSize="large"
            style={{ height: '100%', color: red[500] }}
          />
        </Box>
        <Typography variant="body1" component="h2">
          Foi identificado que já há uma inscrição de número{' '}
          {inscricao.numeroInscricao.toString().padStart(6, '0')} para o CPF{' '}
          {formataCPF(inscricao.cpf)} com a situação da inscrição CLASSIFICADO.
        </Typography>
      </CardContent>
      <CardActions disableSpacing style={{ justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<DoneIcon />}
          onClick={() => history.replace('/', {})}
        >
          OK
        </Button>
      </CardActions>
    </Card>
  );
};

export default InscricaoClassificada;

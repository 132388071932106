const sleep = (milliseconds: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const formataCPF = (cpf: string): string => {
  const cpfOnlyNumbers = cpf.replace(/[^\d]/g, '');

  return cpfOnlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export { sleep, formataCPF };

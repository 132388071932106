/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  Divider,
  Fab,
  Grid,
  IconButton,
  List,
  ListItemText,
  makeStyles,
  Paper,
  Slide,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Add as AddIcon,
  PictureAsPdf as PictureAsPdfIcon,
  PhotoLibrary as PhotoLibraryIcon,
  QuestionAnswer as QuestionAnswerIcon,
} from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';
import { useSnackbar } from 'notistack';
import { format, parse } from 'date-fns';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { useForm } from 'react-hook-form';
import { Skeleton } from '@material-ui/lab';
import IMask from 'imask';

import { red } from '@material-ui/core/colors';
import { apiRendaFranca } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import PontuacaoDetalhe from './Pontuacao';
import MembrosList from './MembrosList';
import OcorrenciaList from './OcorrenciaList';
import AnexosList from './AnexosList';
import TextFieldSelect from '../../../components/TextFieldSelect';
import { situacaoInscricao } from '../../../utils/enums';
import DialogOcorrencia from './DialogOcorrencia';
import CriteriosDesempate from './CriteriosDesempate';
import { enqueueSnackbarErrorDetails } from '../../../utils/transformErrors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    listas: {
      width: '100%',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  // eslint-disable-next-line react/require-default-props
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogEdicaoProps {
  open: boolean;
  onClose(cancel: boolean): void;
  idUsuario: string;
  idInscricao: string;
  idInscricaoAbertura: number;
}

interface Parentesco {
  id: number;
  nome: string;
}

interface ServicoAssistencial {
  id: number;
  nome: string;
}

interface Endereco {
  cep: string;
  bairro_id: number;
  bairro_nome: string;
  logradouro_id: number;
  logradouro_nome: string;
  zona_id: number;
  zona_nome: string;
  numero: string;
  complemento: string;
}

interface Telefone {
  id: number;
  telefone: string;
  tipo: string;
}

interface Membro {
  id: string;
  tipo: string;
  nome: string;
  nomeSocial: string;
  parentesco: Parentesco;
  cpf: string;
  rg: string;
  nis: string;
  dataNascimento: string;
  menorMedidaProtecao: string;
  menorMedidaSocioEducativa: string;
  raca: string;
  possuiDeficiencia: string;
  tipoDeficiencia: string;
  email: string;
  profissao: string;
  renda: string;
  servicoAssistencial: ServicoAssistencial;
  estadoCivil: string;
  sexo: string;
  escolaridade: string;
  endereco: Endereco;
  telefones: Telefone[];
}

interface Anexo {
  nomeArq: string;
  path: string;
  tipo: string;
}

interface AnexosInfo {
  anexos: Anexo[];
  qtdeAnexos: number;
}

interface Ocorrencia {
  id: number;
  tipo: string;
  descricao: string;
  dataHora: string;
  nomeUsuario: string;
  idRespostaRecurso: number | null;
}

interface Pontuacao {
  resideParceiro: number;
  resideMunicipioMaisTresAnos: number;
  desempregadoSeisMeses: number;
  existeMembroDeficiente: number;
  rendaPerCaptaAteMeioSalarioMinimo: number;
  existeParticipanteServicoAssistencial: number;
  existeMenorMedidaProtetiva: number;
  existeMenorMedidaSocioEducativa: number;
  somaPontuacao: number;
}

interface PontuacaoConfirmada {
  resideParceiroConf: string;
  resideParceiroConfValue: number;

  resideMunicipioMaisTresAnosConf: string;
  resideMunicipioMaisTresAnosConfValue: number;

  desempregadoSeisMesesConf: string;
  desempregadoSeisMesesConfValue: number;

  existeMembroDeficienteConf: string;
  existeMembroDeficienteConfValue: number;

  rendaPerCaptaAteMeioSalarioMinimoConf: string;
  rendaPerCaptaAteMeioSalarioMinimoConfValue: number;

  existeParticipanteServicoAssistencialConf: string;
  existeParticipanteServicoAssistencialConfValue: number;

  existeMenorMedidaProtetivaConf: string;
  existeMenorMedidaProtetivaConfValue: number;

  existeMenorMedidaSocioEducativaConf: string;
  existeMenorMedidaSocioEducativaConfValue: number;

  somaPontuacaoConf: number;
}

interface Criterios {
  quantidadeMenoresDezoitoAnos: number;
  quantidadeMaioresSessentaAnos: number;
  quantidadeDeficientes: number;
  dataNascimento: string;
}

interface CriteriosConfirmados {
  quantidadeMenoresDezoitoAnosConf: string;
  quantidadeMenoresDezoitoAnosConfValue: number;

  quantidadeMaioresSessentaAnosConf: string;
  quantidadeMaioresSessentaAnosConfValue: number;

  quantidadeDeficientesConf: string;
  quantidadeDeficientesConfValue: number;

  dataNascimentoConf: string;
  dataNascimentoConfValue: string;
}

interface InscricaoAbertura {
  idInscricaoAbertura: number;
  descricaoInscricaoAbertura: string;
}

export interface Inscricao {
  id: string;
  inscricaoAbertura: InscricaoAbertura;
  numeroInscricao: number;
  dataHora: string;
  idSituacaoInscricao: number | string;
  situacao: string;
  nome: string;
  pontuacao: Pontuacao;
  pontuacaoConfirmada: PontuacaoConfirmada;
  criterios: Criterios;
  criteriosConfirmados: CriteriosConfirmados;
  membros: Membro[];
  anexosInfo: AnexosInfo;
  ocorrencias: Ocorrencia[];
  endereco: Endereco;
  rendaPerCapta: string;
}

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: any;
  value: any;
}

function a11yPropsPanelPrincipal(index: any): any {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPanelPrincipal: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '100%', height: 'calc(100% - 72px)' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

type FormValuesPrincipal = {
  idSituacaoInscricao: number | string;
};

export interface NovaOcorrencia {
  idInscricao: string;
  idTempOcorrencia: string;
  ocorrencia: string;
  idRespostaRecurso: number | null;
  respostaDesclassif: boolean;
}

interface PontuacaoList {
  namePontuacao: string;
  titlePontuacao: string;
  valuePontuacaoUsuario: number;
  pontuacaoConfirmada: string | null;
  valuePontuacaoConfirmada: number | null;
}

interface CriteriosList {
  nameCriterio: string;
  titleCriterio: string;
  valueCriterioUsuario: string;
  criterioConfirmado: string | null;
  valueCriterioConfirmado: string | null;
  options: IMask.AnyMaskedOptions;
}

interface EnumTextField {
  oneOf: {
    title: string;
    value: string | number;
    disabled?: boolean;
  }[];
}

const DialogEdicao: React.FC<DialogEdicaoProps> = ({
  open,
  onClose,
  idInscricao,
  idUsuario,
  idInscricaoAbertura,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();
  const [loading, setLoading] = useState(true);
  const [openDialogOcorrencia, setOpenDialogOcorrencia] = useState(false);
  const [titleDialogOcorrencia, setTitleDialogOcorrencia] = useState('');
  const [recursoDialogOcorrencia, setRecursoDialogOcorrencia] =
    useState<Ocorrencia | null>(null);
  const [refContainerImages, setRefContainerImages] =
    useState<HTMLDivElement | null>(null);

  const inscricao = useRef<Inscricao>({
    id: '',
    inscricaoAbertura: {
      idInscricaoAbertura: 0,
      descricaoInscricaoAbertura: '',
    },
    numeroInscricao: 0,
    dataHora: '',
    idSituacaoInscricao: '',
    situacao: '',
    nome: '',
    pontuacao: { somaPontuacao: 0 } as Pontuacao,
    membros: [] as Membro[],
    anexosInfo: {
      anexos: [] as Anexo[],
    },
    ocorrencias: [] as Ocorrencia[],
    endereco: {} as Endereco,
  } as Inscricao);

  const [images, setImages] = useState<ImageDecorator[]>([
    { src: '', alt: '' },
  ]);
  const [pdfs, setPdfs] = useState<Anexo[]>([]);

  const [novasOcorrencias, setNovasOcorrencias] = useState<NovaOcorrencia[]>(
    [],
  );

  const [pontuacaoList, setPontuacaoList] = useState<PontuacaoList[]>([]);

  const [criteriosList, setCriteriosList] = useState<CriteriosList[]>([]);

  const [valueTabPanelPrincipal, setValueTabPanelPrincipal] = useState(0);

  const [isDesclassif, setIsDesclassif] = useState(false);

  const [situacaoInscricaoAnalise, setSituacaoInscricaoAnalise] =
    useState<EnumTextField>({ oneOf: [] });

  useEffect(() => {
    setSituacaoInscricaoAnalise({
      oneOf: situacaoInscricao.map(item => {
        if (
          item.title.toUpperCase() === 'SOLICITADO' ||
          item.title.toUpperCase() === 'RECURSO IMPETRADO'
        ) {
          return { disabled: true, ...item };
        }
        return item;
      }),
    });
  }, []);

  const {
    control: controlPrincipal,
    handleSubmit: handleSubmitPrincipal,
    errors: errorsPrincipal,
    reset: resetPrincipal,
    watch: watchPrincipal,
    formState: { isSubmitting },
  } = useForm<FormValuesPrincipal>({
    defaultValues: {
      idSituacaoInscricao: '',
    },
  });

  const onRefContainerImagesChange = useCallback(node => {
    setRefContainerImages(node);
  }, []);

  const getImages = useCallback((): ImageDecorator[] => {
    const anexosImagens = inscricao.current.anexosInfo.anexos.filter(
      item => item.tipo === 'image/jpeg' || item.tipo === 'image/png',
    );

    let imagensDecorator;
    if (anexosImagens && anexosImagens.length > 0) {
      imagensDecorator = anexosImagens.map<ImageDecorator>(item => {
        const urlAnexo = `${
          apiRendaFranca.defaults.baseURL + item.path
        }?token=${apiRendaFranca.defaults.headers.authorization}`;
        return {
          src: urlAnexo,
          downloadUrl: urlAnexo,
          alt: item.nomeArq,
        };
      });
    }

    return imagensDecorator || [{ src: '', alt: '' }];
  }, []);

  const getPDFs = useCallback((): Anexo[] => {
    const anexosPdfs = inscricao.current.anexosInfo.anexos.filter(
      item => item.tipo === 'application/pdf',
    );

    let anexosWithLink;
    if (anexosPdfs && anexosPdfs.length > 0) {
      anexosWithLink = anexosPdfs.map<Anexo>(item => {
        const urlAnexo = `${
          apiRendaFranca.defaults.baseURL + item.path
        }?token=${apiRendaFranca.defaults.headers.authorization}`;
        return {
          nomeArq: item.nomeArq,
          path: urlAnexo,
          tipo: item.tipo,
        };
      });
    }

    return anexosWithLink || [];
  }, []);

  const getPontuacaoList = useCallback((): PontuacaoList[] => {
    const pontuacaoListInitial = [] as PontuacaoList[];

    pontuacaoListInitial.push({
      namePontuacao: 'resideMunicipioMaisTresAnos',
      titlePontuacao: 'Reside no município há mais de três anos',
      valuePontuacaoUsuario:
        inscricao.current.pontuacao.resideMunicipioMaisTresAnos,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.resideMunicipioMaisTresAnosConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .resideMunicipioMaisTresAnosConfValue,
    });

    pontuacaoListInitial.push({
      namePontuacao: 'desempregadoSeisMeses',
      titlePontuacao: 'Desempregado(a) há pelo menos seis meses',
      valuePontuacaoUsuario: inscricao.current.pontuacao.desempregadoSeisMeses,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.desempregadoSeisMesesConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.desempregadoSeisMesesConfValue,
    });

    pontuacaoListInitial.push({
      namePontuacao: 'rendaPerCaptaAteMeioSalarioMinimo',
      titlePontuacao: 'Renda per capta até meio salário mínimo',
      valuePontuacaoUsuario:
        inscricao.current.pontuacao.rendaPerCaptaAteMeioSalarioMinimo,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .rendaPerCaptaAteMeioSalarioMinimoConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .rendaPerCaptaAteMeioSalarioMinimoConfValue,
    });

    pontuacaoListInitial.push({
      namePontuacao: 'resideParceiro',
      titlePontuacao: 'Família monoparental',
      valuePontuacaoUsuario: inscricao.current.pontuacao.resideParceiro,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.resideParceiroConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.resideParceiroConfValue,
    });

    pontuacaoListInitial.push({
      namePontuacao: 'existeMembroDeficiente',
      titlePontuacao: 'Algum membro familiar deficiente',
      valuePontuacaoUsuario: inscricao.current.pontuacao.existeMembroDeficiente,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.existeMembroDeficienteConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.existeMembroDeficienteConfValue,
    });

    pontuacaoListInitial.push({
      namePontuacao: 'existeParticipanteServicoAssistencial',
      titlePontuacao: 'Algum membro participante de serviço assistencial',
      valuePontuacaoUsuario:
        inscricao.current.pontuacao.existeParticipanteServicoAssistencial,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .existeParticipanteServicoAssistencialConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .existeParticipanteServicoAssistencialConfValue,
    });

    pontuacaoListInitial.push({
      namePontuacao: 'existeMenorMedidaProtetiva',
      titlePontuacao: 'Existe criança com medida protetiva',
      valuePontuacaoUsuario:
        inscricao.current.pontuacao.existeMenorMedidaProtetiva,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada.existeMenorMedidaProtetivaConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .existeMenorMedidaProtetivaConfValue,
    });

    pontuacaoListInitial.push({
      namePontuacao: 'existeMenorMedidaSocioEducativa',
      titlePontuacao: 'Existe criança ou adolescente com medida socioeducativa',
      valuePontuacaoUsuario:
        inscricao.current.pontuacao.existeMenorMedidaSocioEducativa,
      pontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .existeMenorMedidaSocioEducativaConf,
      valuePontuacaoConfirmada:
        inscricao.current.pontuacaoConfirmada
          .existeMenorMedidaSocioEducativaConfValue,
    });

    return pontuacaoListInitial;
  }, []);

  const getCriterioList = useCallback((): CriteriosList[] => {
    const criterioListInicial = [] as CriteriosList[];

    criterioListInicial.push({
      nameCriterio: 'quantidadeMenoresDezoitoAnos',
      titleCriterio: 'Quantidade menores de dezoito anos',
      valueCriterioUsuario:
        inscricao.current.criterios.quantidadeMenoresDezoitoAnos.toString(),
      criterioConfirmado:
        inscricao.current.criteriosConfirmados.quantidadeMenoresDezoitoAnosConf,
      valueCriterioConfirmado:
        inscricao.current.criteriosConfirmados
          .quantidadeMenoresDezoitoAnosConfValue !== null
          ? inscricao.current.criteriosConfirmados.quantidadeMenoresDezoitoAnosConfValue.toString()
          : '',
      options: {
        mask: Number,
      },
    });

    criterioListInicial.push({
      nameCriterio: 'quantidadeMaioresSessentaAnos',
      titleCriterio: 'Quantidade maiores ou iguais a sessenta anos',
      valueCriterioUsuario:
        inscricao.current.criterios.quantidadeMaioresSessentaAnos.toString(),
      criterioConfirmado:
        inscricao.current.criteriosConfirmados
          .quantidadeMaioresSessentaAnosConf,
      valueCriterioConfirmado:
        inscricao.current.criteriosConfirmados
          .quantidadeMaioresSessentaAnosConfValue !== null
          ? inscricao.current.criteriosConfirmados.quantidadeMaioresSessentaAnosConfValue.toString()
          : '',
      options: {
        mask: Number,
      },
    });

    criterioListInicial.push({
      nameCriterio: 'quantidadeDeficientes',
      titleCriterio: 'Quantidade pessoas com deficiência',
      valueCriterioUsuario:
        inscricao.current.criterios.quantidadeDeficientes.toString(),
      criterioConfirmado:
        inscricao.current.criteriosConfirmados.quantidadeDeficientesConf,
      valueCriterioConfirmado:
        inscricao.current.criteriosConfirmados
          .quantidadeDeficientesConfValue !== null
          ? inscricao.current.criteriosConfirmados.quantidadeDeficientesConfValue.toString()
          : '',
      options: {
        mask: Number,
      },
    });

    criterioListInicial.push({
      nameCriterio: 'dataNascimento',
      titleCriterio: 'Data de nascimento membro principal',
      valueCriterioUsuario: format(
        parse(
          inscricao.current.criterios.dataNascimento,
          'yyyy-MM-dd',
          new Date(),
        ),
        'dd/MM/yyyy',
      ),
      criterioConfirmado:
        inscricao.current.criteriosConfirmados.dataNascimentoConf,
      valueCriterioConfirmado:
        inscricao.current.criteriosConfirmados.dataNascimentoConfValue !== null
          ? format(
              parse(
                inscricao.current.criteriosConfirmados.dataNascimentoConfValue,
                'yyyy-MM-dd',
                new Date(),
              ),
              'dd/MM/yyyy',
            )
          : '',
      options: {
        mask: Date,
        pattern: 'd/m/Y',
        format(date) {
          return format(date, 'dd/MM/yyyy');
        },
        parse(str) {
          return parse(str, 'dd/MM/yyyy', new Date());
        },
      },
    });

    return criterioListInicial;
  }, []);

  const handleOnEntered = useCallback(async () => {
    try {
      setLoading(true);

      const response = await apiRendaFranca.get(
        `/inscrever/${idInscricaoAbertura}/usuario/${idUsuario}`,
      );

      inscricao.current = response.data.payload;

      setImages(getImages());
      setPdfs(getPDFs());
      setPontuacaoList(getPontuacaoList());
      setCriteriosList(getCriterioList());

      resetPrincipal({
        idSituacaoInscricao: inscricao.current.idSituacaoInscricao,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
        signOut();
        return;
      }
      if (err.response && err.response.status === 404) {
        return;
      }
      enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    idUsuario,
    signOut,
    getImages,
    getPDFs,
    resetPrincipal,
    getPontuacaoList,
    getCriterioList,
    idInscricaoAbertura,
  ]);

  const handleChangeTabPanelPrincipal = useCallback(
    (event: any, newValue: number) => {
      setValueTabPanelPrincipal(newValue);
    },
    [],
  );

  const handleClearState = useCallback(() => {
    setRefContainerImages(null);

    inscricao.current = {
      id: '',
      inscricaoAbertura: {
        idInscricaoAbertura: 0,
        descricaoInscricaoAbertura: '',
      },
      numeroInscricao: 0,
      dataHora: '',
      idSituacaoInscricao: '',
      situacao: '',
      nome: '',
      pontuacao: { somaPontuacao: 0 } as Pontuacao,
      membros: [] as Membro[],
      anexosInfo: {
        anexos: [] as Anexo[],
      },
      ocorrencias: [] as Ocorrencia[],
      endereco: {} as Endereco,
    } as Inscricao;

    setImages([{ src: '', alt: '' }]);
    setPdfs([]);

    setNovasOcorrencias([]);

    setPontuacaoList([]);

    setCriteriosList([]);

    setValueTabPanelPrincipal(0);

    setIsDesclassif(false);

    setSituacaoInscricaoAnalise({ oneOf: [] });
  }, []);

  const handleSaveAllCallBack = useCallback(
    async (values: FormValuesPrincipal) => {
      try {
        const formData = new FormData();

        const formDataValues = {
          idInscricao,
          idSituacaoInscricao: values.idSituacaoInscricao,
          novasOcorrencias,
          pontuacao: pontuacaoList,
          criterios: criteriosList,
        };

        formData.append('FormDataValues', JSON.stringify(formDataValues));

        await apiRendaFranca.post('/analise/alterar-inscricao', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        onClose(false);

        enqueueSnackbar('Inscrição alterada com sucesso', {
          variant: 'success',
        });
      } catch (err) {
        if (err.response && err.response.status === 400) {
          const { errorDetails } = err.response.data;

          enqueueSnackbarErrorDetails(errorDetails, enqueueSnackbar);

          return;
        }
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
          signOut();
          return;
        }
        enqueueSnackbar('Erro ao atualizar a inscrição', { variant: 'error' });
      }
    },
    [
      enqueueSnackbar,
      signOut,
      idInscricao,
      onClose,
      novasOcorrencias,
      pontuacaoList,
      criteriosList,
    ],
  );

  const handleSubmitPrincipalCallBack = useCallback(
    (values: FormValuesPrincipal) => {
      const apto = situacaoInscricao.find(
        ({ value }) => value === values.idSituacaoInscricao,
      );

      if (apto?.title.toUpperCase() === 'CLASSIFICADO') {
        const pontuacaoNaoConfirmado = pontuacaoList.filter(
          itemPontuacaoList => itemPontuacaoList.pontuacaoConfirmada === null,
        );

        const criterioNaoConfirmado = criteriosList.filter(
          itemCriteriosList => itemCriteriosList.criterioConfirmado === null,
        );

        if (
          pontuacaoNaoConfirmado.length > 0 ||
          criterioNaoConfirmado.length > 0
        ) {
          enqueueSnackbar(
            'Para situação CLASSIFICADO, confirmar todas as pontuações e todos os critérios',
            {
              variant: 'info',
            },
          );
        } else {
          handleSaveAllCallBack(values);
        }
      } else {
        handleSaveAllCallBack(values);
      }
    },
    [enqueueSnackbar, pontuacaoList, criteriosList, handleSaveAllCallBack],
  );

  const onDesclassif = useCallback(
    (isDesclassifPontuacao: boolean) => {
      const findItemSituacaoInscricao = situacaoInscricao.find(
        ({ title }) => title.toUpperCase() === 'DESCLASSIFICADO',
      );

      if (isDesclassifPontuacao) {
        resetPrincipal({
          idSituacaoInscricao: findItemSituacaoInscricao?.value || '',
        });
      } else {
        resetPrincipal({
          idSituacaoInscricao: inscricao.current.idSituacaoInscricao,
        });
      }

      setIsDesclassif(isDesclassifPontuacao);
    },
    [resetPrincipal, setIsDesclassif],
  );

  return (
    <Dialog
      key={idUsuario}
      fullScreen
      open={open}
      onClose={onClose}
      onEntered={handleOnEntered}
      onExited={handleClearState}
      TransitionComponent={Transition}
      scroll="paper"
    >
      {loading && (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose(true)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {`${inscricao.current.nome}`}
          </Typography>
          <Button
            autoFocus
            color="inherit"
            startIcon={<SaveIcon />}
            form="formPrincipalId"
            type="submit"
            disabled={isSubmitting}
          >
            Salvar
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmitPrincipal(handleSubmitPrincipalCallBack)}
          id="formPrincipalId"
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card elevation={5}>
                <CardContent style={{ padding: 8 }}>
                  {!loading && (
                    <Paper variant="outlined" style={{ padding: 8 }}>
                      <List
                        dense
                        className={classes.listas}
                        style={{ padding: 0 }}
                      >
                        <PontuacaoDetalhe
                          pontuacaoList={pontuacaoList}
                          somaPontuacaoOriginal={
                            inscricao.current.pontuacao.somaPontuacao
                          }
                          onDesclassif={onDesclassif}
                          onChange={pontuacaoListChange =>
                            setPontuacaoList(pontuacaoListChange)
                          }
                        />
                      </List>
                      <List
                        dense
                        className={classes.listas}
                        style={{ padding: 0 }}
                      >
                        <CriteriosDesempate
                          criteriosList={criteriosList}
                          onChange={criteriosListChange =>
                            setCriteriosList(criteriosListChange)
                          }
                        />
                      </List>
                    </Paper>
                  )}

                  {loading && (
                    <div>
                      <Skeleton variant="text" animation="wave" width="20%" />

                      <Skeleton variant="text" animation="wave" width="20%" />

                      <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height={120}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container direction="row" alignItems="stretch" spacing={2}>
                <Grid item xs sm md lg>
                  <Card elevation={5}>
                    <CardContent>
                      {!loading && (
                        <>
                          <Box marginY={1}>
                            <TextFieldSelect
                              control={controlPrincipal}
                              name="idSituacaoInscricao"
                              label="Situação Inscrição"
                              disabled={isDesclassif}
                              fullWidth
                              defaultValue={
                                controlPrincipal.defaultValuesRef.current
                                  .idSituacaoInscricao
                              }
                              helperText={
                                errorsPrincipal.idSituacaoInscricao?.message
                              }
                              error={!!errorsPrincipal.idSituacaoInscricao}
                              oneOf={situacaoInscricaoAnalise.oneOf}
                            />
                          </Box>

                          <Box marginY={1}>
                            <Paper variant="outlined" style={{ padding: 16 }}>
                              <Box margin={1}>
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="subtitle1"
                                    component="h5"
                                    style={{ paddingRight: 4 }}
                                  >
                                    Edição da inscrição:
                                  </Typography>
                                  <Typography>
                                    <Box fontWeight="fontWeightMedium">
                                      {
                                        inscricao.current.inscricaoAbertura
                                          .descricaoInscricaoAbertura
                                      }
                                    </Box>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box margin={1}>
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="subtitle1"
                                    component="h5"
                                    style={{ paddingRight: 4 }}
                                  >
                                    Número da inscrição:
                                  </Typography>
                                  <Typography>
                                    <Box fontWeight="fontWeightMedium">
                                      {inscricao.current.numeroInscricao
                                        .toString()
                                        .padStart(6, '0')}
                                    </Box>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box margin={1}>
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="subtitle1"
                                    component="h5"
                                    style={{ paddingRight: 4 }}
                                  >
                                    Data/Hora Inscrição:
                                  </Typography>
                                  <Typography>
                                    <Box fontWeight="fontWeightMedium">
                                      {inscricao.current.dataHora &&
                                        format(
                                          parse(
                                            inscricao.current.dataHora,
                                            'yyyy-MM-dd HH:mm:ss',
                                            new Date(),
                                          ),
                                          "dd/MM/yyyy 'às' HH:mm:ss",
                                        )}
                                    </Box>
                                  </Typography>
                                </Box>
                              </Box>

                              <Box margin={1}>
                                <Grid container>
                                  <Grid item style={{ marginRight: 4 }}>
                                    <Typography
                                      variant="subtitle1"
                                      component="h5"
                                    >
                                      Renda per capta:
                                    </Typography>
                                  </Grid>
                                  <Grid item style={{ alignSelf: 'center' }}>
                                    <Typography>
                                      <Box fontWeight="fontWeightMedium">
                                        {inscricao.current.rendaPerCapta}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>

                          <Box marginY={1}>
                            <Paper variant="outlined" style={{ padding: 16 }}>
                              <Typography>
                                <Box
                                  marginBottom={1}
                                  fontWeight="fontWeightMedium"
                                >
                                  Endereço
                                </Box>
                              </Typography>
                              <Grid container spacing={2}>
                                {inscricao.current.endereco && (
                                  <Grid item xs={12}>
                                    <Box paddingLeft={1}>
                                      <ListItemText disableTypography>
                                        <Typography variant="h6">
                                          {`CEP: ${inscricao.current.endereco.cep}`}
                                        </Typography>
                                        <Typography variant="h6">
                                          {`${inscricao.current.endereco.logradouro_nome}, ${inscricao.current.endereco.numero}`}
                                        </Typography>
                                        {inscricao.current.endereco
                                          .complemento && (
                                          <Typography
                                            display="block"
                                            variant="subtitle2"
                                            color="textSecondary"
                                          >
                                            {`Complemento: ${inscricao.current.endereco.complemento}`}
                                          </Typography>
                                        )}
                                        <Typography
                                          display="block"
                                          variant="subtitle2"
                                          color="textSecondary"
                                        >
                                          {`Bairro: ${inscricao.current.endereco.bairro_nome}`}
                                        </Typography>
                                        <Typography
                                          display="block"
                                          variant="caption"
                                          color="textSecondary"
                                        >
                                          {`Zona: ${inscricao.current.endereco.zona_nome}`}
                                        </Typography>
                                      </ListItemText>
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            </Paper>
                          </Box>

                          <Box margin={2}>
                            <Divider variant="middle" />
                          </Box>

                          <Paper style={{ padding: 8 }}>
                            <Typography
                              variant="subtitle1"
                              component="h5"
                              style={{ paddingRight: 4 }}
                            >
                              <Box fontWeight="fontWeightMedium">
                                Lista de Membros:
                              </Box>
                            </Typography>

                            <List className={classes.listas}>
                              {inscricao.current.membros &&
                                inscricao.current.membros.map(value => {
                                  return (
                                    <MembrosList
                                      key={value.id}
                                      membro={value}
                                    />
                                  );
                                })}
                            </List>
                          </Paper>
                        </>
                      )}

                      {loading && (
                        <div>
                          <Skeleton
                            variant="rect"
                            animation="wave"
                            width="100%"
                            height={400}
                          />
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs sm md lg>
                  {!loading && (
                    <>
                      <AppBar position="static">
                        <Tabs
                          value={valueTabPanelPrincipal}
                          onChange={handleChangeTabPanelPrincipal}
                          aria-label="simple tabs example"
                        >
                          <Tab
                            label="Anexos Imagens"
                            icon={
                              images.length > 0 &&
                              images[0].src !== '' && <PhotoLibraryIcon />
                            }
                            {...a11yPropsPanelPrincipal(0)}
                          />
                          <Tab
                            label="Anexos PDFs"
                            icon={pdfs.length > 0 && <PictureAsPdfIcon />}
                            {...a11yPropsPanelPrincipal(1)}
                          />
                        </Tabs>
                      </AppBar>
                      <TabPanelPrincipal
                        value={valueTabPanelPrincipal}
                        index={0}
                      >
                        <Card
                          elevation={5}
                          style={{ width: '100%', height: '100%' }}
                        >
                          <CardContent
                            style={{ width: '100%', height: '100%' }}
                          >
                            <div
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              ref={onRefContainerImagesChange}
                            />
                            <Viewer
                              downloadable
                              downloadInNewWindow
                              noClose
                              visible
                              zoomSpeed={0.1}
                              container={refContainerImages || undefined}
                              images={images}
                            />
                          </CardContent>
                        </Card>
                      </TabPanelPrincipal>

                      <TabPanelPrincipal
                        value={valueTabPanelPrincipal}
                        index={1}
                      >
                        <Card
                          elevation={5}
                          style={{ width: '100%', height: '100%' }}
                        >
                          <CardContent>
                            <AnexosList anexos={pdfs} />
                          </CardContent>
                        </Card>
                      </TabPanelPrincipal>
                    </>
                  )}

                  {loading && (
                    <Card elevation={5}>
                      <CardContent>
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          width="100%"
                          height={400}
                        />
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card elevation={5}>
                <CardContent style={{ position: 'relative' }}>
                  {!loading && (
                    <>
                      <Fab
                        color="primary"
                        style={{
                          position: 'absolute',
                          left: '100%',
                          marginLeft: -62,
                          top: 4,
                        }}
                        onClick={() => {
                          setOpenDialogOcorrencia(true);
                          setTitleDialogOcorrencia('Adicionar Ocorrência');
                        }}
                      >
                        <AddIcon />
                      </Fab>
                      {novasOcorrencias.length > 0 && (
                        <Paper variant="outlined" style={{ padding: 8 }}>
                          <Typography
                            variant="subtitle1"
                            component="h5"
                            style={{ paddingRight: 4 }}
                          >
                            <Box fontWeight="fontWeightMedium">
                              Novas Ocorrências:
                            </Box>
                          </Typography>

                          {novasOcorrencias.map(item => {
                            return (
                              <>
                                <Box
                                  key={item.idTempOcorrencia}
                                  margin={1}
                                  position="relative"
                                  minHeight={50}
                                >
                                  {item.idRespostaRecurso && (
                                    <Tooltip title="Resposta Recurso">
                                      <Fab
                                        size="small"
                                        style={{
                                          position: 'absolute',
                                          left: '100%',
                                          marginLeft: -54,
                                          top: 4,
                                          backgroundColor: '#F49C44',
                                        }}
                                      >
                                        <QuestionAnswerIcon />
                                      </Fab>
                                    </Tooltip>
                                  )}
                                  {item.respostaDesclassif && (
                                    <Tooltip title="Resposta Desclassificação">
                                      <Fab
                                        size="small"
                                        style={{
                                          position: 'absolute',
                                          left: '100%',
                                          marginLeft: -54,
                                          top: 4,
                                          backgroundColor: red[500],
                                        }}
                                      >
                                        <QuestionAnswerIcon />
                                      </Fab>
                                    </Tooltip>
                                  )}
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      style={{ minWidth: 85, maxWidth: 85 }}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        component="h5"
                                      >
                                        Ocorrência:
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm
                                      md
                                      lg
                                      style={{ alignSelf: 'center' }}
                                    >
                                      <Typography>
                                        <Box fontWeight="fontWeightMedium">
                                          {item.ocorrencia
                                            .split('\n')
                                            .map((itemSplit, key) => {
                                              return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <span key={key}>
                                                  {itemSplit}
                                                  <br />
                                                </span>
                                              );
                                            })}
                                        </Box>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  {item.idRespostaRecurso && (
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={2}
                                        md={2}
                                        lg={2}
                                        style={{ minWidth: 160, maxWidth: 160 }}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          component="h5"
                                        >
                                          Resposta ao recurso:
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm
                                        md
                                        lg
                                        style={{ alignSelf: 'center' }}
                                      >
                                        <Typography>
                                          <Box fontWeight="fontWeightMedium">
                                            {item.idRespostaRecurso}
                                          </Box>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Box>
                                <Divider />
                              </>
                            );
                          })}
                        </Paper>
                      )}

                      <Box marginY={1} />

                      <Paper variant="outlined" style={{ padding: 8 }}>
                        <Typography
                          variant="subtitle1"
                          component="h5"
                          style={{ paddingRight: 4 }}
                        >
                          <Box fontWeight="fontWeightMedium">
                            Ocorrências Já inseridas:
                          </Box>
                        </Typography>

                        <List className={classes.listas}>
                          {inscricao.current.ocorrencias &&
                            inscricao.current.ocorrencias.map(value => {
                              return (
                                <OcorrenciaList
                                  key={value.id}
                                  ocorrencia={value}
                                  onClickRecurso={recurso => {
                                    setOpenDialogOcorrencia(true);
                                    setTitleDialogOcorrencia(
                                      'Adicionar Resposta ao Recurso',
                                    );
                                    setRecursoDialogOcorrencia(recurso);
                                  }}
                                />
                              );
                            })}
                        </List>
                      </Paper>
                    </>
                  )}

                  {loading && (
                    <div>
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height={150}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogOcorrencia
        idInscricao={inscricao.current.id}
        open={openDialogOcorrencia}
        onClose={(cancel, novaOcorrencia) => {
          if (!cancel) {
            setNovasOcorrencias(previous => {
              previous.push(novaOcorrencia);
              return previous;
            });
          }

          setOpenDialogOcorrencia(false);
          setTitleDialogOcorrencia('');
          setRecursoDialogOcorrencia(null);
        }}
        title={titleDialogOcorrencia}
        recurso={recursoDialogOcorrencia}
      />
    </Dialog>
  );
};

export default DialogEdicao;

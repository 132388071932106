import React from 'react';

import { Paper } from '@material-ui/core';
import AnexosList from './AnexosList';
import { AnexosInfo } from '..';

interface AnexosProps {
  anexosInfo: AnexosInfo;
}

const Anexos: React.FC<AnexosProps> = ({ anexosInfo }) => {
  return (
    <Paper style={{ padding: 8 }}>
      <AnexosList anexosInfo={anexosInfo} />
    </Paper>
  );
};

export default Anexos;

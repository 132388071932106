import React, { useEffect, useState } from 'react';
import IMask from 'imask';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Control, useController } from 'react-hook-form';

export type MaskedEvent = {
  value: any;
  name?: string;
};

type InputMask = {
  onAccept?: (
    event: MaskedEvent,
    ref: React.RefObject<HTMLInputElement>,
  ) => void;
  onComplete?: (
    event: MaskedEvent,
    ref: React.RefObject<HTMLInputElement>,
  ) => void;
} & TextFieldProps;

type Props = {
  options: IMask.AnyMaskedOptions;
  control: Control<any>;
  name: string;
} & InputMask;

const MaskedInput: React.FC<Props> = ({
  options,
  control,
  name,
  label,
  type,
  variant,
  fullWidth,
  defaultValue,
  inputProps,
  required,
  helperText,
  error,
  onAccept,
  onComplete,
}) => {
  const {
    field: { ref, value: valueController },
    meta: { invalid },
  } = useController({
    name,
    control,
    defaultValue,
    onFocus: () => {
      ref?.current?.focus();
    },
  });

  const inputRef = ref;

  const [mask, setMask] =
    useState<IMask.InputMask<IMask.AnyMaskedOptions> | null>(null);

  useEffect(() => {
    if (inputRef.current && !mask) {
      const maskIMask = IMask(inputRef.current, {
        ...options,
      });
      maskIMask.unmaskedValue = valueController;

      setMask(maskIMask);
    }
  }, [mask, options, inputRef, valueController]);

  useEffect(() => {
    if (inputRef.current) {
      if (mask) {
        mask.on('accept', () => {
          if (inputRef.current) {
            control.setValue(name, inputRef.current.value, {
              shouldValidate: invalid,
              shouldDirty: true,
            });
            if (onAccept) {
              onAccept(
                {
                  name,
                  value: inputRef.current.value,
                },
                inputRef,
              );
            }
          }
        });

        mask.on('complete', () => {
          if (inputRef.current) {
            control.setValue(name, inputRef.current.value, {
              shouldValidate: invalid,
              shouldDirty: true,
            });
            if (onComplete) {
              onComplete(
                {
                  name,
                  value: inputRef.current.value,
                },
                inputRef,
              );
            }
          }
        });
      }
    }
  }, [mask, name, onAccept, onComplete, inputRef, control, invalid]);

  return (
    <TextField
      inputRef={inputRef}
      name={name}
      label={label}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
      helperText={helperText}
      error={error}
      inputProps={inputProps}
    />
  );
};

export default MaskedInput;

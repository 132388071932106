import React, { useCallback } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { ICellRendererParams } from 'ag-grid-community';
import { useSnackbar } from 'notistack';

import DialogEdicao from './Dialog/DialogEdicao';
import { useAuth } from '../../hooks/auth';
import { apiRendaFranca } from '../../services/api';
import { InscricoesResponse } from '.';

const OperacoesRenderer: React.FC<ICellRendererParams> = props => {
  const [open, setOpen] = React.useState(false);
  const { data, api } = props;
  const { idUsuario, idInscricao, inscricaoAbertura } = data;
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();

  const handleClick = useCallback((): void => {
    setOpen(true);
  }, []);

  const handleUpdateData = useCallback(async () => {
    try {
      api.showLoadingOverlay();

      const response = await apiRendaFranca.get<InscricoesResponse>(
        `/inscrever/listar-todos`,
      );

      api.setRowData(response.data.payload);
      api.refreshClientSideRowModel('filter');

      api.hideOverlay();
    } catch (err) {
      if (err.response && err.response.status === 401) {
        enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
        signOut();
        return;
      }
      enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
    }
  }, [enqueueSnackbar, signOut, api]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <IconButton size="small" color="primary" onClick={handleClick}>
          <EditIcon />
        </IconButton>
      </Box>
      <DialogEdicao
        open={open}
        onClose={cancel => {
          if (!cancel) {
            handleUpdateData();
          }
          setOpen(false);
        }}
        idInscricao={idInscricao}
        idUsuario={idUsuario}
        idInscricaoAbertura={inscricaoAbertura.idInscricaoAbertura}
      />
    </>
  );
};

export default OperacoesRenderer;

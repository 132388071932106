import React, { useCallback } from 'react';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { isValid, format, formatISO, parseISO } from 'date-fns';

import KeyboardDateTimePicker from '../../../components/KeyBoardDateTimePicker';
import { apiRendaFranca } from '../../../services/api';
import { addServerErrors } from '../../../utils/transformErrors';
import { EditInscricaoPrazoState } from '../index';

interface DialogEdicaoInscricaoPrazoProps {
  open: boolean;
  onClose: (cancel: boolean) => void;
  editInscricaoPrazoState: EditInscricaoPrazoState;
}

interface FormValues {
  idInscricaoAbertura: number;
  idInscricaoPrazo: number;
  descricao: string;
  dataHoraInicio: any;
  dataHoraFim: any;
}

const FormSchema = Yup.object().shape({
  descricao: Yup.string().required('Campo obrigatório'),
  dataHoraInicio: Yup.date()
    .typeError('Campo inválido')
    .min(
      new Date(1900, 0, 1),
      'A data deve ser posterior ou igual a 01/01/1900',
    )
    .max(new Date(2100, 0, 1), 'A data deve ser anterior ou igual a 01/01/2100')
    .required('Campo obrigatório'),
  dataHoraFim: Yup.date()
    .typeError('Campo inválido')
    .min(
      new Date(1900, 0, 1),
      'A data deve ser posterior ou igual a 01/01/1900',
    )
    .max(new Date(2100, 0, 1), 'A data deve ser anterior ou igual a 01/01/2100')
    .required('Campo obrigatório'),
});

const DialogEdicaoInscricaoPrazo: React.FC<DialogEdicaoInscricaoPrazoProps> = ({
  onClose,
  open,
  editInscricaoPrazoState,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      idInscricaoAbertura: editInscricaoPrazoState.idInscricaoAbertura,
      idInscricaoPrazo: editInscricaoPrazoState.prazo.id,
      descricao: editInscricaoPrazoState.prazo.descricao,
      dataHoraInicio: editInscricaoPrazoState.prazo.dataHoraInicio,
      dataHoraFim: editInscricaoPrazoState.prazo.dataHoraFim,
    },
  });

  const handleCancel = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSubmitCallBack = useCallback(
    async (values: FormValues) => {
      try {
        await apiRendaFranca.put(
          `/inscricao-abertura/${values.idInscricaoAbertura}/prazo/${values.idInscricaoPrazo}`,
          {
            ...values,
            dataHoraInicio: formatISO(values.dataHoraInicio),
            dataHoraFim: formatISO(values.dataHoraFim),
          },
        );

        enqueueSnackbar('Prazo alterado com sucesso!', {
          variant: 'success',
        });

        onClose(false);
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;

          if (errorDetails) {
            addServerErrors<FormValues>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao alterar prazo', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, setError, onClose],
  );

  return (
    <>
      <DevTool control={control} />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={open}
      >
        <DialogTitle>Editar Prazo da Inscrição</DialogTitle>

        <DialogContent dividers>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSubmitCallBack)}
            id="formPrincipalId"
            style={{ marginTop: 16 }}
          >
            <Grid container spacing={2}>
              <input ref={register} type="hidden" name="idInscricaoAbertura" />

              <input ref={register} type="hidden" name="idInscricaoPrazo" />

              <Grid item xs={12}>
                <TextField
                  inputRef={register}
                  name="descricao"
                  label="Descrição"
                  variant="outlined"
                  fullWidth
                  required
                  helperText={errors.descricao?.message}
                  error={!!errors.descricao}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="dataHoraInicio"
                  control={control}
                  render={props => {
                    return (
                      <KeyboardDateTimePicker
                        name={props.name}
                        label="Data/Hora Início"
                        value={
                          isValid(parseISO(props.value))
                            ? parseISO(props.value)
                            : null
                        }
                        inputValue={
                          !isValid(parseISO(props.value))
                            ? props.value
                            : format(
                                parseISO(props.value),
                                'dd/MM/yyyy HH:mm:ss',
                              )
                        }
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date(2100, 0, 1)}
                        helperText={errors.dataHoraInicio?.message}
                        required
                        error={!!errors.dataHoraInicio}
                        onChange={(date, value) => {
                          if (date && isValid(date)) {
                            setValue('dataHoraInicio', formatISO(date), {
                              shouldValidate: true,
                            });
                          } else {
                            setValue('dataHoraInicio', value, {
                              shouldValidate: false,
                            });
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="dataHoraFim"
                  control={control}
                  render={props => {
                    return (
                      <KeyboardDateTimePicker
                        name={props.name}
                        label="Data/Hora Fim"
                        value={
                          isValid(parseISO(props.value))
                            ? parseISO(props.value)
                            : null
                        }
                        inputValue={
                          !isValid(parseISO(props.value))
                            ? props.value
                            : format(
                                parseISO(props.value),
                                'dd/MM/yyyy HH:mm:ss',
                              )
                        }
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date()}
                        helperText={errors.dataHoraFim?.message}
                        required
                        error={!!errors.dataHoraFim}
                        onChange={(date, value) => {
                          if (date && isValid(date)) {
                            setValue('dataHoraFim', formatISO(date), {
                              shouldValidate: true,
                            });
                          } else {
                            setValue('dataHoraFim', value, {
                              shouldValidate: false,
                            });
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isSubmitting}
            form="formPrincipalId"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEdicaoInscricaoPrazo;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useStateMachine } from 'little-state-machine';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import * as Yup from 'yup';
import {
  AccountCircle,
  AddCircleOutline,
  Delete as DeleteIcon,
  Edit as EditIcon,
  RemoveCircleOutline,
} from '@material-ui/icons';
import {
  isValid,
  formatISO,
  parseISO,
  differenceInYears,
  format,
  endOfDay,
} from 'date-fns';
import { cpf } from 'cpf-cnpj-validator';
import { createIntl, createIntlCache } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  updateStepThree,
  updateStepThreeRemoveTelefone,
  updateStepThreeRemoveBeneficiario,
  deleteMemberAtIndex,
  clearState,
} from '../../../../utils/updateActions';
import KeyBoardDatePicker from '../../../../components/KeyBoardDatePicker';
import TextFieldSelect from '../../../../components/TextFieldSelect';
import {
  deficienciaTipo,
  escolaridade,
  parentescoMembroPrincipal,
  raca,
  servicoAssistencial,
  sexo,
  simNao,
  tipoTelefone,
} from '../../../../utils/enums';
import MaskedInput from '../../../../components/MaskedInput';
import { formataCPF } from '../../../../utils/functions';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'pt-BR',
    messages: {},
  },
  cache,
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface BeneficiarioTelefone {
  idTelefone: number;
  telefone: string;
  tipoTelefone: string;
}

interface Beneficiario {
  idBeneficiario: string;
  nome: string;
  nomeSocial: string;
  parentescoMembroPrincipal: number | string;
  cpf: string;
  rg: string;
  nis: string;
  dataNascimento: any;
  raca: string;
  telefones: BeneficiarioTelefone[];
  deleteTelefones: BeneficiarioTelefone[];
  possuiDeficiencia: string;
  deficienciaTipo: string;
  email: string;
  profissao: string;
  renda: any;
  servicoAssistencial: number | string;
  sexo: string;
  escolaridade: string;
  menorMedidaProtecao: string;
  menorMedidaSocioEducativa: string;
  numero: string;
  complemento: string;
}

type FormValues = {
  beneficiarioList: Beneficiario[];
  beneficiario: Beneficiario;
  qtdeMembrosFamiliares: any;
  rendaTotal: any;
};

interface EditInfo {
  isEdit: boolean;
  indexEdit: number;
}

interface MemberAlreadyAdded {
  added: boolean;
  id: string;
  name: string;
}

const StepThreeSchema = Yup.object().shape({
  beneficiario: Yup.object().shape({
    nome: Yup.string()
      .required('Campo obrigatório')
      .trim()
      .matches(/^[\p{L}\p{N}*-]*(?: [\p{L}\p{N}*-]+)+$/u, 'Campo inválido'),
    nomeSocial: Yup.string(),
    parentescoMembroPrincipal: Yup.string().required('Campo obrigatório'),
    cpf: Yup.string()
      .required('Campo CPF obrigatório')
      .matches(/\d{3}\.\d{3}\.\d{3}-\d{2}/, 'Campo CPF inválido')
      .test('is valid cpf', 'Campo CPF inválido', value => {
        return cpf.isValid(value || '');
      }),
    rg: Yup.string().max(20, 'Campo inválido'),
    nis: Yup.string().matches(/^(\s*|\d{11})$/, 'Campo inválido'),
    dataNascimento: Yup.date()
      .typeError('Campo inválido')
      .min(
        new Date(1900, 0, 1),
        'A data de nascimento deve ser posterior a 01/01/1900',
      )
      .max(
        endOfDay(new Date()),
        'A data de nascimento deve ser anterior ou igual a hoje',
      )
      .required('Campo obrigatório'),
    raca: Yup.string().required('Campo obrigatório'),
    telefones: Yup.array().of(
      Yup.object().shape({
        tipoTelefone: Yup.string(),
        telefone: Yup.string(),
      }),
    ),
    possuiDeficiencia: Yup.string().required('Campo obrigatório'),
    deficienciaTipo: Yup.string().when('possuiDeficiencia', {
      is: 'SIM',
      then: Yup.string().required('Campo obrigatório'),
    }),
    email: Yup.string(),
    profissao: Yup.string(),
    renda: Yup.number()
      .transform((value, originalValue) => {
        if (value as typeof Number) return value;

        const parseValue = parseFloat(
          originalValue
            .replaceAll('R$', '')
            .replaceAll('.', '')
            .replaceAll(',', '.')
            .trim(),
        );

        return parseValue;
      })
      .typeError('Campo inválido')
      .min(0, 'O valor mínimo para o campo é 0')
      .required('Campo obrigatório'),
    servicoAssistencial: Yup.string(),
    sexo: Yup.string().required('Campo obrigatório'),
    escolaridade: Yup.string(),
    menorMedidaProtecao: Yup.string().when('$idade', {
      is: (idade: number) => idade < 18,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string(),
    }),
    menorMedidaSocioEducativa: Yup.string().when('$idade', {
      is: (idade: number) => idade >= 12 && idade < 18,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string(),
    }),
  }),
});

const StepThree: React.FC<any> = ({ handleNext, handleBack }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const [idade, setIdade] = useState(0);
  const [isValidDataNascimento, setValidDataNascimento] = useState(false);

  const { state, actions } = useStateMachine({
    updateStepThree,
    updateStepThreeRemoveTelefone,
    updateStepThreeRemoveBeneficiario,
    deleteMemberAtIndex,
    clearState,
  });

  const [rendaPerCapta, setRendaPerCapta] = useState(0);
  const [rendaPerCaptaPassoOne, setRendaPerCaptaPassoOne] = useState(0);
  const [abort, setAbort] = useState(false);

  const {
    setValue,
    control,
    handleSubmit,
    register,
    errors,
    watch,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(StepThreeSchema),
    context: { idade },
    defaultValues: {
      beneficiarioList: state.beneficiarioList,
      rendaTotal: state.rendaTotal,
      qtdeMembrosFamiliares: state.qtdeMembrosFamiliares,
      beneficiario: {
        idBeneficiario: '',
        nome: '',
        nomeSocial: '',
        parentescoMembroPrincipal: '',
        cpf: '',
        rg: '',
        nis: '',
        dataNascimento: '',
        raca: '',
        telefones: [
          {
            idTelefone: 0,
            telefone: '',
            tipoTelefone: '',
          },
        ],
        deleteTelefones: [],
        possuiDeficiencia: '',
        deficienciaTipo: '',
        email: '',
        profissao: '',
        renda: '',
        servicoAssistencial: '',
        sexo: '',
        escolaridade: '',
        menorMedidaProtecao: '',
        menorMedidaSocioEducativa: '',
      },
    },
  });
  const history = useHistory();

  const watchRendaTotalPassoOne = watch('rendaTotal', state.rendaTotal);

  const watchQtdeMembrosFamiliares = watch(
    'qtdeMembrosFamiliares',
    state.qtdeMembrosFamiliares,
  );

  const watchPossuiDeficiencia = watch('beneficiario.possuiDeficiencia', '');

  const watchDataNascimento = watch('beneficiario.dataNascimento', '');

  const [openDialog, setOpenDialog] = useState(false);

  const [editInfo, setEditInfo] = useState<EditInfo>({
    isEdit: false,
    indexEdit: state.beneficiarioList.length,
  } as EditInfo);

  const handleClickOpen = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleClose = useCallback(
    (aceitou: boolean) => {
      if (aceitou) {
        actions.clearState(undefined);
        history.push('/home');
      }
      setOpenDialog(false);
    },
    [history, actions],
  );

  useEffect(() => {
    if (isValid(parseISO(watchDataNascimento))) {
      setIdade(differenceInYears(new Date(), parseISO(watchDataNascimento)));
      setValidDataNascimento(true);
    } else {
      setIdade(0);
      setValidDataNascimento(false);
    }
  }, [watchDataNascimento]);

  const {
    fields: fieldsBeneficiarios,
    append: appendBeneficiarios,
    remove: removeBeneficiarios,
  } = useFieldArray<Beneficiario>({
    control,
    name: 'beneficiarioList',
  });

  const {
    fields: fieldsTelefones,
    append: appendTelefones,
    remove: removeTelefones,
  } = useFieldArray({
    control,
    name: 'beneficiario.telefones',
  });

  const watchBeneficiarios = watch('beneficiarioList');

  useEffect(() => {
    let rendaTotal = 0;

    watchBeneficiarios.forEach(beneficiario => {
      rendaTotal += parseFloat(
        beneficiario.renda
          .replaceAll('R$', '')
          .replaceAll('.', '')
          .replaceAll(',', '.')
          .trim(),
      );
    });

    setRendaPerCapta(rendaTotal / watchQtdeMembrosFamiliares);
  }, [watchBeneficiarios, watchQtdeMembrosFamiliares]);

  useEffect(() => {
    setRendaPerCaptaPassoOne(
      watchRendaTotalPassoOne
        .replaceAll('R$', '')
        .replaceAll('.', '')
        .replaceAll(',', '.')
        .trim() / watchQtdeMembrosFamiliares,
    );
  }, [watchRendaTotalPassoOne, watchQtdeMembrosFamiliares]);

  useEffect(() => {
    if (
      rendaPerCapta > 550 ||
      watchQtdeMembrosFamiliares - watchBeneficiarios.length > 0 ||
      rendaPerCapta !== rendaPerCaptaPassoOne
    ) {
      setAbort(true);
    } else {
      setAbort(false);
    }
  }, [
    rendaPerCapta,
    watchQtdeMembrosFamiliares,
    watchBeneficiarios.length,
    rendaPerCaptaPassoOne,
  ]);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const isMemberAlreadyAdded = useCallback(
    (cpfToAdd: string): MemberAlreadyAdded => {
      const beneficiarioFind = state.beneficiarioList.find(
        (beneficiario, index) =>
          beneficiario.cpf ===
            cpfToAdd.replaceAll('.', '').replaceAll('-', '').trim() &&
          (!editInfo.isEdit ||
            (editInfo.isEdit && editInfo.indexEdit !== index)),
      );

      if (beneficiarioFind) {
        return {
          added: true,
          id: beneficiarioFind.idBeneficiario,
          name: beneficiarioFind.nome,
        };
      }

      return {
        added: false,
        id: '',
        name: '',
      };
    },
    [state.beneficiarioList, editInfo.isEdit, editInfo.indexEdit],
  );

  const handleSubmitCallBack = useCallback(
    (values: FormValues) => {
      const memberAlreadyAdded = isMemberAlreadyAdded(values.beneficiario.cpf);

      if (memberAlreadyAdded.added) {
        setError('beneficiario.cpf', {
          type: 'server',
          message: `Membro ${memberAlreadyAdded.name} já adicionado com esse CPF`,
          shouldFocus: true,
        });
      } else {
        actions.updateStepThree({
          index: editInfo.indexEdit,
          beneficiario: {
            ...values.beneficiario,
            dataNascimento: formatISO(values.beneficiario.dataNascimento, {
              representation: 'date',
            }),
            renda: intl.formatNumber(values.beneficiario.renda, {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            }),
            deleteTelefones:
              state.beneficiarioList[editInfo.indexEdit]?.deleteTelefones,
          },
        });

        if (editInfo.isEdit) {
          const beneficiarioListState = state.beneficiarioList;
          beneficiarioListState[editInfo.indexEdit] = {
            ...values.beneficiario,
            dataNascimento: formatISO(values.beneficiario.dataNascimento, {
              representation: 'date',
            }),
            renda: intl.formatNumber(values.beneficiario.renda, {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            }),
            deleteTelefones:
              state.beneficiarioList[editInfo.indexEdit]?.deleteTelefones,
          };

          setValue('beneficiarioList', beneficiarioListState);
        } else {
          appendBeneficiarios({
            ...values.beneficiario,
            dataNascimento: formatISO(values.beneficiario.dataNascimento, {
              representation: 'date',
            }),
            renda: intl.formatNumber(values.beneficiario.renda, {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            }),
          });
        }

        handleExpandClick();

        setEditInfo({
          isEdit: false,
          indexEdit: state.beneficiarioList.length,
        });
      }
    },
    [
      actions,
      handleExpandClick,
      appendBeneficiarios,
      setValue,
      state.beneficiarioList,
      editInfo.isEdit,
      editInfo.indexEdit,
      isMemberAlreadyAdded,
      setError,
    ],
  );

  const handleCancelInsertOrEditMembro = useCallback(() => {
    reset({
      beneficiarioList: state.beneficiarioList,
      beneficiario: {
        idBeneficiario: '',
        nome: '',
        nomeSocial: '',
        parentescoMembroPrincipal: '',
        cpf: '',
        rg: '',
        nis: '',
        dataNascimento: '',
        raca: '',
        telefones: [
          {
            idTelefone: 0,
            telefone: '',
            tipoTelefone: '',
          },
        ],
        possuiDeficiencia: '',
        deficienciaTipo: '',
        email: '',
        profissao: '',
        renda: '',
        servicoAssistencial: '',
        sexo: '',
        escolaridade: '',
        menorMedidaProtecao: '',
        menorMedidaSocioEducativa: '',
      },
    });

    handleExpandClick();
  }, [reset, state.beneficiarioList, handleExpandClick]);

  const handleEditMembro = useCallback(
    (index: number) => {
      setEditInfo({
        isEdit: true,
        indexEdit: index,
      });

      reset({
        beneficiarioList: state.beneficiarioList,
        beneficiario: {
          idBeneficiario: fieldsBeneficiarios[index].idBeneficiario,
          nome: fieldsBeneficiarios[index].nome,
          nomeSocial: fieldsBeneficiarios[index].nomeSocial,
          parentescoMembroPrincipal:
            fieldsBeneficiarios[index].parentescoMembroPrincipal,
          cpf: formataCPF(fieldsBeneficiarios[index].cpf || ''),
          rg: fieldsBeneficiarios[index].rg,
          nis: fieldsBeneficiarios[index].nis,
          dataNascimento: fieldsBeneficiarios[index].dataNascimento,
          raca: fieldsBeneficiarios[index].raca,
          telefones: fieldsBeneficiarios[
            index
          ].telefones?.map<BeneficiarioTelefone>(
            (item: BeneficiarioTelefone) => {
              return {
                idTelefone: item.idTelefone,
                telefone: item.telefone,
                tipoTelefone: item.tipoTelefone,
              };
            },
          ),
          possuiDeficiencia: fieldsBeneficiarios[index].possuiDeficiencia,
          deficienciaTipo: fieldsBeneficiarios[index].deficienciaTipo,
          email: fieldsBeneficiarios[index].email,
          profissao: fieldsBeneficiarios[index].profissao,
          renda: fieldsBeneficiarios[index].renda,
          servicoAssistencial: fieldsBeneficiarios[index].servicoAssistencial,
          sexo: fieldsBeneficiarios[index].sexo,
          escolaridade: fieldsBeneficiarios[index].escolaridade,
          menorMedidaProtecao: fieldsBeneficiarios[index].menorMedidaProtecao,
          menorMedidaSocioEducativa:
            fieldsBeneficiarios[index].menorMedidaSocioEducativa,
        },
      });

      handleExpandClick();
    },
    [handleExpandClick, fieldsBeneficiarios, reset, state.beneficiarioList],
  );

  const handleRemoveTelefone = useCallback(
    (phoneItem: BeneficiarioTelefone, index: number) => {
      if (phoneItem.idTelefone) {
        actions.updateStepThreeRemoveTelefone({
          index: editInfo.indexEdit,
          telefone: phoneItem,
        });
      }
      removeTelefones(index);
    },
    [actions, removeTelefones, editInfo.indexEdit],
  );

  const handleDeleteMembro = useCallback(
    (index: number) => {
      if (fieldsBeneficiarios[index].idBeneficiario) {
        actions.updateStepThreeRemoveBeneficiario({
          idBeneficiario: fieldsBeneficiarios[index].idBeneficiario || '',
          nome: fieldsBeneficiarios[index].nome || '',
          cpf: formataCPF(fieldsBeneficiarios[index].cpf || ''),
        });
      }

      actions.deleteMemberAtIndex(index);
      removeBeneficiarios(index);
    },
    [actions, fieldsBeneficiarios, removeBeneficiarios],
  );

  return (
    <>
      <Grid container alignContent="center" justify="center">
        <Grid item xs={12} sm={9} md={7} lg={5}>
          <Box maxWidth={530} marginX="auto">
            <Card elevation={12}>
              <CardContent>
                {!expanded && (
                  <Box my={1}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<AddCircleOutline />}
                      disabled={
                        watchQtdeMembrosFamiliares -
                          watchBeneficiarios.length ===
                        0
                      }
                      onClick={() => {
                        setEditInfo({
                          isEdit: false,
                          indexEdit: state.beneficiarioList.length,
                        });
                        handleExpandClick();
                      }}
                    >
                      Adicionar Membro Familiar
                    </Button>
                  </Box>
                )}

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <form
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit(handleSubmitCallBack)}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        spacing={2}
                      >
                        <input
                          ref={register}
                          type="hidden"
                          name="beneficiario.idBeneficiario"
                        />
                        <Grid item xs={12}>
                          <TextField
                            inputRef={register}
                            name="beneficiario.nome"
                            label="Nome Completo"
                            variant="outlined"
                            fullWidth
                            required
                            helperText={errors.beneficiario?.nome?.message}
                            error={!!errors.beneficiario?.nome}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            inputRef={register}
                            name="beneficiario.nomeSocial"
                            label="Nome Social"
                            variant="outlined"
                            fullWidth
                            helperText={
                              errors.beneficiario?.nomeSocial?.message
                            }
                            error={!!errors.beneficiario?.nomeSocial}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldSelect
                            control={control}
                            name="beneficiario.parentescoMembroPrincipal"
                            label="Parentesco com Membro Principal"
                            fullWidth
                            defaultValue={
                              control.defaultValuesRef.current.beneficiario
                                ?.parentescoMembroPrincipal
                            }
                            required
                            helperText={
                              errors.beneficiario?.parentescoMembroPrincipal
                                ?.message
                            }
                            error={
                              !!errors.beneficiario?.parentescoMembroPrincipal
                            }
                            oneOf={parentescoMembroPrincipal}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <MaskedInput
                            options={{
                              mask: '000.000.000-00',
                            }}
                            control={control}
                            name="beneficiario.cpf"
                            label="CPF"
                            type="tel"
                            variant="outlined"
                            fullWidth
                            required
                            helperText={errors.beneficiario?.cpf?.message}
                            error={!!errors.beneficiario?.cpf}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            inputRef={register}
                            name="beneficiario.rg"
                            label="RG"
                            variant="outlined"
                            inputProps={{ maxLength: 20 }}
                            fullWidth
                            helperText={errors.beneficiario?.rg?.message}
                            error={!!errors.beneficiario?.rg}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <MaskedInput
                            options={{
                              mask: '00000000000',
                            }}
                            control={control}
                            name="beneficiario.nis"
                            label="NIS"
                            type="tel"
                            variant="outlined"
                            fullWidth
                            helperText={errors.beneficiario?.nis?.message}
                            error={!!errors.beneficiario?.nis}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controller
                            name="beneficiario.dataNascimento"
                            control={control}
                            render={props => {
                              return (
                                <KeyBoardDatePicker
                                  name={props.name}
                                  label="Data de Nascimento"
                                  value={
                                    isValid(parseISO(props.value))
                                      ? parseISO(props.value)
                                      : null
                                  }
                                  inputValue={
                                    !isValid(parseISO(props.value))
                                      ? props.value
                                      : format(
                                          parseISO(props.value),
                                          'dd/MM/yyyy',
                                        )
                                  }
                                  minDate={new Date(1900, 0, 1)}
                                  maxDate={new Date()}
                                  helperText={
                                    errors.beneficiario?.dataNascimento?.message
                                  }
                                  required
                                  error={!!errors.beneficiario?.dataNascimento}
                                  onChange={(date, value) => {
                                    if (date && isValid(date)) {
                                      setValue(
                                        'beneficiario.dataNascimento',
                                        formatISO(date, {
                                          representation: 'date',
                                        }),
                                        {
                                          shouldValidate: true,
                                        },
                                      );
                                    } else {
                                      setValue(
                                        'beneficiario.dataNascimento',
                                        value,
                                        {
                                          shouldValidate: false,
                                        },
                                      );
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </Grid>

                        {isValidDataNascimento && idade < 18 && (
                          <Grid item xs={12}>
                            <TextFieldSelect
                              control={control}
                              name="beneficiario.menorMedidaProtecao"
                              label="Possui Medida Protetiva?"
                              fullWidth
                              defaultValue={
                                control.defaultValuesRef.current.beneficiario
                                  ?.menorMedidaProtecao
                              }
                              required
                              helperText={
                                errors.beneficiario?.menorMedidaProtecao
                                  ?.message
                              }
                              error={!!errors.beneficiario?.menorMedidaProtecao}
                              oneOf={simNao}
                            />
                          </Grid>
                        )}

                        {isValidDataNascimento && idade >= 12 && idade < 18 && (
                          <Grid item xs={12}>
                            <TextFieldSelect
                              control={control}
                              name="beneficiario.menorMedidaSocioEducativa"
                              label="Cumprindo Medida Socioeducativa?"
                              fullWidth
                              defaultValue={
                                control.defaultValuesRef.current.beneficiario
                                  ?.menorMedidaSocioEducativa
                              }
                              required
                              helperText={
                                errors.beneficiario?.menorMedidaSocioEducativa
                                  ?.message
                              }
                              error={
                                !!errors.beneficiario?.menorMedidaSocioEducativa
                              }
                              oneOf={simNao}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <TextFieldSelect
                            control={control}
                            name="beneficiario.raca"
                            label="Raça"
                            fullWidth
                            defaultValue={
                              control.defaultValuesRef.current.beneficiario
                                ?.raca
                            }
                            required
                            helperText={errors.beneficiario?.raca?.message}
                            error={!!errors.beneficiario?.raca}
                            oneOf={raca}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          {fieldsTelefones.map((phoneItem, index) => {
                            return (
                              <Grid key={phoneItem.id} container spacing={1}>
                                <input
                                  ref={register()}
                                  type="hidden"
                                  name={`beneficiario.telefones[${index}].idTelefone`}
                                  defaultValue={phoneItem.idTelefone}
                                />
                                <Grid item xs>
                                  <MaskedInput
                                    options={{
                                      mask: [
                                        {
                                          mask: '(00) 0000-0000',
                                        },
                                        {
                                          mask: '(00) 00000-0000',
                                        },
                                      ],
                                    }}
                                    control={control}
                                    name={`beneficiario.telefones[${index}].telefone`}
                                    label="Telefone"
                                    type="tel"
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={phoneItem.telefone}
                                    helperText={
                                      errors.beneficiario?.telefones?.[index]
                                        ?.telefone?.message
                                    }
                                    error={
                                      !!errors.beneficiario?.telefones?.[index]
                                        ?.telefone
                                    }
                                  />
                                </Grid>

                                <Grid item xs>
                                  <TextFieldSelect
                                    control={control}
                                    name={`beneficiario.telefones[${index}].tipoTelefone`}
                                    label="Tipo Telefone"
                                    fullWidth
                                    defaultValue={phoneItem.tipoTelefone}
                                    helperText={
                                      errors.beneficiario?.telefones?.[index]
                                        ?.tipoTelefone?.message
                                    }
                                    error={
                                      !!errors.beneficiario?.telefones?.[index]
                                        ?.tipoTelefone
                                    }
                                    oneOf={tipoTelefone}
                                  />
                                </Grid>

                                <Grid item xs={1}>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                  >
                                    <IconButton
                                      size="small"
                                      color="secondary"
                                      onClick={() =>
                                        handleRemoveTelefone(
                                          phoneItem as BeneficiarioTelefone,
                                          index,
                                        )
                                      }
                                    >
                                      <RemoveCircleOutline />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>

                        <Grid item xs={12}>
                          <Grid
                            container
                            alignItems="flex-start"
                            justify="flex-start"
                          >
                            <Box textAlign="center">
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                disabled={fieldsTelefones.length === 3}
                                startIcon={<AddCircleOutline />}
                                onClick={() => {
                                  if (fieldsTelefones.length === 3) return;
                                  appendTelefones(
                                    { telefone: '', tipoTelefone: '' },
                                    false,
                                  );
                                }}
                              >
                                Adicionar Telefone
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldSelect
                            control={control}
                            name="beneficiario.possuiDeficiencia"
                            label="Possui deficiência?"
                            fullWidth
                            defaultValue={
                              control.defaultValuesRef.current.beneficiario
                                ?.possuiDeficiencia
                            }
                            required
                            helperText={
                              errors.beneficiario?.possuiDeficiencia?.message
                            }
                            error={!!errors.beneficiario?.possuiDeficiencia}
                            oneOf={simNao}
                          />
                        </Grid>

                        {watchPossuiDeficiencia === 'SIM' && (
                          <Grid item xs={12}>
                            <TextFieldSelect
                              control={control}
                              name="beneficiario.deficienciaTipo"
                              label="Tipo deficiência"
                              fullWidth
                              defaultValue={
                                control.defaultValuesRef.current.beneficiario
                                  ?.deficienciaTipo
                              }
                              required
                              helperText={
                                errors.beneficiario?.deficienciaTipo?.message
                              }
                              error={!!errors.beneficiario?.deficienciaTipo}
                              oneOf={deficienciaTipo}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <TextField
                            inputRef={register}
                            name="beneficiario.email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            helperText={errors.beneficiario?.email?.message}
                            error={!!errors.beneficiario?.email}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            inputRef={register}
                            name="beneficiario.profissao"
                            label="Profissão"
                            variant="outlined"
                            fullWidth
                            helperText={errors.beneficiario?.profissao?.message}
                            error={!!errors.beneficiario?.profissao}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <MaskedInput
                            options={{
                              mask: 'R$ num',
                              blocks: {
                                num: {
                                  mask: Number,
                                  scale: 2,
                                  signed: false,
                                  thousandsSeparator: '.',
                                  padFractionalZeros: false,
                                  normalizeZeros: false,
                                  radix: ',',
                                  mapToRadix: [','],
                                },
                              },
                            }}
                            control={control}
                            name="beneficiario.renda"
                            label="Renda"
                            type="tel"
                            variant="outlined"
                            fullWidth
                            required
                            helperText={
                              errors.beneficiario?.renda?.message
                                ? errors.beneficiario?.renda?.message
                                : "Informe '0' caso não tenha renda"
                            }
                            error={!!errors.beneficiario?.renda}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldSelect
                            control={control}
                            name="beneficiario.servicoAssistencial"
                            label="Serviço Assistencial"
                            fullWidth
                            defaultValue={
                              control.defaultValuesRef.current.beneficiario
                                ?.servicoAssistencial
                            }
                            helperText={
                              errors.beneficiario?.servicoAssistencial?.message
                            }
                            error={!!errors.beneficiario?.servicoAssistencial}
                            oneOf={servicoAssistencial}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldSelect
                            control={control}
                            name="beneficiario.sexo"
                            label="Sexo"
                            fullWidth
                            defaultValue={
                              control.defaultValuesRef.current.beneficiario
                                ?.sexo
                            }
                            required
                            helperText={errors.beneficiario?.sexo?.message}
                            error={!!errors.beneficiario?.sexo}
                            oneOf={sexo}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldSelect
                            control={control}
                            name="beneficiario.escolaridade"
                            label="Escolaridade"
                            fullWidth
                            defaultValue={
                              control.defaultValuesRef.current.beneficiario
                                ?.escolaridade
                            }
                            helperText={
                              errors.beneficiario?.escolaridade?.message
                            }
                            error={!!errors.beneficiario?.escolaridade}
                            oneOf={escolaridade}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        alignItems="flex-end"
                        justify="flex-end"
                        spacing={1}
                      >
                        <Grid item>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            onClick={handleCancelInsertOrEditMembro}
                          >
                            Cancelar
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {editInfo.isEdit
                              ? 'Editar Membro'
                              : 'Incluir Membro'}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Collapse>

                {abort && (
                  <Alert severity="error">
                    <AlertTitle>Atenção</AlertTitle>

                    {watchQtdeMembrosFamiliares - watchBeneficiarios.length >
                      0 && (
                      <Typography>{`• Falta adicionar ${
                        watchQtdeMembrosFamiliares - watchBeneficiarios.length
                      } membro(s)`}</Typography>
                    )}

                    {rendaPerCapta > 550 && (
                      <Typography>{`• Renda per capta: ${intl.formatNumber(
                        rendaPerCapta,
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                        },
                      )}`}</Typography>
                    )}

                    {rendaPerCapta !== rendaPerCaptaPassoOne && (
                      <Typography>{`• Renda per capta calculada (${intl.formatNumber(
                        rendaPerCapta,
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                        },
                      )}) não confere com a inserida no passo 1 (${intl.formatNumber(
                        rendaPerCaptaPassoOne,
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                        },
                      )})`}</Typography>
                    )}
                  </Alert>
                )}

                <Typography gutterBottom variant="h6" component="h2">
                  Lista de Membros
                </Typography>
                <List dense>
                  {watchBeneficiarios.map((beneficiarioItem, index) => {
                    return (
                      <Fragment key={beneficiarioItem.cpf}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <AccountCircle />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={beneficiarioItem.nome}
                            secondary={
                              beneficiarioItem.nomeSocial ? (
                                <>
                                  <div>{beneficiarioItem.nomeSocial}</div>
                                  <div>
                                    {' '}
                                    CPF: {formataCPF(beneficiarioItem.cpf)}
                                  </div>
                                </>
                              ) : (
                                <div>
                                  CPF: {formataCPF(beneficiarioItem.cpf)}
                                </div>
                              )
                            }
                          />
                          <ListItemSecondaryAction>
                            {index > 0 && (
                              <>
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  onClick={() => handleEditMembro(index)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => handleDeleteMembro(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </Fragment>
                    );
                  })}
                </List>

                <Grid
                  container
                  alignItems="flex-end"
                  justify="flex-end"
                  spacing={1}
                >
                  <Grid item xs>
                    <Button
                      className={classes.button}
                      onClick={handleClickOpen}
                      variant="contained"
                      color="secondary"
                      size={matches ? 'small' : 'medium'}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={handleBack}
                      className={classes.button}
                      size={matches ? 'small' : 'medium'}
                    >
                      Anterior
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handleNext}
                      size={matches ? 'small' : 'medium'}
                      disabled={expanded || abort}
                    >
                      Próximo
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Dialog
          open={openDialog}
          onClose={() => handleClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Cancelar inscrição?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Os dados não salvos serão excluídos
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose(false)} color="primary">
              Não
            </Button>
            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <DevTool control={control} />
    </>
  );
};

export default StepThree;

import React from 'react';
import {
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  StandardTextFieldProps,
  Theme,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

interface ItemTextFieldSelect extends StandardTextFieldProps {
  oneOf: {
    title: string;
    value: string | number;
    disabled?: boolean;
  }[];
  control: any;
  onChangeExternal?(value: unknown): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      width: 200,
    },
    listItem: {
      whiteSpace: 'normal',
    },
  }),
);

const TextFieldSelect: React.FC<ItemTextFieldSelect> = ({
  control,
  name,
  label,
  helperText,
  error,
  defaultValue,
  required,
  oneOf,
  fullWidth,
  onChangeExternal,
  disabled,
}) => {
  const labelId = `${name}-label`;

  const classes = useStyles();

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      variant="outlined"
      error={error}
      disabled={disabled}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name || ''}
        control={control}
        defaultValue={defaultValue}
        render={({ ref, value, onChange }) => {
          return (
            <Select
              inputRef={ref}
              value={value}
              labelId={labelId}
              label={`${label} ${required ? '*' : ''}`}
              MenuProps={{ className: classes.menu }}
              onChange={e => {
                onChange(e.target.value);
                if (onChangeExternal) {
                  onChangeExternal(e.target.value);
                }
              }}
            >
              {oneOf.map(item => (
                <MenuItem
                  className={classes.listItem}
                  key={item.value}
                  value={item.value}
                  disabled={item.disabled}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default TextFieldSelect;

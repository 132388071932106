import React from 'react';
import { Done as DoneIcon, Close as CloseIcon } from '@material-ui/icons';
import { ICellRendererParams } from 'ag-grid-community';
import { green, red } from '@material-ui/core/colors';
import { Checkbox } from '@material-ui/core';
import { InscricaoCurso } from '../pages/Pagamento';

interface ConclusaoCellRenderer extends ICellRendererParams {
  onChange(
    idInscricao: string,
    inscricaoCurso: InscricaoCurso,
    checked: boolean,
  ): void;
}

const ConclusaoCellRenderer: React.FC<ConclusaoCellRenderer> = ({
  value,
  node,
  onChange,
}) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Checkbox
          color="primary"
          onChange={(event, checked) => onChange(node.id || '', value, checked)}
        />
        {value.conclusaoCurso && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DoneIcon style={{ color: green[500] }} />
          </div>
        )}
        {!value.conclusaoCurso && value.conclusaoCurso !== null && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CloseIcon style={{ color: red[500] }} />
          </div>
        )}
      </div>
    </>
  );
};

export default ConclusaoCellRenderer;

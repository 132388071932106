import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Receipt as ReceiptIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import RendaFrancaLogo from '../../assets/renda_franca_logo.jpeg';
import { useAuth } from '../../hooks/auth';
import { apiRendaFranca } from '../../services/api';
import {
  InscricaoAberturaApi,
  InscricaoAbertura,
} from '../../components/AppBar';

const useStyles = makeStyles(theme =>
  createStyles({
    flex: {
      flex: 1,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

interface HomeProps {
  setAppBarComponent(component: ReactNode): void;
}

const Home: React.FC<HomeProps> = ({ setAppBarComponent }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { signOut } = useAuth();

  const [inscricaoAberturas, setInscricaoAberturas] = useState<
    InscricaoAbertura[]
  >([]);

  useEffect(() => {
    setAppBarComponent(
      <Typography variant="h6" color="inherit" className={classes.flex}>
        Início
      </Typography>,
    );
  }, [setAppBarComponent, classes.flex]);

  useEffect(() => {
    document.title = 'Renda Franca | Início';
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await apiRendaFranca.get<InscricaoAberturaApi>(
          '/cadastros/inscricao-abertura',
        );

        setInscricaoAberturas(response.data.payload);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
          signOut();
          return;
        }
        if (err.response && err.response.status === 404) {
          return;
        }
        enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar, signOut]);

  return (
    <Box margin={1}>
      <Grid container justify="space-evenly" alignItems="stretch" spacing={2}>
        <Grid item xs sm md lg style={{ maxWidth: 340, alignSelf: 'center' }}>
          <Card elevation={5}>
            <CardMedia
              style={{ height: 140 }}
              component="img"
              image={RendaFrancaLogo}
              title="Seja bem vindo(a)!"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h4">
                Seja bem vindo(a)!
              </Typography>
              <Typography gutterBottom variant="body1">
                Escolha uma das inscrições abaixo:
              </Typography>
              {inscricaoAberturas.map(inscricaoAbertura => {
                return (
                  <List
                    key={inscricaoAbertura.id}
                    component="div"
                    disablePadding
                  >
                    <ListItem
                      button
                      className={classes.nested}
                      component={Link}
                      to={`/inscricoes/${inscricaoAbertura.id}`}
                    >
                      <ListItemIcon>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText primary={inscricaoAbertura.descricao} />
                    </ListItem>
                  </List>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;

import React, { useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format, parse } from 'date-fns';
import { Edit as EditIcon } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { getColor } from '../../../utils/colorSituacaoInscricao';
import { Prazo, InscricaoAbertura, Ocorrencia } from '..';
import DialogRecursoInscricao from './DialogRecursoInscricao';
import DialogGeracaoCupom from './DialogGeracaoCupom';
import Cronograma from './Cronograma';

const useStyles = makeStyles(() =>
  createStyles({
    message: {
      width: '100%',
    },
  }),
);

interface SituacaoProps {
  idInscricao: string;
  podeBannerAnaliseInscricao: boolean;
  prazoBannerAnalise: Prazo;
  podeEditarInscricao: boolean;
  prazoEditarInscricao: Prazo;
  podeRecursoInscricao: boolean;
  prazoRecursoInscricao: Prazo;
  podeGeracaoCupom: boolean;
  prazoGeracaoCupom: Prazo;
  inscricaoAbertura: InscricaoAbertura;
  numeroInscricao: number;
  dataHora: string;
  situacao: string;
  descricaoSituacao: string;
  ocorrenciasRespostaDesclassif: Ocorrencia[];

  handleEditarInscricao(): void;
  handleRefreshScreen(): void;
}

const Situacao: React.FC<SituacaoProps> = ({
  idInscricao,
  podeBannerAnaliseInscricao,
  prazoBannerAnalise,
  podeEditarInscricao,
  prazoEditarInscricao,
  podeRecursoInscricao,
  prazoRecursoInscricao,
  podeGeracaoCupom,
  prazoGeracaoCupom,
  inscricaoAbertura,
  numeroInscricao,
  dataHora,
  situacao,
  descricaoSituacao,
  ocorrenciasRespostaDesclassif,
  handleEditarInscricao,
  handleRefreshScreen,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [openDialogRecurso, setOpenDialogRecurso] = useState(false);
  const [openDialogGeracaoCupom, setOpenDialogGeracaoCupom] = useState(false);

  return (
    <>
      <Paper variant="outlined" style={{ padding: 16 }}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="subtitle1"
            component="h5"
            style={{ paddingRight: 4 }}
          >
            Edição da inscrição:
          </Typography>
          <Typography>
            <Box fontWeight="fontWeightMedium">
              {inscricaoAbertura.descricaoInscricaoAbertura}
            </Box>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="subtitle1"
            component="h5"
            style={{ paddingRight: 4 }}
          >
            Número da inscrição:
          </Typography>
          <Typography>
            <Box fontWeight="fontWeightMedium">
              {numeroInscricao.toString().padStart(6, '0')}
            </Box>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="subtitle1"
            component="h5"
            style={{ paddingRight: 4 }}
          >
            Data/Hora:
          </Typography>
          <Typography>
            <Box fontWeight="fontWeightMedium">
              {dataHora &&
                format(
                  parse(dataHora, 'yyyy-MM-dd HH:mm:ss', new Date()),
                  "dd/MM/yyyy 'às' HH:mm:ss",
                )}
            </Box>
          </Typography>
        </Box>

        {!podeBannerAnaliseInscricao && (
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              component="h5"
              style={{ paddingRight: 4 }}
            >
              Situação:
            </Typography>
            <Typography style={{ color: getColor(situacao) }}>
              <Box fontWeight="fontWeightMedium">{descricaoSituacao}</Box>
            </Typography>
          </Box>
        )}

        {podeBannerAnaliseInscricao && (
          <Box marginY={1}>
            <Alert severity="info" classes={{ message: classes.message }}>
              <AlertTitle>Inscrição em Análise</AlertTitle>

              <Typography gutterBottom>
                Sua inscrição está no período de análise.
              </Typography>

              <Typography variant="body2">Período de análise:</Typography>

              <Typography variant="body2">
                {`${format(
                  parse(
                    prazoBannerAnalise.dataHoraInicio,
                    'yyyy-MM-dd HH:mm:ss',
                    new Date(),
                  ),
                  "dd/MM/yyyy HH'h'mm",
                )} - ${format(
                  parse(
                    prazoBannerAnalise.dataHoraFim,
                    'yyyy-MM-dd HH:mm:ss',
                    new Date(),
                  ),
                  "dd/MM/yyyy HH'h'mm",
                )}`}
              </Typography>
            </Alert>
          </Box>
        )}

        {!podeBannerAnaliseInscricao &&
          !podeEditarInscricao &&
          situacao === 'SOLICITADO' && (
            <Box marginY={1}>
              <Alert severity="info" classes={{ message: classes.message }}>
                <AlertTitle>Inscrição Realizada</AlertTitle>

                <Typography gutterBottom>
                  Sua inscrição já foi efetuada. Aguarde até que seja concluída
                  a sua análise.
                </Typography>
              </Alert>
            </Box>
          )}

        {!podeBannerAnaliseInscricao && situacao === 'CLASSIFICADO' && (
          <Box marginY={1}>
            <Alert severity="success" classes={{ message: classes.message }}>
              <AlertTitle>Inscrição Classificada</AlertTitle>

              <Typography gutterBottom>
                Você foi classificado para o programa Renda Franca, aguarde o
                prazo para o próximo passo.
              </Typography>
            </Alert>
          </Box>
        )}

        {!podeBannerAnaliseInscricao && situacao === 'DESCLASSIFICADO' && (
          <Box marginY={1}>
            <Alert severity="error" classes={{ message: classes.message }}>
              <AlertTitle>Inscrição Desclassificada</AlertTitle>

              <Typography gutterBottom>
                Sua inscrição está com a situação DESCLASSIFICADA, você pode
                efetuar um recurso no período informado.
              </Typography>

              {ocorrenciasRespostaDesclassif.length > 0 && (
                <Typography gutterBottom>Motivos Desclassificação:</Typography>
              )}

              {ocorrenciasRespostaDesclassif.map(ocorrencia => {
                return (
                  <Typography>
                    <Box fontWeight="fontWeightMedium">
                      {ocorrencia.descricao.split('\n').map((item, key) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </Box>
                  </Typography>
                );
              })}
            </Alert>
          </Box>
        )}

        {!podeBannerAnaliseInscricao && situacao === 'EXCLUIDO' && (
          <Box marginY={1}>
            <Alert severity="error" classes={{ message: classes.message }}>
              <AlertTitle>Inscrição Excluída</AlertTitle>

              <Typography gutterBottom>
                Sua inscrição está com a situação EXCLUÍDO, portanto não há
                opções para serem realizadas.
              </Typography>
            </Alert>
          </Box>
        )}

        {podeEditarInscricao && (
          <Box marginY={1}>
            <Alert severity="info" classes={{ message: classes.message }}>
              <AlertTitle>Editar inscrição</AlertTitle>
              <Grid container style={{ width: '100%' }}>
                <Grid item style={{ width: '100%' }}>
                  <Typography variant="body2">
                    Período para editar inscrição:
                  </Typography>

                  <Typography variant="body2">
                    {`${format(
                      parse(
                        prazoEditarInscricao.dataHoraInicio,
                        'yyyy-MM-dd HH:mm:ss',
                        new Date(),
                      ),
                      "dd/MM/yyyy HH'h'mm",
                    )} - ${format(
                      parse(
                        prazoEditarInscricao.dataHoraFim,
                        'yyyy-MM-dd HH:mm:ss',
                        new Date(),
                      ),
                      "dd/MM/yyyy HH'h'mm",
                    )}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  style={{
                    width: '100%',
                    textAlign: 'end',
                    marginTop: 8,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      handleEditarInscricao();
                      history.push(
                        `/inscrever/${inscricaoAbertura.idInscricaoAbertura}`,
                      );
                    }}
                    disabled={!podeEditarInscricao}
                  >
                    Editar Inscrição
                  </Button>
                </Grid>
              </Grid>
            </Alert>
          </Box>
        )}

        {podeGeracaoCupom && (
          <Box marginY={1}>
            <Alert severity="info" classes={{ message: classes.message }}>
              <AlertTitle>Gerar Cupom - Caminho do Emprego</AlertTitle>
              <Grid container style={{ width: '100%' }}>
                <Grid item style={{ width: '100%' }}>
                  <Typography variant="body2">
                    Período para gerar cupom do caminho do emprego:
                  </Typography>

                  <Typography variant="body2">
                    {`${format(
                      parse(
                        prazoGeracaoCupom.dataHoraInicio,
                        'yyyy-MM-dd HH:mm:ss',
                        new Date(),
                      ),
                      "dd/MM/yyyy HH'h'mm",
                    )} - ${format(
                      parse(
                        prazoGeracaoCupom.dataHoraFim,
                        'yyyy-MM-dd HH:mm:ss',
                        new Date(),
                      ),
                      "dd/MM/yyyy HH'h'mm",
                    )}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  style={{
                    width: '100%',
                    textAlign: 'end',
                    marginTop: 8,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      setOpenDialogGeracaoCupom(true);
                    }}
                    disabled={!podeGeracaoCupom}
                  >
                    Gerar Cupom
                  </Button>
                </Grid>
              </Grid>
            </Alert>
          </Box>
        )}

        {podeRecursoInscricao && (
          <Box marginY={1}>
            <Alert severity="info" classes={{ message: classes.message }}>
              <AlertTitle>Efetuar recurso</AlertTitle>
              <Grid container style={{ width: '100%' }}>
                <Grid item style={{ width: '100%' }}>
                  <Typography variant="body2">
                    Período para efetuar recurso:
                  </Typography>

                  <Typography variant="body2">
                    {`${format(
                      parse(
                        prazoRecursoInscricao.dataHoraInicio,
                        'yyyy-MM-dd HH:mm:ss',
                        new Date(),
                      ),
                      "dd/MM/yyyy HH'h'mm",
                    )} - ${format(
                      parse(
                        prazoRecursoInscricao.dataHoraFim,
                        'yyyy-MM-dd HH:mm:ss',
                        new Date(),
                      ),
                      "dd/MM/yyyy HH'h'mm",
                    )}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  style={{
                    width: '100%',
                    textAlign: 'end',
                    marginTop: 8,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      setOpenDialogRecurso(true);
                    }}
                    disabled={!podeRecursoInscricao}
                  >
                    Efetuar Recurso
                  </Button>
                </Grid>
              </Grid>
            </Alert>
          </Box>
        )}
      </Paper>

      <Box margin={1} />

      <Cronograma prazos={inscricaoAbertura.prazos} />

      {openDialogRecurso && (
        <DialogRecursoInscricao
          idInscricao={idInscricao}
          open={openDialogRecurso}
          onClose={cancel => {
            if (!cancel) {
              handleRefreshScreen();
            }

            setOpenDialogRecurso(false);
          }}
        />
      )}

      {openDialogGeracaoCupom && (
        <DialogGeracaoCupom
          idInscricao={idInscricao}
          open={openDialogGeracaoCupom}
          onClose={cancel => {
            if (!cancel) {
              handleRefreshScreen();
            }

            setOpenDialogGeracaoCupom(false);
          }}
        />
      )}
    </>
  );
};

export default Situacao;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  isValid,
  parseISO,
  formatISO,
  endOfDay,
  format,
  differenceInYears,
} from 'date-fns';
import { createIntl, createIntlCache } from 'react-intl';
import {
  updateStepOne,
  clearState,
  updateDataNascimentoStepOne,
} from '../../../../utils/updateActions';
import TextFieldSelect from '../../../../components/TextFieldSelect';
import { simNao } from '../../../../utils/enums';
import KeyBoardDatePicker from '../../../../components/KeyBoardDatePicker';
import MaskedInput from '../../../../components/MaskedInput';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'pt-BR',
    messages: {},
  },
  cache,
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
  }),
);

type FormValues = {
  resideMunicipioMaisTresAnos: string;
  desempregadoPeloMenosSeisMeses: string;
  dataNascimento: any;
  qtdeMembrosFamiliares: any;
  rendaTotal: any;
};

const StepOneSchema = Yup.object().shape({
  dataNascimento: Yup.date()
    .typeError('Campo inválido')
    .min(
      new Date(1900, 0, 1),
      'A data de nascimento deve ser posterior a 01/01/1900',
    )
    .max(
      endOfDay(new Date()),
      'A data de nascimento deve ser anterior ou igual a hoje',
    )
    .required('Campo obrigatório'),
  resideMunicipioMaisTresAnos: Yup.string()
    .required('Campo obrigatório')
    .oneOf(['', 'SIM', 'NAO'], 'Campo inválido'),
  desempregadoPeloMenosSeisMeses: Yup.string()
    .required('Campo obrigatório')
    .oneOf(['', 'SIM', 'NAO'], 'Campo inválido'),
  rendaTotal: Yup.number()
    .transform((value, originalValue) => {
      if (value as typeof Number) return value;

      const parseValue = parseFloat(
        originalValue
          .replaceAll('R$', '')
          .replaceAll('.', '')
          .replaceAll(',', '.')
          .trim(),
      );

      return parseValue;
    })
    .typeError('Campo inválido')
    .min(0, 'O valor mínimo para o campo é 0')
    .max(9999.99, 'O valor máximo para o campo é 9999,99')
    .required('Campo obrigatório'),
  qtdeMembrosFamiliares: Yup.number()
    .typeError('Campo inválido')
    .min(1, 'O valor mínimo para o campo é 1')
    .max(25, 'O valor máximo para o campo é 18')
    .required('Campo obrigatório'),
});

interface StepOneProps {
  handleNext(): void;
  handleBack(): void;
  salarioMinimo: number;
}

const StepOne: React.FC<StepOneProps> = ({
  handleNext,
  handleBack,
  salarioMinimo,
}) => {
  const classes = useStyles();
  const [rendaPerCapta, setRendaPerCapta] = useState(0);
  const [isValidRendaPerCapta, setValidRendaPerCapta] = useState(false);
  const [idade, setIdade] = useState(0);
  const [isValidDataNascimento, setValidDataNascimento] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const { state, actions } = useStateMachine({
    updateStepOne,
    clearState,
    updateDataNascimentoStepOne,
  });
  const [abort, setAbort] = useState(false);
  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleClose = useCallback(
    (aceitou: boolean) => {
      if (aceitou) {
        actions.clearState(undefined);
        history.push('/home');
      }
      setOpenDialog(false);
    },
    [history, actions],
  );

  const {
    control,
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isSubmitting },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(StepOneSchema),
    defaultValues: {
      resideMunicipioMaisTresAnos: state.resideMunicipioMaisTresAnos,
      desempregadoPeloMenosSeisMeses: state.desempregadoPeloMenosSeisMeses,
      dataNascimento: state.beneficiarioList[0].dataNascimento,
      rendaTotal: state.rendaTotal,
      qtdeMembrosFamiliares: state.qtdeMembrosFamiliares,
    },
  });
  const watchRendaTotal = watch('rendaTotal', '');
  const watchQtdeMembrosFamiliares = watch('qtdeMembrosFamiliares', '');
  const watchResponsavelFamiliar = watch('responsavelFamiliar', '');
  const watchResideMunicipioMaisTresAnos = watch(
    'resideMunicipioMaisTresAnos',
    '',
  );
  const watchDesempregadoPeloMenosSeisMeses = watch(
    'desempregadoPeloMenosSeisMeses',
    '',
  );

  const watchDataNascimento = watch('dataNascimento', '');

  useEffect(() => {
    if (isValid(parseISO(watchDataNascimento))) {
      setIdade(differenceInYears(new Date(), parseISO(watchDataNascimento)));
      setValidDataNascimento(true);
    } else {
      setIdade(0);
      setValidDataNascimento(false);
    }
  }, [watchDataNascimento]);

  useEffect(() => {
    if (
      watchRendaTotal.toString().length > 0 &&
      watchQtdeMembrosFamiliares.toString().length > 0
    ) {
      setRendaPerCapta(
        watchRendaTotal
          .replaceAll('R$', '')
          .replaceAll('.', '')
          .replaceAll(',', '.')
          .trim() / watchQtdeMembrosFamiliares,
      );
      setValidRendaPerCapta(true);
    } else {
      setRendaPerCapta(0);
      setValidRendaPerCapta(false);
    }
  }, [watchRendaTotal, watchQtdeMembrosFamiliares]);

  useEffect(() => {
    if (
      (rendaPerCapta > 550 && isValidRendaPerCapta) ||
      watchResponsavelFamiliar === 'NAO' ||
      watchResideMunicipioMaisTresAnos === 'NAO' ||
      watchDesempregadoPeloMenosSeisMeses === 'NAO' ||
      (idade < 18 && isValidDataNascimento)
    ) {
      setAbort(true);
    } else {
      setAbort(false);
    }
  }, [
    rendaPerCapta,
    isValidRendaPerCapta,
    watchResponsavelFamiliar,
    watchResideMunicipioMaisTresAnos,
    watchDesempregadoPeloMenosSeisMeses,
    idade,
    isValidDataNascimento,
  ]);

  const handleSubmitCallBack = useCallback(
    (values: FormValues) => {
      actions.updateStepOne({
        resideMunicipioMaisTresAnos: values.resideMunicipioMaisTresAnos,
        desempregadoPeloMenosSeisMeses: values.desempregadoPeloMenosSeisMeses,
        qtdeMembrosFamiliares: values.qtdeMembrosFamiliares.toString(),
        rendaTotal: intl.formatNumber(values.rendaTotal, {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }),
      });

      actions.updateDataNascimentoStepOne(
        formatISO(values.dataNascimento, {
          representation: 'date',
        }),
      );

      handleNext();
    },
    [actions, handleNext],
  );

  return (
    <Grid container alignContent="center" justify="center">
      <Grid item xs={12} sm={9} md={7} lg={5}>
        <Box maxWidth={530} marginX="auto">
          <Card elevation={12}>
            <CardContent>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handleSubmitCallBack)}
              >
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Controller
                      name="dataNascimento"
                      control={control}
                      render={props => {
                        return (
                          <KeyBoardDatePicker
                            name={props.name}
                            label="Data de Nascimento"
                            value={
                              isValid(parseISO(props.value))
                                ? parseISO(props.value)
                                : null
                            }
                            inputValue={
                              !isValid(parseISO(props.value))
                                ? props.value
                                : format(parseISO(props.value), 'dd/MM/yyyy')
                            }
                            minDate={new Date(1900, 0, 1)}
                            maxDate={new Date()}
                            helperText={errors.dataNascimento?.message}
                            required
                            error={!!errors.dataNascimento}
                            onChange={(date, value) => {
                              if (date && isValid(date)) {
                                setValue(
                                  'dataNascimento',
                                  formatISO(date, { representation: 'date' }),
                                  {
                                    shouldValidate: true,
                                  },
                                );
                              } else {
                                setValue('dataNascimento', value, {
                                  shouldValidate: false,
                                });
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldSelect
                      control={control}
                      name="resideMunicipioMaisTresAnos"
                      label="Reside no município há mais de três anos?"
                      fullWidth
                      helperText={errors.resideMunicipioMaisTresAnos?.message}
                      error={!!errors.resideMunicipioMaisTresAnos}
                      required
                      oneOf={simNao}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldSelect
                      control={control}
                      name="desempregadoPeloMenosSeisMeses"
                      label="Desempregado(a) há pelo menos seis meses?"
                      fullWidth
                      helperText={
                        errors.desempregadoPeloMenosSeisMeses?.message
                      }
                      error={!!errors.desempregadoPeloMenosSeisMeses}
                      required
                      oneOf={simNao}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <MaskedInput
                      options={{
                        mask: 'R$ num',
                        blocks: {
                          num: {
                            mask: Number,
                            scale: 2,
                            signed: false,
                            thousandsSeparator: '.',
                            padFractionalZeros: false,
                            normalizeZeros: false,
                            radix: ',',
                            mapToRadix: [','],
                          },
                        },
                      }}
                      control={control}
                      name="rendaTotal"
                      label="Renda Total"
                      type="tel"
                      variant="outlined"
                      fullWidth
                      required
                      helperText={
                        errors.rendaTotal?.message
                          ? errors.rendaTotal?.message
                          : 'informe a renda total de todos os membros'
                      }
                      error={!!errors.rendaTotal}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <MaskedInput
                      options={{
                        mask: Number,
                      }}
                      control={control}
                      name="qtdeMembrosFamiliares"
                      label="Quantidade membros familiares"
                      type="tel"
                      variant="outlined"
                      fullWidth
                      required
                      helperText={
                        errors.qtdeMembrosFamiliares?.message
                          ? errors.qtdeMembrosFamiliares?.message
                          : 'informe a quantidade total de membros que compoêm sua renda familiar'
                      }
                      error={!!errors.qtdeMembrosFamiliares}
                    />
                  </Grid>

                  {isValidRendaPerCapta && (
                    <Grid item xs={12}>
                      <Alert severity="info">
                        <AlertTitle>Salário mínimo vigente</AlertTitle>
                        {intl.formatNumber(salarioMinimo, {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                        })}
                      </Alert>
                    </Grid>
                  )}

                  {isValidRendaPerCapta && (
                    <Grid item xs={12}>
                      <Alert severity="info">
                        <AlertTitle>Renda per capta</AlertTitle>
                        {intl.formatNumber(rendaPerCapta, {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                        })}
                      </Alert>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    {abort && (
                      <Alert severity="error">
                        <AlertTitle>Inscrição não permitida</AlertTitle>
                        Sua resposta não atende aos critérios para a inscrição
                        no programa. Consulte a página de orientações para
                        maiores detalhes{' '}
                        <Link
                          href="https://www.franca.sp.gov.br/acao-social/orientacoes-programa-renda-franca"
                          target="_blank"
                          variant="body2"
                        >
                          clique aqui
                        </Link>
                        .
                      </Alert>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems="flex-end"
                  justify="flex-end"
                  spacing={1}
                >
                  <Grid item xs>
                    <Button
                      className={classes.button}
                      onClick={handleClickOpen}
                      variant="contained"
                      color="secondary"
                      size={matches ? 'small' : 'medium'}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      disabled
                      onClick={handleBack}
                      className={classes.button}
                      size={matches ? 'small' : 'medium'}
                    >
                      Anterior
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.button}
                      disabled={abort || isSubmitting}
                      size={matches ? 'small' : 'medium'}
                    >
                      Próximo
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancelar inscrição?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Os dados não salvos serão excluídos
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            Não
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default StepOne;

import React from 'react';
import {
  Box,
  createStyles,
  List,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format, parse } from 'date-fns';
import { CriteriosList, Prazo } from '..';
import CriterioConfirmado from './CriterioConfirmado';

const useStyles = makeStyles(() =>
  createStyles({
    listas: {
      width: '100%',
    },
    message: {
      width: '100%',
    },
  }),
);

interface CriteriosProps {
  podeBannerAnaliseInscricao: boolean;
  prazoBannerAnalise: Prazo;
  criteriosList: CriteriosList[];
}

const Criterios: React.FC<CriteriosProps> = ({
  podeBannerAnaliseInscricao,
  prazoBannerAnalise,
  criteriosList,
}) => {
  const classes = useStyles();

  return (
    <>
      {podeBannerAnaliseInscricao && (
        <Box marginY={1}>
          <Alert severity="info" classes={{ message: classes.message }}>
            <AlertTitle>Inscrição em Análise</AlertTitle>

            <Typography gutterBottom>
              Sua inscrição está no período de análise.
            </Typography>

            <Typography variant="body2">Período de análise:</Typography>

            <Typography variant="body2">
              {`${format(
                parse(
                  prazoBannerAnalise.dataHoraInicio,
                  'yyyy-MM-dd HH:mm:ss',
                  new Date(),
                ),
                "dd/MM/yyyy HH'h'mm",
              )} - ${format(
                parse(
                  prazoBannerAnalise.dataHoraFim,
                  'yyyy-MM-dd HH:mm:ss',
                  new Date(),
                ),
                "dd/MM/yyyy HH'h'mm",
              )}`}
            </Typography>
          </Alert>
        </Box>
      )}

      {!podeBannerAnaliseInscricao && (
        <Paper style={{ padding: 8 }} elevation={0}>
          <Typography
            variant="subtitle1"
            component="h5"
            style={{ paddingRight: 4 }}
          >
            <Box fontWeight="fontWeightMedium">
              Detalhamento Critérios de Desempate
            </Box>
          </Typography>
          <List dense className={classes.listas} style={{ padding: 0 }}>
            <CriterioConfirmado criteriosList={criteriosList} />
          </List>
        </Paper>
      )}
    </>
  );
};

export default Criterios;

import { GridApi } from 'ag-grid-community';
import {
  AlignmentType,
  convertMillimetersToTwip,
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType,
} from 'docx';
import { normalizeDiacritics } from 'normalize-text';
import { formataCPF } from '../../../utils/functions';

interface DocumentCreatorProps {
  gridApi: GridApi | null;
  modelo: string;
}

export class DocumentCreator {
  public static create({ gridApi, modelo }: DocumentCreatorProps): Document {
    const document = new Document({
      creator: 'Renda Franca',
      title: 'Lista Diário Oficial',
      styles: {
        default: {
          document: {
            run: {
              font: 'Arial',
              size: 18,
            },
            paragraph: {
              spacing: { line: 240 },
            },
          },
        },
      },
      sections: [
        {
          children: [DocumentCreator.createTable(gridApi, modelo)],
          properties: {
            page: {
              margin: {
                top: convertMillimetersToTwip(25),
                bottom: convertMillimetersToTwip(25),
                left: convertMillimetersToTwip(30),
                right: convertMillimetersToTwip(30),
                footer: convertMillimetersToTwip(12.5),
                header: convertMillimetersToTwip(12.5),
                gutter: 0,
                mirror: true,
              },
            },
          },
        },
      ],
    });

    return document;
  }

  public static createTable(gridApi: GridApi | null, modelo: string): Table {
    const count = gridApi?.getDisplayedRowCount() || 0;

    const table = new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      columnWidths: [20, 13, 12, 37, 18],
      rows: [
        new TableRow({
          tableHeader: true,
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: 'Classificação',
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 20,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: 'Pontuação',
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 13,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: 'Inscrição',
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 12,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: 'Nome',
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 37,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph({ text: 'CPF', alignment: AlignmentType.CENTER }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 18,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
          ],
        }),
      ],
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < count; i++) {
      const rowNode = gridApi?.getDisplayedRowAtIndex(i);

      const firstPartCpf = formataCPF(rowNode?.data.cpfPrincipal)
        .substring(0, 6)
        .replace(/[\d]/g, '*');

      const secondPartCpf = formataCPF(rowNode?.data.cpfPrincipal).substring(
        6,
        14,
      );

      const cpf = firstPartCpf + secondPartCpf;

      let classificacao;
      if (modelo === 'modeloA') {
        classificacao =
          rowNode?.data.posicaoConf === null
            ? ''
            : rowNode?.data.posicaoConf.toString();
      }

      if (modelo === 'modeloB') {
        classificacao = rowNode?.data.descricaoSituacaoInscricao;
      }

      table.addChildElement(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: classificacao,
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 20,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text:
                    rowNode?.data.somaPontuacaoConf === null
                      ? ''
                      : rowNode?.data.somaPontuacaoConf.toString(),
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 13,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: rowNode?.data.numeroInscricao
                    .toString()
                    .padStart(6, '0'),
                  alignment: AlignmentType.CENTER,
                }),
              ],
              width: {
                size: 12,
                type: WidthType.PERCENTAGE,
              },
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph(
                  normalizeDiacritics(rowNode?.data.nome.toUpperCase()),
                ),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 37,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: cpf,
                  alignment: AlignmentType.CENTER,
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 18,
                type: WidthType.PERCENTAGE,
              },
              margins: {
                top: convertMillimetersToTwip(0),
                bottom: convertMillimetersToTwip(0),
                left: convertMillimetersToTwip(2),
                right: convertMillimetersToTwip(2),
              },
            }),
          ],
        }),
      );
    }

    return table;
  }
}

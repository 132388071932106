import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { apiRendaFranca } from '../services/api';
import { InscricaoAberturaApi, InscricaoAbertura } from './AppBar';
import { useAuth } from '../hooks/auth';

export interface Params {
  id: string;
}

const AppBarMenu: React.FC = () => {
  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const params = useParams<Params>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const [selectedInscricaoAbertura, setSelectedInscricaoAbertura] =
    useState<InscricaoAbertura>({} as InscricaoAbertura);

  const [inscricaoAberturas, setInscricaoAberturas] = useState<
    InscricaoAbertura[]
  >([]);

  const handleClickListItem = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleMenuItemClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      inscricaoAbertura: InscricaoAbertura,
    ) => {
      setSelectedInscricaoAbertura(inscricaoAbertura);
      setAnchorEl(null);
      history.push(`/inscricoes/${inscricaoAbertura.id}`);
    },
    [history],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await apiRendaFranca.get<InscricaoAberturaApi>(
          '/cadastros/inscricao-abertura',
        );

        setInscricaoAberturas(response.data.payload);

        const findInscricaoAbertura = response.data.payload.find(
          inscricaoAbertura => inscricaoAbertura.id === parseInt(params.id, 10),
        );

        setSelectedInscricaoAbertura(
          findInscricaoAbertura || ({} as InscricaoAbertura),
        );
      } catch (err) {
        if (err.response && err.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', { variant: 'error' });
          signOut();
          return;
        }
        if (err.response && err.response.status === 404) {
          return;
        }
        enqueueSnackbar('Erro ao consultar inscrição', { variant: 'error' });
      }
    })();
  }, [enqueueSnackbar, signOut, params.id]);

  return (
    <div>
      <List disablePadding component="nav">
        <ListItem
          disableGutters
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
        >
          <ListItemText
            primary={`Renda Franca - ${selectedInscricaoAbertura?.descricao}`}
            primaryTypographyProps={{
              variant: matches ? 'body2' : 'h6',
              color: 'inherit',
              noWrap: true,
            }}
          />
          <ListItemIcon>
            <MoreVertIcon htmlColor="#fff" />
          </ListItemIcon>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {inscricaoAberturas.map(inscricaoAbertura => (
          <MenuItem
            key={inscricaoAbertura.id}
            selected={inscricaoAbertura.id === selectedInscricaoAbertura?.id}
            onClick={event => handleMenuItemClick(event, inscricaoAbertura)}
          >
            {`Renda Franca - ${inscricaoAbertura.descricao}`}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AppBarMenu;

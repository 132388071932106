import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import {
  AttachFile as AttachFileIcon,
  CheckCircle,
  Error as ErrorIcon,
} from '@material-ui/icons';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { GlobalState } from 'little-state-machine';
import { useSnackbar } from 'notistack';

import { useParams } from 'react-router-dom';

import { useAuth } from '../../../../hooks/auth';
import { apiRendaFranca } from '../../../../services/api';
import { Params } from '../../../../components/AppBarMenu';

interface ConfirmationDialogRawProps {
  classes: Record<'paper', string>;
  id: string;
  open: boolean;
  onClose: (success: boolean) => void;
  files: File[];
  formDataValues: GlobalState;
}

interface ProgressFile {
  id: string;
  progress: number;
  pathSuccess: string;
  error: string;
}

interface HasError {
  hasError: boolean;
  errors: string[];
}

interface ErrorDetails {
  messageUser: string;
  messageDev: string;
  field: string;
  value: any;
}

interface ErrorResponseApi {
  statusCode: number;
  timestamp: string;
  statusDescription: string;
  errorDetails: ErrorDetails[];
}

const DialogTitleInProgress: React.FC = () => {
  return (
    <>
      <span>Aguarde, estamos enviando seus dados</span>
      <Box display="flex" alignContent="center" justifyContent="center">
        <CircularProgress />
      </Box>
    </>
  );
};

interface DialogTitleSuccessProps {
  numeroInscricao: number;
}

const DialogTitleSuccess: React.FC<DialogTitleSuccessProps> = ({
  numeroInscricao,
}) => {
  return (
    <>
      <span style={{ color: green[500] }}>
        {`Inscrição ${numeroInscricao
          .toString()
          .padStart(6, '0')} salva com sucesso!`}
      </span>
      <Box display="flex" alignContent="center" justifyContent="center">
        <CheckCircle fontSize="large" style={{ color: green[500] }} />
      </Box>
    </>
  );
};

interface DialogTitleErrorProps {
  hasError: HasError;
}

const DialogTitleError: React.FC<DialogTitleErrorProps> = ({ hasError }) => {
  return (
    <>
      <List component="div" disablePadding>
        <ListItem component="div">
          <ListItemIcon>
            <ErrorIcon fontSize="large" style={{ color: red[500] }} />
          </ListItemIcon>
          <ListItemText primary="Erro ao processar a inscrição" />
        </ListItem>
      </List>
      <span style={{ color: red[500] }} />
      <Box display="flex" alignContent="center" justifyContent="center">
        <List>
          {hasError.errors.map(item => {
            return (
              <ListItem key={item}>
                <ListItemText primary={`• ${item}`} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </>
  );
};

const SendFormDataAndFiles: React.FC<ConfirmationDialogRawProps> = props => {
  const { signOut } = useAuth();
  const { onClose, formDataValues, files, open, ...other } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [progressFiles, setProgressFiles] = useState<ProgressFile[]>([]);
  const progressFilesRef = useRef(progressFiles);

  const [completedUploads, setCompletedUploads] = useState(false);
  const [hasError, setHasError] = useState<HasError>({
    hasError: false,
    errors: [],
  });

  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);

  const [numeroInscricao, setNumeroInscricao] = useState(0);

  const params = useParams<Params>();

  const handleClearState = useCallback(() => {
    setProgressFiles([]);
    progressFilesRef.current = [];
    setCompletedUploads(false);
    setHasError({
      hasError: false,
      errors: [],
    });
    cancelTokenSourceRef.current = null;
    setNumeroInscricao(0);
  }, []);

  const myUploadProgress = useCallback(
    (file: File, index: number) => (data: any) => {
      setProgressFiles(previousProgressFile => {
        const percentage = Math.round((100 * data.loaded) / data.total);

        const newProgressFile = [...previousProgressFile];
        const newItemProgressFile = { ...previousProgressFile[index] };

        newItemProgressFile.id = file.name;
        newItemProgressFile.progress = percentage;

        newProgressFile[index] = newItemProgressFile;

        progressFilesRef.current = newProgressFile;

        return newProgressFile;
      });
    },
    [],
  );

  const setPathSucess = useCallback((response: any, index: number) => {
    if (response) {
      setProgressFiles(previousProgressFile => {
        const newProgressFile = [...previousProgressFile];
        const newItemProgressFile = { ...previousProgressFile[index] };

        newItemProgressFile.pathSuccess = response.payload;

        newProgressFile[index] = newItemProgressFile;

        progressFilesRef.current = newProgressFile;

        return newProgressFile;
      });
    }
  }, []);

  const setErrorProgressFile = useCallback((error: any, index: number) => {
    if (error) {
      setProgressFiles(previousProgressFile => {
        const newProgressFile = [...previousProgressFile];
        const newItemProgressFile = { ...previousProgressFile[index] };

        const errorMessage =
          error.response?.data?.errorDetails?.errorDetails[0]?.messageUser ||
          'erro ao enviar arquivo';
        newItemProgressFile.error = errorMessage;

        newProgressFile[index] = newItemProgressFile;

        progressFilesRef.current = newProgressFile;

        return newProgressFile;
      });
    }
  }, []);

  const handleSubmitFormDataValues = useCallback(async () => {
    const formData = new FormData();

    formData.append('FormDataValues', JSON.stringify(formDataValues));
    formData.append('ProgressFiles', JSON.stringify(progressFilesRef.current));

    await apiRendaFranca
      .post(`/inscrever/${params.id}/enviar-formdatavalues`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: cancelTokenSourceRef.current?.token,
      })
      .then(result => {
        setNumeroInscricao(result.data.payload);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          enqueueSnackbar('Favor fazer login novamente', {
            variant: 'error',
            preventDuplicate: true,
          });
          signOut();
        }

        const errorState = {
          hasError: true,
          errors: [],
        } as HasError;

        if (error.response && error.response.status === 400) {
          const response = error.response.data as ErrorResponseApi;

          const errors = response.errorDetails.map<string>(
            errorDetail => errorDetail.messageUser,
          );

          errorState.errors = errors;
        }

        setHasError(errorState);
      });
  }, [
    formDataValues,
    cancelTokenSourceRef,
    enqueueSnackbar,
    signOut,
    params.id,
  ]);

  const handleSubmitFiles = useCallback(async () => {
    try {
      const promises: Promise<AxiosResponse<any>>[] = [];

      cancelTokenSourceRef.current = axios.CancelToken.source();

      files.forEach((item, index) => {
        const formData = new FormData();
        formData.append('file', item);

        const uploadFile = apiRendaFranca
          .post('/inscrever/enviar-arquivo', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            cancelToken: cancelTokenSourceRef.current?.token,
            onUploadProgress: myUploadProgress(item, index),
          })
          .then(result => {
            setPathSucess(result.data, index);
            return result;
          })
          .catch(error => {
            setErrorProgressFile(error, index);
            setHasError({
              hasError: true,
              errors: [],
            });

            if (error.response && error.response.status === 401) {
              enqueueSnackbar('Favor fazer login novamente', {
                variant: 'error',
                preventDuplicate: true,
              });
              signOut();
            }

            return error;
          });

        promises.push(uploadFile);
      });

      await Promise.all(promises)
        .then(async values => {
          const cancels = values.filter(item => axios.isCancel(item));
          const errors = values.filter(item => axios.isAxiosError(item));

          if (cancels.length === 0 && errors.length === 0) {
            await handleSubmitFormDataValues();
          }
        })
        .finally(() => {
          setCompletedUploads(true);
        });
    } catch (err) {
      setHasError({
        hasError: true,
        errors: [],
      });
      enqueueSnackbar('Erro ao processar a inscrição', { variant: 'error' });
    }
  }, [
    files,
    myUploadProgress,
    setPathSucess,
    setErrorProgressFile,
    handleSubmitFormDataValues,
    enqueueSnackbar,
    signOut,
  ]);

  const MyDialogTitle = useCallback(() => {
    if (completedUploads && hasError.hasError) {
      return <DialogTitleError hasError={hasError} />;
    }
    if (completedUploads && !hasError.hasError) {
      return <DialogTitleSuccess numeroInscricao={numeroInscricao} />;
    }
    return <DialogTitleInProgress />;
  }, [completedUploads, hasError, numeroInscricao]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntered={handleSubmitFiles}
      onExited={handleClearState}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <MyDialogTitle />
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {files.map((file, index) => {
            const isItemError =
              !!progressFiles[index] &&
              !!progressFiles[index].error &&
              progressFiles[index].error.length > 0;

            const isItemSuccess =
              !!progressFiles[index] &&
              !!progressFiles[index].pathSuccess &&
              progressFiles[index].pathSuccess.length > 0;
            return (
              <ListItem
                style={
                  isItemError
                    ? {
                        outlineStyle: 'solid',
                        outlineWidth: '1px',
                        outlineColor: red[500],
                      }
                    : {}
                }
                disableGutters
                key={file.name}
              >
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  secondaryTypographyProps={{ style: { color: red[500] } }}
                  secondary={isItemError ? progressFiles[index].error : ''}
                />
                <ListItemIcon style={{ justifyContent: 'center' }}>
                  {isItemError && <ErrorIcon style={{ color: red[500] }} />}
                  {isItemSuccess && (
                    <CheckCircle style={{ color: green[500] }} />
                  )}
                  {!isItemError &&
                    !isItemSuccess &&
                    progressFiles[index] &&
                    progressFiles[index].progress < 100 && (
                      <CircularProgress
                        variant="determinate"
                        value={
                          progressFiles[index]
                            ? progressFiles[index].progress
                            : 0
                        }
                        size={20}
                      />
                    )}
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        {(!completedUploads || (completedUploads && hasError.hasError)) && (
          <Button
            onClick={() => {
              cancelTokenSourceRef.current?.cancel(
                'Requisição cancelada pelo usuário',
              );
              onClose(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
        )}
        {completedUploads && !hasError.hasError && (
          <Button
            onClick={() => {
              onClose(true);
            }}
            color="primary"
          >
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SendFormDataAndFiles;

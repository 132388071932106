import React from 'react';

import { createStyles, List, makeStyles, Paper } from '@material-ui/core';
import MembrosList from './MembrosList';
import { Membro } from '..';

const useStyles = makeStyles(() =>
  createStyles({
    listas: {
      width: '100%',
    },
  }),
);

interface MembrosProps {
  membros: Membro[];
}

const Membros: React.FC<MembrosProps> = ({ membros }) => {
  const classes = useStyles();

  return (
    <Paper style={{ padding: 8 }}>
      <List className={classes.listas}>
        {membros &&
          membros.map(value => {
            return <MembrosList key={value.id} membro={value} />;
          })}
      </List>
    </Paper>
  );
};

export default Membros;

import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { apiRendaFranca } from '../../../../services/api';
import { addServerErrors } from '../../../../utils/transformErrors';

interface DialogRecursoInscricaoProps {
  idInscricao: string;
  open: boolean;
  onClose: (cancel: boolean) => void;
}

interface FormValues {
  ocorrencia: string;
}

const FormSchema = Yup.object().shape({
  ocorrencia: Yup.string()
    .required('Campo obrigatório')
    .min(10, 'Mínimo de 10 caracteres')
    .max(250, 'Máximo de 250 caracteres'),
});

const DialogRecursoInscricao: React.FC<DialogRecursoInscricaoProps> = ({
  onClose,
  open,
  idInscricao,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      ocorrencia: '',
    },
  });

  const watchOcorrencia = watch('ocorrencia', '');

  const handleCancel = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSubmitCallBack = useCallback(
    async (values: FormValues) => {
      try {
        await apiRendaFranca.post(`/inscricao/${idInscricao}/recurso`, values);

        enqueueSnackbar('Recurso efetuado com sucesso!', {
          variant: 'success',
        });

        onClose(false);
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;

          if (errorDetails) {
            addServerErrors<FormValues>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao efetuar recurso', { variant: 'error' });
      }
    },
    [enqueueSnackbar, onClose, setError, idInscricao],
  );

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>Efetuar Recurso</DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>
          Escreva no campo abaixo o motivo pelo qual deseja entrar com recurso:
        </Typography>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(handleSubmitCallBack)}
          id="formPrincipalId"
        >
          <TextField
            inputRef={register}
            name="ocorrencia"
            variant="outlined"
            fullWidth
            required
            inputProps={{ maxLength: 250 }}
            helperText={errors.ocorrencia?.message}
            error={!!errors.ocorrencia}
            multiline
            rows={7}
          />
        </form>

        <Box marginTop={1}>
          <Typography>
            {250 - watchOcorrencia.length} caracteres restantes
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={isSubmitting}
          form="formPrincipalId"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogRecursoInscricao;

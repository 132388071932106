import React, { useCallback } from 'react';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { endOfDay, format, formatISO, isValid, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import KeyBoardDatePicker from '../../components/KeyBoardDatePicker';
import { apiRendaFranca } from '../../services/api';
import { addServerErrors } from '../../utils/transformErrors';

interface FormValues {
  dataPublicacao: Date;
}

const FormSchema = Yup.object().shape({
  dataPublicacao: Yup.date()
    .typeError('Campo inválido')
    .min(
      new Date(1900, 0, 1),
      'A data de nascimento deve ser posterior ou igual a 01/01/1900',
    )
    .max(
      endOfDay(new Date()),
      'A data de nascimento deve ser anterior ou igual a hoje',
    )
    .required('Campo obrigatório'),
});

interface DialogInsercaoProps {
  open: boolean;
  onClose: (cancel: boolean) => void;
}

const DialogInsercao: React.FC<DialogInsercaoProps> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      dataPublicacao: '',
    },
  });

  const handleCancel = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleSubmitCallBack = useCallback(
    async (values: FormValues) => {
      try {
        await apiRendaFranca.post('/diario-oficial', {
          ...values,
          dataPublicacao: formatISO(values.dataPublicacao, {
            representation: 'date',
          }),
        });

        enqueueSnackbar('Registro criado com sucesso!', {
          variant: 'success',
        });

        onClose(false);
      } catch (err) {
        if (err.response) {
          const { errorDetails } = err.response.data;

          if (errorDetails) {
            addServerErrors<FormValues>(errorDetails, setError);
          }
        }
        enqueueSnackbar('Erro ao criar registro', { variant: 'error' });
      }
    },
    [enqueueSnackbar, setError, onClose],
  );

  return (
    <>
      <DevTool control={control} />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
        open={open}
      >
        <DialogTitle>Criar Publicação Diário Oficial</DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom>
            Criar registro de publicação para o diário oficial, vinculando a
            situação, posição de classificação e pontuação da inscrição no
            momento da publicação.
          </Typography>
          <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSubmitCallBack)}
            id="formPrincipalId"
            style={{ marginTop: 16 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="dataPublicacao"
                  control={control}
                  render={props => {
                    return (
                      <KeyBoardDatePicker
                        name={props.name}
                        label="Data da Publicação"
                        value={
                          isValid(parseISO(props.value))
                            ? parseISO(props.value)
                            : null
                        }
                        inputValue={
                          !isValid(parseISO(props.value))
                            ? props.value
                            : format(parseISO(props.value), 'dd/MM/yyyy')
                        }
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date()}
                        helperText={errors.dataPublicacao?.message}
                        required
                        error={!!errors.dataPublicacao}
                        onChange={(date, value) => {
                          if (date && isValid(date)) {
                            setValue(
                              'dataPublicacao',
                              formatISO(date, { representation: 'date' }),
                              {
                                shouldValidate: true,
                              },
                            );
                          } else {
                            setValue('dataPublicacao', value, {
                              shouldValidate: false,
                            });
                          }
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isSubmitting}
            form="formPrincipalId"
          >
            Criar Publicação
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogInsercao;
